import { FC } from "react";

import { Button } from "component-library";

import { SearchTypes } from "../../../../types/panels/searchPanel/search";

import { SEARCH_TYPES } from "../../../../constants/panels/searchPanel/search";

import useSearchPanelStore from "../../../../store/search/panel/useSearchPanelStore";

import useSearchPanelActions from "../../../../customHooks/search/useSearchPanelActions";

import SaveSearchButton from "./SaveSearchButton";

interface Props {
  searchType?: SearchTypes;
  isResetDisabled: boolean;
  isSaveDisabled: boolean;
  isSearchDisabled: boolean;
  isShowPrompt: boolean;
  hasLoadedSavedSearch: boolean;
  handleResetClick: () => void;
  onClickSaveCallback: () => void;
  handleSearchClick: () => void;
}

const ActionButtons: FC<Props> = ({
  // TODO: Remove searchType used for Save Search
  searchType = SEARCH_TYPES.ATTRIBUTE_SEARCH,
  isResetDisabled,
  isSaveDisabled,
  isSearchDisabled,
  isShowPrompt,
  hasLoadedSavedSearch,
  handleResetClick,
  onClickSaveCallback,
  handleSearchClick,
}) => {
  const { searchCallback } = useSearchPanelActions();
  const openedQBEditorPanel = useSearchPanelStore(
    (state) => state.openedQBEditorPanel
  );
  const updateOpenedQBEditorPanel = useSearchPanelStore(
    (state) => state.updateOpenedQBEditorPanel
  );

  const closeAttributesPanel = () => {
    if (openedQBEditorPanel) {
      updateOpenedQBEditorPanel(null);
    }
  };

  return (
    <div className="action-button-container">
      <Button
        text="RESET"
        iconLeft="restartAlt"
        type="tertiary"
        state={isResetDisabled ? "disabled" : "enabled"}
        onClick={handleResetClick}
      />
      <SaveSearchButton
        searchType={searchType}
        hasLoadedSavedSearch={hasLoadedSavedSearch}
        disabled={isSaveDisabled}
        onClickSaveCallback={onClickSaveCallback}
        closeAttributesPanel={closeAttributesPanel}
      />
      <Button
        text="Search"
        iconLeft="search"
        state={isSearchDisabled ? "disabled" : "enabled"}
        type="primary"
        onClick={() => {
          // need to call the general search callback first before
          // the search callbacks by record type to reset
          // the search criteria and extent info first
          searchCallback();
          handleSearchClick();
        }}
      />
    </div>
  );
};

export default ActionButtons;
