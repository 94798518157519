import { RecordType } from "../../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useExtentPermitsStore from "../../../store/map/gis/extent/permits/extentPermitsStore";
import useExtentWellsStore from "../../../store/map/gis/extent/wells/extentWellsStore";

interface UseExtentRecordStoreProps {
  searchRecordType: RecordType;
}

const useExtentRecordStore = ({
  searchRecordType,
}: UseExtentRecordStoreProps) => {
  const extentWellsStore = useExtentWellsStore;
  const extentPermitsStore = useExtentPermitsStore;

  const getExtentStore = () => {
    if (searchRecordType === RECORD_TYPES.PERMIT) {
      return extentPermitsStore;
    }

    return extentWellsStore;
  };

  return getExtentStore();
};

export default useExtentRecordStore;
