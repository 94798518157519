import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import { Button } from "component-library";

import {
  AssetEconomicsChartProps,
  IAssetEconomicsData,
} from "../../../../types/charts/chartType/chartType";
import { Modules } from "../../../../types/modularity/modules";
import { WellEconomicsInputs } from "../../../../types/panels/wellPanel/wellEconomicsType";

import { CHART_MODE_VIEW } from "../../../../constants/charts/chartModeView";
import { MAX_WELLS_COUNT_ASSET_ECONOMICS } from "../../../../constants/charts/charts";
import * as constants from "../../../../constants/panels/wellPanel/wellEconomics";

import useChartStore from "../../../../store/chart/chartStore";
import useAlertPopupStore from "../../../../store/map/alert/alertStore";
import useModularityStore from "../../../../store/modularity/modularityStore";
import usePanelsStore from "../../../../store/panels/panelsStore";
import useWellSelectionStore from "../../../../store/selection/wells/wellSelectionStore";

import useAnalysisChartRefetch from "../../../../customHooks/charts/useAnalysisChartRefetch";
import useWellEconomicsAggregation from "../../../../customHooks/charts/useWellEconomicsAggregation";
import useWellEconomicsAggregationRecalculate from "../../../../customHooks/charts/useWellEconomicsAggregationRecalculate";
import useWellEconomicsOutputData from "../../../../customHooks/panels/wellPanel/useWellEconomicsData";
import useWellEconomicsEvents from "../../../../customHooks/panels/wellPanel/useWellEconomicsEvents";
import useWellEconomicsUoM from "../../../../customHooks/panels/wellPanel/useWellEconomicsUoM";

import { clone } from "../../../../utils/helper";

import LoadingBackDrop from "../../../common/LoadingBackDrop";
import OutlinedInputField from "../../../common/OutlinedInputField";
import DownloadIcon from "../../../common/icons/DownloadIcon";
import WellPanelAccordion from "../../../panels/wellPanel/common/WellPanelAccordion";
import WellEconomicsOutput from "../../../panels/wellPanel/footer/WellEconomicsOutput";
import ChartTypeHeader from "../chartTypeHeader/ChartTypeHeader";
import AssetEconomicsMessageDisplay from "./AssetEconomicsMessageDisplay";

const AssetEconomics: FC<AssetEconomicsChartProps> = ({
  chartMode,
  chartId,
  chartType,
  onChartFullScreenClose,
}) => {
  const chartRef = useRef<any>();

  const [currentEconomicsIChartData, setCurrentEconomicsIChartData] = useState<
    IAssetEconomicsData | undefined
  >();
  const [hasTriggeredRecalculate, setHasTriggeredRecalculate] = useState(false);
  const [hasTriggeredReset, setHasTriggeredReset] = useState(false);
  const [disableRecalculate, setDisableRecalculate] = useState(true);
  const [disableExport, setDisableExport] = useState(false);
  const [disableReset, setDisableReset] = useState(false);
  const [isEconomicsRecalculateLoading, setIsEconomicsRecalculateLoading] =
    useState(false);

  const wellEconomicsInputInfo = usePanelsStore(
    (state) => state.wellEconomicsInputs?.[chartId]
  );

  const removeWellEconomicsByIds = usePanelsStore(
    (state) => state.removeWellEconomicsByIds
  );

  const modules = useModularityStore((state) => state.modules);
  const updateModuleDataByKey = useModularityStore(
    (state) => state.updateModuleDataByKey
  );

  const chartData = useChartStore((state) => state.chartData);
  const getDataFlag = useChartStore((state) => state.chartDataRefetchFlag);
  const updateGetDataFlag = useChartStore(
    (state) => state.setChartDataRefetchFlag
  );
  const updateChartDataByKey = useChartStore(
    (state) => state.updateChartDataByKey
  );

  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  const selectedWellUwis = useWellSelectionStore(
    (state) => state.selectedRecordUwis
  );

  const { refetch, updateRefetch } = useAnalysisChartRefetch({
    chartMode,
    chartId,
    chartType,
  });

  const {
    data: economicsData,
    loading: isLoading,
    loadingExport,
    getWellEconomicsOutputData,
  } = useWellEconomicsOutputData();

  const {
    warningMessage,
    isExpandedAccordion,
    setTriggerWarning,
    handleOnExpandDetails,
    handleSectionHeightUpdate,
  } = useWellEconomicsAggregation(chartId, chartMode);

  const { handleInputChange, handleOnBlur, validateBeforeRecalculate } =
    useWellEconomicsEvents(currentEconomicsIChartData?.chartId ?? "");

  const {
    originalInputValues,
    checkEquality,
    reCheckAPIValidations,
    parseInputValues,
    setGreyedStateNull,
    formatDefaultInputsToNull,
    addWellEconomicsByWellInput,
    checkDefaultEquality,
  } = useWellEconomicsAggregationRecalculate(currentEconomicsIChartData);

  const { formatWellEconomicsUnits } = useWellEconomicsUoM();

  const showSpinnerLoader = useMemo(
    () =>
      (hasTriggeredRecalculate || hasTriggeredReset) && isLoading
        ? false
        : isLoading,
    [isLoading, hasTriggeredRecalculate, hasTriggeredReset]
  );

  const updateModuleRefetch = () => {
    const chartModule: Modules | undefined = (clone(modules) as Modules[]).find(
      (module) => module.module === chartId
    );

    if (chartModule) {
      chartModule.refetch = false;

      updateModuleDataByKey(chartId, chartModule);
    }

    updateRefetch(false);
  };

  const handleRecalculate = useCallback(
    (parsedChangedInputValues?: WellEconomicsInputs, isForExport = false) => {
      if (!wellEconomicsInputInfo?.inputs) return;

      if (validateBeforeRecalculate() && reCheckAPIValidations()) {
        const inputs = parsedChangedInputValues
          ? parsedChangedInputValues
          : parseInputValues(wellEconomicsInputInfo?.inputs);
        const newInputs = setGreyedStateNull(inputs);
        const formattedInputs = formatDefaultInputsToNull(newInputs);

        getWellEconomicsOutputData({
          uwi: selectedWellUwis,
          inputs: formattedInputs,
          auditVerbosity: "FullList",
          summaryOnly: !isForExport,
          ...(currentEconomicsIChartData?.targetUom
            ? { targetUom: currentEconomicsIChartData?.targetUom }
            : {}),
          isForAnalysisExport: isForExport,
        });

        setDisableRecalculate(true);
        setDisableExport(true);
        setHasTriggeredRecalculate(true);
        setDisableReset(true);
        setIsEconomicsRecalculateLoading(true);
      }
    },
    [wellEconomicsInputInfo, selectedWellUwis]
  );

  const handleReset = () => {
    getWellEconomicsOutputData({
      uwi: selectedWellUwis,
      inputs: constants.WELL_ECONOMICS_DEFAULT_VALUES,
      includeFcfDetails: true,
    });

    setDisableRecalculate(true);
    setDisableReset(true);
    setDisableExport(true);
    setHasTriggeredReset(true);
    setIsEconomicsRecalculateLoading(true);
  };

  const handleExport = useCallback(() => {
    if (!wellEconomicsInputInfo?.inputs) return;
    const parsedChangedInputValues = parseInputValues(
      wellEconomicsInputInfo.inputs
    );

    if (checkDefaultEquality(parsedChangedInputValues)) {
      getWellEconomicsOutputData({
        uwi: selectedWellUwis,
        inputs: constants.WELL_ECONOMICS_DEFAULT_VALUES,
        summaryOnly: false,
        ...(currentEconomicsIChartData?.targetUom
          ? { targetUom: currentEconomicsIChartData?.targetUom }
          : {}),
        isForAnalysisExport: true,
      });

      setDisableRecalculate(true);
      setDisableReset(true);
      setDisableExport(true);
    } else {
      //will also recalculate and update changes made by user
      handleRecalculate(parsedChangedInputValues, true);
    }
  }, [wellEconomicsInputInfo?.inputs]);

  useEffect(() => {
    if (!wellEconomicsInputInfo?.inputs || !originalInputValues) {
      setDisableRecalculate(true);
      return;
    }

    if (wellEconomicsInputInfo?.errorState) {
      const parsedChangedInputValues = parseInputValues(
        wellEconomicsInputInfo?.inputs
      );
      if (
        Object.values(wellEconomicsInputInfo?.errorState).some(
          (item) => item
        ) ||
        checkEquality(parsedChangedInputValues)
      ) {
        setDisableRecalculate(true);
      } else {
        setDisableRecalculate(false);
      }
    }
  }, [
    originalInputValues,
    wellEconomicsInputInfo?.inputs,
    wellEconomicsInputInfo?.errorState,
  ]);

  useEffect(() => {
    if (!chartId) return;
    const chart = chartData?.find((data) => data.chartId === chartId) as
      | IAssetEconomicsData
      | undefined;

    if (chart) {
      setCurrentEconomicsIChartData(chart);
    }
  }, [chartData]);

  useEffect(() => {
    if (!selectedWellUwis.length && currentEconomicsIChartData) {
      const removedEconomicsData = {
        ...currentEconomicsIChartData,
        assetEconomicsRawData: undefined,
      };
      setCurrentEconomicsIChartData(removedEconomicsData);
      updateChartDataByKey(chartId, removedEconomicsData);
    }
  }, [selectedWellUwis]);

  useEffect(() => {
    if (!currentEconomicsIChartData?.assetEconomicsRawData) {
      const chart = chartData?.find((data) => data.chartId === chartId) as
        | IAssetEconomicsData
        | undefined;

      if (chart?.assetEconomicsRawData) setCurrentEconomicsIChartData(chart);
    }
  }, [currentEconomicsIChartData, chartData]);

  useEffect(() => {
    if (
      refetch &&
      selectedWellUwis.length &&
      selectedWellUwis.length < MAX_WELLS_COUNT_ASSET_ECONOMICS
    ) {
      getWellEconomicsOutputData({
        uwi: selectedWellUwis,
        auditVerbosity: "FullList",
        summaryOnly: true,
        ...(currentEconomicsIChartData?.targetUom
          ? { targetUom: currentEconomicsIChartData?.targetUom }
          : {}),
      });
      if (wellEconomicsInputInfo) removeWellEconomicsByIds([chartId]);
    }
  }, [refetch]);

  useEffect(() => {
    if (!economicsData) {
      setTriggerWarning(undefined);
      return;
    }

    if (!selectedWellUwis.length) {
      updateModuleRefetch();
      return;
    }

    if (!economicsData.Result.Success) {
      updateAlertState({
        severity: "warning",
        message: "No economic data available for selected well(s)",
      });

      setTriggerWarning("No economics data available");
      updateModuleRefetch();
      return;
    }

    if (currentEconomicsIChartData) {
      if (
        hasTriggeredRecalculate &&
        wellEconomicsInputInfo &&
        currentEconomicsIChartData?.assetEconomicsRawData
      ) {
        const parsedChangedInputValues = parseInputValues(
          wellEconomicsInputInfo?.inputs
        );
        const economicsInputRawData = {
          ...currentEconomicsIChartData.assetEconomicsRawData,
          recalculatedValues: economicsData,
          userValuesBeforeRecalculation: parsedChangedInputValues,
        };

        updateChartDataByKey(chartId, {
          ...currentEconomicsIChartData,
          assetEconomicsRawData: economicsInputRawData,
        });

        setHasTriggeredRecalculate(false);
      } else {
        if (!wellEconomicsInputInfo) {
          addWellEconomicsByWellInput(economicsData.Inputs);
        } else if (hasTriggeredReset) {
          removeWellEconomicsByIds([chartId]);

          addWellEconomicsByWellInput(economicsData.Inputs);
        }

        if (
          !currentEconomicsIChartData.assetEconomicsRawData ||
          (currentEconomicsIChartData.assetEconomicsRawData &&
            hasTriggeredReset)
        ) {
          const updatedIchartData: IAssetEconomicsData = {
            ...currentEconomicsIChartData,
            assetEconomicsRawData: {
              defaultValues: economicsData,
              recalculatedValues: null,
              userValuesBeforeRecalculation: null,
            },
          };
          updateChartDataByKey(chartId, updatedIchartData);
        }
      }

      setTimeout(() => setIsEconomicsRecalculateLoading(false), 500);
    }
    setDisableExport(false);
    setHasTriggeredReset(false);
    setDisableReset(true);
    updateModuleRefetch();

    if (getDataFlag.refetch) {
      updateGetDataFlag({ chartId: "", refetch: false });
    }
  }, [economicsData]);

  useEffect(() => {
    handleSectionHeightUpdate(showSpinnerLoader);
  }, [showSpinnerLoader, warningMessage]);

  useEffect(() => {
    if (currentEconomicsIChartData?.assetEconomicsRawData?.recalculatedValues) {
      if (
        wellEconomicsInputInfo?.changedState &&
        !Object.values(wellEconomicsInputInfo.changedState).some((item) => item)
      ) {
        setDisableReset(true);
      } else {
        setDisableReset(false);
      }
      setDisableRecalculate(true);
    } else {
      setDisableReset(true);
    }
  }, [
    currentEconomicsIChartData?.assetEconomicsRawData,
    wellEconomicsInputInfo?.changedState,
  ]);

  return (
    <div
      className={classNames({
        "economics-fullScreen-individual":
          chartMode === CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART,
      })}
    >
      {!refetch && !showSpinnerLoader ? (
        <>
          <ChartTypeHeader
            chartId={chartId}
            isChartExport={false}
            chartMode={chartMode}
            onChartFullScreenClose={onChartFullScreenClose}
            chartRendered={false}
            chartRef={chartRef}
            onChartExportData={handleExport}
          />
          <div
            className="plot-chart-container asset-economics-container-accordion"
            id={chartId}
            ref={chartRef}
          >
            {warningMessage ? (
              <AssetEconomicsMessageDisplay message={warningMessage} />
            ) : (
              <>
                {currentEconomicsIChartData?.assetEconomicsRawData && (
                  <>
                    <WellPanelAccordion
                      expanded={isExpandedAccordion}
                      header={"Details"}
                      onChange={() =>
                        handleOnExpandDetails(!isExpandedAccordion)
                      }
                      isLoading={false}
                      className="cl-custom-accordion"
                    >
                      <div className="well-economics-fields-container">
                        {constants.WELL_ECONOMICS_INPUT_FIELDS.map((field) => (
                          <OutlinedInputField
                            key={field.key}
                            label={field.label}
                            suffix={formatWellEconomicsUnits(
                              field.key,
                              field.unit,
                              currentEconomicsIChartData.assetEconomicsRawData
                                ?.defaultValues.TargetUom
                            )}
                            value={
                              wellEconomicsInputInfo?.inputs?.[field.key] ?? ""
                            }
                            onChange={(e) => handleInputChange(e, field.key)}
                            onBlur={() => handleOnBlur(field.key)}
                            error={
                              wellEconomicsInputInfo?.errorState[field.key]
                            }
                            highlight={
                              !wellEconomicsInputInfo?.errorState[field.key] &&
                              wellEconomicsInputInfo?.changedState?.[field.key]
                            }
                            greyoutfilled={
                              wellEconomicsInputInfo?.greyedState?.[field.key]
                            }
                          />
                        ))}
                      </div>
                      <div className="economics-details-footer">
                        <Button
                          text="Reset"
                          type="secondary"
                          disabled={disableReset || isLoading}
                          onClick={handleReset}
                        />
                        <Button
                          text="Recalculate"
                          type="secondary"
                          disabled={disableRecalculate || isLoading}
                          onClick={() => handleRecalculate()}
                        />
                        <Button
                          text={
                            <>
                              <span>
                                <DownloadIcon />
                              </span>
                              <p>EXPORT</p>
                            </>
                          }
                          disabled={disableExport || loadingExport}
                          onClick={handleExport}
                        />
                      </div>
                    </WellPanelAccordion>
                    <WellEconomicsOutput
                      wellEconomicsData={
                        currentEconomicsIChartData.assetEconomicsRawData
                      }
                      isLoading={isEconomicsRecalculateLoading}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <LoadingBackDrop
          className={classNames("plot-chart-area loader")}
          isOpen
        />
      )}
    </div>
  );
};

export default AssetEconomics;
