import { useCallback, useMemo, useState } from "react";

import { MapData, RecordData } from "../../types/common/records";
import { GridDataObj } from "../../types/grid/useGridData";
import {
  SelectedKeys,
  SelectedKeysWithData,
} from "../../types/map/selection/common/selectionStore";
import { RecordType } from "../../types/panels/searchPanel/search";

import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";

import { getRecordIdByRecordType } from "../../utils/datagrid/getRecordSpecificConstants";

import useDataGridRecordStore from "./useDataGridRecordStore";
import useRecordSelectionStore from "./useRecordSelectionStore";

interface UseGridSelectRowProps {
  searchRecordType: RecordType;
}

export const useGridSelectRow = ({
  searchRecordType,
}: UseGridSelectRowProps) => {
  const [gridRecordObj, setGridRecordObj] = useState<
    GridDataObj | Record<string, never>
  >({});

  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType,
  });
  const allGridData = dataGridRecordStore((state) => state.allGridData);
  const gridFilteredCount = dataGridRecordStore(
    (state) => state.gridFilteredCount
  );

  const recordSelectionStore = useRecordSelectionStore({
    searchRecordType,
  });
  const selectedIds = recordSelectionStore((state) => state.selectedIds);
  const selectedIdsKeys = recordSelectionStore(
    (state) => state.selectedIdsKeys
  );
  const allSelectedGridData = recordSelectionStore(
    (state) => state.allSelectedGridData
  );
  const selectedRecordUwis = recordSelectionStore(
    (state) => state.selectedRecordUwis
  );
  const updateAllSelectedGridData = recordSelectionStore(
    (state) => state.updateAllSelectedGridData
  );
  const removeAllCurrentlySelectedIds = recordSelectionStore(
    (state) => state.removeAllCurrentlySelectedIds
  );
  const updateSelectedRecords = recordSelectionStore(
    (state) => state.updateSelectedRecords
  );
  const updateDeselectedRecords = recordSelectionStore(
    (state) => state.updateDeselectedRecords
  );

  const toggleIsHighlightSelectedSpots = useMapSettingsStore(
    (state) => state.toggleIsHighlightSelectedSpots
  );

  const selectedGridDataKeys = useMemo(() => {
    if (!selectedIdsKeys.length) toggleIsHighlightSelectedSpots(true);

    if (selectedIdsKeys.length && selectedIdsKeys.length < gridFilteredCount) {
      const midpointCount = gridFilteredCount / 2;

      if (selectedIdsKeys.length > midpointCount) {
        const deselectedIds: number[] = [];
        allGridData.forEach((data) => {
          const recordKey = getRecordIdByRecordType(data, searchRecordType);
          if (recordKey && !selectedIds[recordKey]) {
            deselectedIds.push(recordKey);
          }
        });
        updateDeselectedRecords(deselectedIds);
      } else {
        updateDeselectedRecords([]);
      }
    } else {
      updateDeselectedRecords([]);
    }

    return selectedIdsKeys;
  }, [selectedIdsKeys]);

  const convertRecordDataArrayToObject = (gridData: RecordData[]) => {
    const gridObj = gridData.reduce((a: GridDataObj, v: RecordData) => {
      const recordKey = getRecordIdByRecordType(v, searchRecordType);

      if (recordKey) a[recordKey] = v;

      return a;
    }, {});

    setGridRecordObj(gridObj);
  };

  const isSelectedRecordInGrid = (Ids: number[]) => {
    return Ids.every((id) => gridRecordObj.hasOwnProperty(id));
  };

  // getting of data based on the selected WellHeaderPermitKey
  const getSelectedData = useCallback(
    (selectedIds: { [key: string]: number | boolean }) => {
      const selectedMapData: { [key: string]: MapData } = {};
      allGridData
        .filter((data) => {
          const recordKey = getRecordIdByRecordType(data, searchRecordType);
          return recordKey && selectedIds[recordKey];
        })
        .forEach((data) => {
          const recordKey = getRecordIdByRecordType(data, searchRecordType);
          if (recordKey)
            selectedMapData[recordKey] = {
              WellID: data.WellID,
              PermitID: data.PermitID,
              ParentWellID: data.ParentWellID,
              BottomWellboreID: data.BottomWellboreID,
              UWI: data.UWI ?? "",
              RecordType: data.RecordType,
            };
        });

      return selectedMapData;
    },
    [allGridData]
  );

  const allSelectedRecordIdsKeys = useMemo(() => {
    const recordIDsToKeys = [
      ...Object.keys(allSelectedGridData).map((key) => parseInt(key)),
    ];
    return recordIDsToKeys;
  }, [allSelectedGridData]);

  const headerSelectCallback = () => {
    if (selectedGridDataKeys.length > 0) {
      // deselect all
      removeAllCurrentlySelectedIds();
    } else {
      // select all
      const recordIds: SelectedKeys = {};
      const recordData: SelectedKeysWithData = {};
      const recordIdKeys: number[] = [];
      const recordUwis: string[] = [];

      allGridData.forEach((data) => {
        const recordKey = getRecordIdByRecordType(data, searchRecordType);
        const mapData = {
          WellID: data.WellID,
          PermitID: data.PermitID,
          ParentWellID: data.ParentWellID,
          BottomWellboreID: data.BottomWellboreID,
          ...(data.UWI && { UWI: data.UWI }), //make UWI optional
          RecordType: data.RecordType,
        };
        if (recordKey) {
          recordIds[recordKey] = recordKey;
          recordData[recordKey] = mapData;
          recordIdKeys.push(recordKey);
          if (data.UWI && !selectedRecordUwis.includes(data.UWI))
            recordUwis.push(data.UWI);
        }
      });

      updateSelectedRecords(recordIds, recordData, recordIdKeys, recordUwis);
      updateAllSelectedGridData({ ...recordIds });
    }
  };

  const getCheckBoxElements = () => {
    const checkboxContainer = document.querySelector(
      `.${searchRecordType} .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root`
    );
    const svgCheckbox = document.querySelector(
      `.${searchRecordType} .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root svg`
    );
    const SVGCheckboxPath = document.querySelector(
      `.${searchRecordType} .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root svg path`
    );

    return { checkboxContainer, svgCheckbox, SVGCheckboxPath };
  };

  const setCheckboxHeaderInterdetminate = (determinateCheckbox: Element) => {
    const indeterminateSVGPath = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    indeterminateSVGPath.setAttribute(
      "d",
      "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z"
    );

    const checkBoxElements = getCheckBoxElements();

    if (checkBoxElements.checkboxContainer)
      checkBoxElements.checkboxContainer.classList.add(
        "MuiCheckbox-indeterminate"
      );
    if (checkBoxElements.svgCheckbox && checkBoxElements.SVGCheckboxPath)
      checkBoxElements.svgCheckbox.replaceChild(
        indeterminateSVGPath,
        checkBoxElements.SVGCheckboxPath
      );

    determinateCheckbox.setAttribute("data-indeterminate", "true");
  };

  const setCheckboxHeaderDeterminate = (indeterminateCheckbox: Element) => {
    const newSVGPath = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    newSVGPath.setAttribute(
      "d",
      "M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
    );

    const checkBoxElements = getCheckBoxElements();

    if (checkBoxElements.checkboxContainer)
      checkBoxElements.checkboxContainer.classList.remove(
        "MuiCheckbox-indeterminate"
      );
    if (checkBoxElements.svgCheckbox && checkBoxElements.SVGCheckboxPath)
      checkBoxElements.svgCheckbox.replaceChild(
        newSVGPath,
        checkBoxElements.SVGCheckboxPath
      );
    indeterminateCheckbox.setAttribute("data-indeterminate", "false");
  };

  const handleNewSelection = (
    selectedObj: SelectedKeys | { [key: string]: boolean }
  ) => {
    const newselectedData = getSelectedData(selectedObj);
    const newSelectedRecordData: SelectedKeysWithData = {};
    const newSelectedIds: SelectedKeys = {};
    const newSelectedIdsKeys: number[] = [];
    const newSelectedRecordUwis: string[] = [];

    for (const [key, value] of Object.entries(newselectedData)) {
      const recordKey = getRecordIdByRecordType(value, searchRecordType);

      if (recordKey) {
        newSelectedIds[recordKey] = recordKey;
        newSelectedRecordData[key] = value;
        newSelectedIdsKeys.push(recordKey);

        if (value.UWI) newSelectedRecordUwis.push(value.UWI);
      }
    }

    return {
      newSelectedRecordData,
      newSelectedIds,
      newSelectedIdsKeys,
      newSelectedRecordUwis,
    };
  };

  return {
    selectedGridDataKeys,
    allSelectedRecordIdsKeys,
    getSelectedData,
    headerSelectCallback,
    setCheckboxHeaderInterdetminate,
    setCheckboxHeaderDeterminate,
    convertRecordDataArrayToObject,
    isSelectedRecordInGrid,
    handleNewSelection,
  };
};
