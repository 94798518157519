import { ChangeEvent, useEffect, useState } from "react";

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
  TextField,
} from "@mui/material";

import config from "../../../configs/appSettings";

import {
  COLUMN_ACTION_ADD,
  COLUMN_ACTION_REMOVE,
  COLUMN_ACTION_STORED,
  COLUMN_GRID_MAXIMUM,
} from "../../../constants/grid";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useDataGridRecordStore from "../../../customHooks/grid/useDataGridRecordStore";
import { useGridData } from "../../../customHooks/grid/useGridData";
import useSearchStore from "../../../customHooks/search/useSearchStore";

import {
  allColumns,
  columnVisibilityList,
  getColumnKeyNames,
  getColumnVisibilityAction,
  shownColumnsCount,
} from "../../../utils/datagrid";

const DynamicColumn = () => {
  const pageLimit = config.searchPageLimit;
  // TEMP: temporarily set to well
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const columnVisibilityModel = dataGridRecordStore(
    (state) => state.columnVisibilityModel
  );
  const updateColumnVisibilityModel = dataGridRecordStore(
    (state) => state.updateColumnVisibilityModel
  );
  const updateColumnVisibilityAction = dataGridRecordStore(
    (state) => state.updateColumnVisibilityAction
  );

  const [columns, setColumns] = useState(allColumns);
  const columnsFetched = dataGridRecordStore((state) => state.columnsFetched);
  const [shownColumns, setShownColumns] = useState(columnsFetched);
  const updateColumnsPayload = dataGridRecordStore(
    (state) => state.updateColumnsPayload
  );
  const sortPayload = dataGridRecordStore((state) => state.sortPayload);
  const updateGridRecordDataTrigger = dataGridRecordStore(
    (state) => state.updateGridRecordDataTrigger
  );

  // TEMP: temporarily set to well
  const searchStore = useSearchStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const searchCriteria = searchStore((state) => state.searchCriteria);

  const { searchGridData } = useGridData();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newModel = {
      ...columnVisibilityModel,
      [event.target.name]: event.target.checked,
    };

    const { action, columnsArray } = getColumnVisibilityAction(
      newModel,
      columnVisibilityModel,
      columnsFetched
    );

    updateColumnVisibilityAction(action);

    if (action === COLUMN_ACTION_ADD) {
      updateColumnVisibilityModel(newModel);
      updateGridRecordDataTrigger("dynamicColumns");
      updateColumnsPayload(columnsArray);
      searchGridData({
        ...searchCriteria,
        offset: 0,
        pageLimit: pageLimit,
        columns: columnsArray,
        sort: sortPayload,
        optimizeColumns: true,
      });
      setShownColumns(getColumnKeyNames(newModel, false));
    } else if (
      action === COLUMN_ACTION_REMOVE ||
      action === COLUMN_ACTION_STORED
    ) {
      if (Object.keys(newModel).length === 0) {
        updateColumnVisibilityModel(columnVisibilityList);
      }

      if (shownColumnsCount(newModel) > 1) {
        updateColumnVisibilityModel(newModel);
        setShownColumns(getColumnKeyNames(newModel, false));
        updateColumnsPayload(columnsArray);
      }
    }
  };

  useEffect(() => {
    if (columnVisibilityModel) {
      setShownColumns(getColumnKeyNames(columnVisibilityModel, false));
    }
  }, [columnVisibilityModel]);

  const filter = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const keyword = event.target.value;

    if (keyword !== "") {
      const filtered = allColumns.filter((col) => {
        return col.headerName?.toLowerCase().includes(keyword.toLowerCase());
      });

      setColumns(filtered);
    } else {
      setColumns(allColumns);
    }
  };

  return (
    <FormControl
      component="fieldset"
      variant="standard"
      className="grid-toggle-columns-form"
    >
      <TextField
        type={"text"}
        fullWidth
        label="Find Column"
        className="search-field"
        onChange={filter}
        size="small"
        variant="standard"
        onKeyDown={(e) => {
          // prevent the input keyboard events from bubbling up
          // when the input is inside a menuItem component
          e.stopPropagation();
        }}
      />
      <FormGroup className="column-list-container">
        {columns.map(({ field, headerName }, index) => {
          return (
            <>
              {field !== "__check__" && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={shownColumns.indexOf(field) > -1}
                      onChange={handleChange}
                      name={`${field}`}
                      data-testid={`switch-${headerName}`}
                    />
                  }
                  label={headerName}
                  key={`column-${field}-${index}`}
                  className="column-list"
                />
              )}
            </>
          );
        })}
      </FormGroup>
      <FormHelperText className="helper-text">
        Max {COLUMN_GRID_MAXIMUM}
      </FormHelperText>
    </FormControl>
  );
};

export default DynamicColumn;
