import { FC } from "react";

import { RecordCounts } from "../../../../../../types/common/records";
import { VarcharBooleanProps } from "../../../../../../types/panels/searchPanel/queryBuilder";

import { BOOLEAN } from "../../../../../../constants/attributes";
import { initialOperationType } from "../../../../../../constants/panels/searchPanel/queryBuilder/store";

import useSearchStore from "../../../../../../customHooks/search/useSearchStore";

import QBTypeAheadComboBox from "./QBAutocomplete";

const QBBooleanAttribute: FC<VarcharBooleanProps> = ({
  attribute,
  attributeQBInfo,
  searchRecordType,
  isOptionsLoading,
  getOptionsWithWellCounts,
  modifyAttributeBounds,
}) => {
  const searchStore = useSearchStore({ searchRecordType });
  const updateCurrentQBInfo = searchStore((state) => state.updateCurrentQBInfo);

  const onAttributeSelect = (newValue: RecordCounts) => {
    // autocomplete automatically sets the new value
    // to null when clearing the value
    const attribValue = newValue?.attributeValue;
    const value = attribValue ? [attribValue] : [];
    updateCurrentQBInfo(attribute.key, "values", value);
    modifyAttributeBounds(value, initialOperationType[BOOLEAN]);
  };

  return (
    <div className="relative">
      <QBTypeAheadComboBox
        label={attribute.label}
        options={attributeQBInfo.options}
        isOptionsLoading={isOptionsLoading}
        value={
          attributeQBInfo?.values.length
            ? {
                attributeValue: attributeQBInfo.values[0].toString(),
                count: 0,
              }
            : null
        }
        inputValue={
          attributeQBInfo?.values.length
            ? attributeQBInfo.values[0].toString()
            : ""
        }
        onSelect={(e, value) => onAttributeSelect(value as RecordCounts)}
        onClick={getOptionsWithWellCounts}
        searchRecordType={searchRecordType}
      />
    </div>
  );
};

export default QBBooleanAttribute;
