import { RecordType } from "../../types/panels/searchPanel/search";

import {
  DEFAULT_LAYERS_TO_TOGGLE_ON,
  LAYERS_TO_TOGGLE_OFF,
  RECORD_TYPES,
} from "../../constants/panels/searchPanel/search";

import useMapStore from "../../store/map/mapStore";

const useSearchToggleLayers = () => {
  const toggleLayers = useMapStore((state) => state.toggleLayers);

  const updateLayersByRecordType = (
    prevRecordTypes: RecordType[],
    newRecordTypes: RecordType[]
  ) => {
    const allRecordTypes: RecordType[] = [
      RECORD_TYPES.WELL,
      RECORD_TYPES.PERMIT,
    ];
    for (const recType of allRecordTypes) {
      // toggled on
      if (
        !prevRecordTypes.includes(recType) &&
        newRecordTypes.includes(recType)
      ) {
        toggleLayers(DEFAULT_LAYERS_TO_TOGGLE_ON[recType], true);
      }
      // toggled off
      if (
        prevRecordTypes.includes(recType) &&
        !newRecordTypes.includes(recType)
      ) {
        toggleLayers(LAYERS_TO_TOGGLE_OFF[recType], false);
      }
      // no toggle change
      if (
        prevRecordTypes.includes(recType) &&
        newRecordTypes.includes(recType)
      ) {
        continue;
      }
    }
  };

  return { updateLayersByRecordType };
};

export default useSearchToggleLayers;
