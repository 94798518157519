import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box, LinearProgress } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridColumnHeaderParams,
  GridColumnOrderChangeParams,
  GridFilterModel,
  GridRowId,
  GridSortModel,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";

import classNames from "classnames";
import { debounce, isEqual } from "lodash";

import { setTimeoutType } from "../../types/common/general";
import { RecordData } from "../../types/common/records";
import { VisibleColumns } from "../../types/grid/export";
import { SelectedKeys } from "../../types/map/selection/common/selectionStore";
import { RecordType } from "../../types/panels/searchPanel/search";

import config from "../../configs/appSettings";

import { NON_ATTRIBUTE_COLUMNS } from "../../constants/grid";
import {
  RESET_GRID_TRIGGERS,
  SELECT_DRAW_TRIGGER,
  SELECT_GRID_TRIGGER,
  SELECT_MAP_TRIGGER,
} from "../../constants/grid/gridTrigger";

import useChartStore from "../../store/chart/chartStore";
import useDataGridStore from "../../store/grid/dataGridStore";
import usePanelsStore from "../../store/panels/panelsStore";

import { useChartModule } from "../../customHooks/charts/useChartModule";
import { useContainerDimensions } from "../../customHooks/common/useContainerDimentions";
import {
  useGridData,
  useGridFilter,
  useGridInfiniteLoader,
  useGridSelectRow,
  useGridSort,
} from "../../customHooks/grid";
import useDataGridRecordStore from "../../customHooks/grid/useDataGridRecordStore";
import { useGridSortBySelected } from "../../customHooks/grid/useGridSortBySelected";
import useGridWellPanel from "../../customHooks/grid/useGridWellPanel";
import useRecordSelectionStore from "../../customHooks/grid/useRecordSelectionStore";
import { useSelectedRecordByRecordType } from "../../customHooks/grid/useSelectedRecordByRecordType";
import { useModularity } from "../../customHooks/modularity/useModularity";
import useSearchStore from "../../customHooks/search/useSearchStore";
import useAllWellDataForExport from "../../customHooks/useAllWellDataForExport";
import usePrevious from "../../customHooks/usePrevious";

import {
  defaultFilterModel,
  getGridClassName,
  getGridContainerClassName,
  getHeaderClassName,
} from "../../utils/datagrid/";
import {
  getColumnsByRecordType,
  getRowIdByRecordType,
} from "../../utils/datagrid/getRecordSpecificConstants";
import { getUWIByProperties } from "../../utils/map/gis/common";

import CustomColumnMenu from "./CustomGridComponents/ColumnMenu/ColumnMenu";
import Footer from "./CustomGridComponents/Footer";
import GridPlotHeader from "./CustomGridComponents/Header/GridPlotHeader";
import HeaderFilterIconButton from "./CustomGridComponents/HeaderFilterIcon";
import NoResultDisplay from "./CustomGridComponents/NoResultDisplay";

interface DataGridProps {
  searchRecordType: RecordType;
}

const DataGrid = ({ searchRecordType }: DataGridProps) => {
  const pageLimit = config.searchPageLimit;
  const rowSize = 500;
  const handleRecordSelectionRef = useRef(false);
  const handleGridHeaderSelected = useRef(false);
  const isGridFilteredRef = useRef(false);
  const gridRef = useRef(null);
  const gridContainerRef = useRef(null);
  const apiRef = useGridApiRef();
  const [loadedRecordData, setLoadedRecordData] = useState<RecordData[]>([]); // will only contain wells loaded in grid by row size (currently, 500)
  const [currentRows, setCurrentRows] = useState(rowSize);
  const [hasMore, setHasMore] = useState(false);
  const [totalFetchedRows, setTotalFetchedRows] = useState(0);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [sortBySelectedDataFromApi, setSortBySelectedDataFromApi] =
    useState(false);
  const setTimeOutRef = useRef<setTimeoutType | null>(null);

  const recordSelectionStore = useRecordSelectionStore({
    searchRecordType,
  });
  const allSelectedGridData = recordSelectionStore(
    (state) => state.allSelectedGridData
  );
  const selectedIds = recordSelectionStore((state) => state.selectedIds);
  const selectedRecordData = recordSelectionStore(
    (state) => state.selectedRecordData
  );
  const updateSelectedRecords = recordSelectionStore(
    (state) => state.updateSelectedRecords
  );
  const addSelectedRecords = recordSelectionStore(
    (state) => state.addSelectedRecords
  );
  const removeSelectedIds = recordSelectionStore(
    (state) => state.removeSelectedIds
  );
  const updateSelectedMapParentWellIDs = recordSelectionStore(
    (state) => state.updateSelectedMapParentWellIDs
  );
  const updateSelectedBottomWellboreIDs = recordSelectionStore(
    (state) => state.updateSelectedBottomWellboreIDs
  );
  const selectedIdsKeys = recordSelectionStore(
    (state) => state.selectedIdsKeys
  );

  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );
  const chartData = useChartStore((state) => state.chartData);

  const searchStore = useSearchStore({ searchRecordType });
  const searchCriteria = searchStore((state) => state.searchCriteria);
  const isLoadingSavedSearchFileUpload = searchStore(
    (state) => state.isLoadingSavedSearchFileUpload
  );

  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType,
  });
  const isFullScreenGrid = dataGridRecordStore(
    (state) => state.isFullScreenGrid
  );
  const gridFilteredCount = dataGridRecordStore(
    (state) => state.gridFilteredCount
  );
  const batchWellGridData = dataGridRecordStore(
    (state) => state.batchWellGridData
  );
  const batchWellGridDataSuccess = dataGridRecordStore(
    (state) => state.batchWellGridDataSuccess
  );
  const batchWellGridDataLoading = dataGridRecordStore(
    (state) => state.batchWellGridDataLoading
  );
  const concatBatchesWellGridData = dataGridRecordStore(
    (state) => state.concatBatchesWellGridData
  );
  const updateConcatBatchesWellGridData = dataGridRecordStore(
    (state) => state.updateConcatBatchesWellGridData
  );
  const gridTotalCount = dataGridRecordStore((state) => state.gridTotalCount);
  const gridRecordDataTrigger = dataGridRecordStore(
    (state) => state.gridRecordDataTrigger
  );
  const updateApiRef = dataGridRecordStore((state) => state.updateApiRef);
  const sortModel = dataGridRecordStore((state) => state.sortModel);
  const columnsOrder = dataGridRecordStore((state) => state.columnsOrder);
  const updateColumnsOrder = dataGridRecordStore(
    (state) => state.updateColumnsOrder
  );
  const filterModel = dataGridRecordStore((state) => state.filterModel);
  const columnVisibilityModel = dataGridRecordStore(
    (state) => state.columnVisibilityModel
  );
  const allGridData = dataGridRecordStore((state) => state.allGridData);
  const allWellGridDataLoading = dataGridRecordStore(
    (state) => state.allWellGridDataLoading
  );
  const resetDefaultGridFilter = dataGridRecordStore(
    (state) => state.resetDefaultGridFilter
  );
  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const sortByAnalysis = dataGridRecordStore((state) => state.sortByAnalysis);
  const updateSortByAnalysis = dataGridRecordStore(
    (state) => state.updateSortByAnalysis
  );
  const recordSelectTrigger = dataGridRecordStore(
    (state) => state.recordSelectTrigger
  );
  const updateRecordSelectTrigger = dataGridRecordStore(
    (state) => state.updateRecordSelectTrigger
  );
  const { error: gridDataError } = useGridData(searchRecordType);
  const { sortGridCallback } = useGridSort();
  const { getNextBatchWellData, getNext500WellData } =
    useGridInfiniteLoader(searchRecordType);
  const { getMapGridFilterData } = useGridFilter();
  const {
    selectedGridDataKeys,
    headerSelectCallback,
    setCheckboxHeaderDeterminate,
    setCheckboxHeaderInterdetminate,
    convertRecordDataArrayToObject,
    isSelectedRecordInGrid,
    handleNewSelection,
  } = useGridSelectRow({ searchRecordType });
  const { exportDataAsExcelCallback } = useAllWellDataForExport();
  const { getGridSubContainerClassName, isOpenDstPanel } = useModularity();
  const { sortGridBySelected, getGridDataForSortBySelected } =
    useGridSortBySelected();
  const { wellPanelColumn } = useGridWellPanel();
  const { isSelectedAllGrid } = useSelectedRecordByRecordType({
    searchRecordType,
  });
  const { handleWellChartUpdate, handleWellPermitChartUpdate } =
    useChartModule();
  const { width } = useContainerDimensions(gridContainerRef);
  const prevSelectedIdsKeys = usePrevious(selectedIdsKeys);

  const handleGridRecordSelection = useCallback(
    (newSelectedIds: GridRowId[]) => {
      // if column header is clicked
      if (handleGridHeaderSelected.current) return;

      handleRecordSelectionRef.current = true;

      const prevLength = selectedGridDataKeys.length;
      const newLength = newSelectedIds.length;

      updateRecordSelectTrigger("grid");

      if (prevLength < newLength) {
        //ADD
        const IDsToAdd = newSelectedIds.filter((id) => !selectedIds[id]);
        const IDsObject: { [key: string]: boolean } = {};
        IDsToAdd.forEach((key) => {
          IDsObject[key] = true;
        });

        const newSelectionData = handleNewSelection(IDsObject);
        addSelectedRecords(
          newSelectionData.newSelectedIds,
          newSelectionData.newSelectedRecordData,
          newSelectionData.newSelectedIdsKeys,
          newSelectionData.newSelectedRecordUwis
        );
      } else {
        //REMOVE
        const updatedSelection: { [key: GridRowId]: boolean } = {};
        newSelectedIds.forEach((key) => {
          updatedSelection[key] = true;
        });
        const keysToRemove = selectedGridDataKeys.filter(
          (key: number) => !updatedSelection[key]
        );

        if (keysToRemove.length) removeSelectedIds(keysToRemove);
      }

      handleGridHeaderSelected.current = false;
    },
    [selectedIds, selectedGridDataKeys, allGridData]
  );

  useEffect(() => {
    if (
      selectedIdsKeys.length &&
      chartData.length &&
      !isEqual(prevSelectedIdsKeys, selectedIdsKeys)
    ) {
      handleWellChartUpdate();
    }
  }, [JSON.stringify(selectedIdsKeys)]);

  useEffect(() => {
    if (selectedGridDataKeys.length && chartData.length) {
      handleWellPermitChartUpdate();
    }
  }, [JSON.stringify(selectedGridDataKeys)]);

  useEffect(() => {
    // Manually disable select all checkbox when
    // all well grid data is still loading
    if (gridRef?.current) {
      const element: Document = gridRef.current;
      const selectAllCheckbox = element.querySelector(
        '[aria-label="Select all rows"]'
      );

      if (selectAllCheckbox) {
        if (allWellGridDataLoading) {
          selectAllCheckbox.setAttribute("disabled", "true");
        } else {
          selectAllCheckbox.removeAttribute("disabled");
        }
      }
    }

    // manual updating of selected wells when doing grid filter
    if (!allWellGridDataLoading && isGridFilteredRef.current) {
      const newSelectedData = handleNewSelection(allSelectedGridData);

      updateSelectedRecords(
        newSelectedData.newSelectedIds,
        newSelectedData.newSelectedRecordData,
        newSelectedData.newSelectedIdsKeys,
        newSelectedData.newSelectedRecordUwis
      );
    }
  }, [allWellGridDataLoading]);

  useEffect(() => {
    isLoadingSavedSearchFileUpload &&
      setLoading(isLoadingSavedSearchFileUpload);
  }, [isLoadingSavedSearchFileUpload]);

  //To style well grid selection in Map
  useEffect(() => {
    // Has these conditions that prevents this from
    // being called by other components
    if (
      handleRecordSelectionRef?.current ||
      isGridFilteredRef?.current ||
      handleGridHeaderSelected?.current
    ) {
      const uniqueParentWellIDs: SelectedKeys = {};
      const uniqueBottomWellboreIDs: SelectedKeys = {};
      const uniqueUWIs: SelectedKeys = {};

      for (const key in selectedRecordData) {
        const value = selectedRecordData[key];
        if (value && value.WellID) {
          const { ParentWellID, BottomWellboreID } = value;
          uniqueParentWellIDs[ParentWellID] = value.WellID;
          uniqueBottomWellboreIDs[BottomWellboreID] = value.WellID;
          //make UWI optional
          if (value.UWI) {
            const UWI = getUWIByProperties(value.UWI);
            uniqueUWIs[UWI] = value.WellID;
          }
        }
      }

      updateSelectedMapParentWellIDs(uniqueParentWellIDs);
      updateSelectedBottomWellboreIDs(uniqueBottomWellboreIDs);
      handleRecordSelectionRef.current = false;
      isGridFilteredRef.current = false;
    }
  }, [selectedRecordData]);

  const orderedColumns = useMemo(() => {
    const columns = getColumnsByRecordType(searchRecordType);
    return columnsOrder
      .filter((column) => columns.some((item) => item.field === column))
      .map((field) => columns.find((column) => column.field === field));
  }, [columnsOrder, searchRecordType]) as GridColDef[];

  const handleFilterCall = (newFilterModel: GridFilterModel) => {
    isGridFilteredRef.current = true;
    setLoading(true);
    if (sortByAnalysis) setSortBySelectedDataFromApi(true);
    getMapGridFilterData(newFilterModel);
  };

  const handleOnRowsScrollEnd = useCallback(() => {
    if (hasMore) {
      setLoading(true);
      if (currentRows >= totalFetchedRows) {
        if (
          (gridTotalCount > gridFilteredCount &&
            currentRows >= gridFilteredCount) ||
          (gridTotalCount === gridFilteredCount &&
            currentRows >= gridTotalCount)
        ) {
          setHasMore(false);
          setLoading(false);
        } else {
          if (concatBatchesWellGridData.length > 0) {
            const newOffset = offset + pageLimit;
            setOffset(newOffset);
            if (sortByAnalysis) setSortBySelectedDataFromApi(true);
            getNextBatchWellData(newOffset);
          }
        }
      } else {
        const { nextSetOfData, nextRowsCount } = getNext500WellData(
          currentRows,
          rowSize,
          totalFetchedRows,
          concatBatchesWellGridData
        );
        if (setTimeOutRef.current) {
          clearTimeout(setTimeOutRef.current);
          setTimeOutRef.current = null;
        }

        // 0.5 second delay
        setTimeOutRef.current = setTimeout(() => {
          setLoadedRecordData(loadedRecordData.concat(nextSetOfData));
          setHasMore(true);
          setCurrentRows(nextRowsCount);
          setLoading(false);
        }, 500);
      }
    }
  }, [
    hasMore,
    currentRows,
    totalFetchedRows,
    gridTotalCount,
    gridFilteredCount,
    concatBatchesWellGridData,
    offset,
    pageLimit,
    getNextBatchWellData,
    getNext500WellData,
    loadedRecordData,
  ]);

  const exportAsExcel = () => {
    //get visible columns
    const visibleColumns: VisibleColumns[] = apiRef.current
      .getVisibleColumns()
      .filter((col) => !NON_ATTRIBUTE_COLUMNS.includes(col.field))
      .map((filteredCol) => {
        return { field: filteredCol.field, header: filteredCol.headerName };
      });

    exportDataAsExcelCallback(concatBatchesWellGridData, visibleColumns);
  };

  const handleSortingCall = (newSortModel: GridSortModel) => {
    if (
      !searchCriteria?.currentBounds?.length &&
      !searchCriteria?.drawnPolygons?.length &&
      !searchCriteria?.identifier.identifiers?.length &&
      !searchCriteria?.identifier.fileId &&
      !searchCriteria?.shapeId
    )
      return;

    if (sortByAnalysis) setSortBySelectedDataFromApi(true);
    sortGridCallback(newSortModel);
  };

  const handleColumnHeaderClick = (
    e: GridColumnHeaderParams<GridValidRowModel>
  ) => {
    if (e.field === "__check__") {
      if (allWellGridDataLoading) return;
      handleGridHeaderSelected.current = true;

      updateRecordSelectTrigger("grid");
      headerSelectCallback();
    }
  };

  const handleColumnOrderChange = debounce(
    (params: GridColumnOrderChangeParams) => {
      const newColumnsOrder = columnsOrder.filter(
        (field) => params.column.field !== field
      );

      newColumnsOrder.splice(params.targetIndex - 1, 0, params.column.field);
      updateColumnsOrder(newColumnsOrder);
    },
    1000
  );

  const handleFilterReset = () => {
    resetDefaultGridFilter();
    apiRef.current.setFilterModel(defaultFilterModel);
  };

  const handledeterminateCheckbox = useCallback(() => {
    const determinateCheckbox = document.querySelector(
      `.${searchRecordType} .MuiDataGrid-columnHeaderCheckbox input[data-indeterminate='false']`
    );

    //set checkbox to be indeterminate
    if (determinateCheckbox) {
      handleGridHeaderSelected.current = false;
      setCheckboxHeaderInterdetminate(determinateCheckbox);
    } else {
      if (selectedGridDataKeys.length < gridFilteredCount) {
        handleGridHeaderSelected.current = false;
        setTimeout(() => {
          const determinateCheckbox = document.querySelector(
            `.${searchRecordType} .MuiDataGrid-columnHeaderCheckbox input[data-indeterminate='false']`
          );
          if (determinateCheckbox) {
            setCheckboxHeaderInterdetminate(determinateCheckbox);
          } else {
            //sometimes indeterminate is true but svg is not updated
            //case: select wells in map that are not yet loaded in grid
            //click "Selected for Analysis", then deselect again
            const indeterminateCheckbox = document.querySelector(
              `.${searchRecordType} .MuiDataGrid-columnHeaderCheckbox input[data-indeterminate='true']`
            );
            const pathIsIndeterminate = document.querySelector(
              `.${searchRecordType} .MuiDataGrid-columnHeaderCheckbox svg path[d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z']`
            );

            if (indeterminateCheckbox && !pathIsIndeterminate) {
              setCheckboxHeaderDeterminate(indeterminateCheckbox);
            }
          }
        }, 100);
      }
    }
  }, [
    selectedGridDataKeys,
    gridFilteredCount,
    searchRecordType,
    setCheckboxHeaderInterdetminate,
  ]);

  const handleIndeterminateCheckbox = useCallback(() => {
    const indeterminateCheckbox = document.querySelector(
      ".MuiDataGrid-columnHeaderCheckbox input[data-indeterminate='true']"
    );
    if (indeterminateCheckbox) {
      handleGridHeaderSelected.current = false;
      setCheckboxHeaderDeterminate(indeterminateCheckbox);
    }
  }, [setCheckboxHeaderDeterminate]);

  useEffect(() => {
    if (batchWellGridDataSuccess) {
      updateRecordSelectTrigger("");
      if (batchWellGridData && batchWellGridData.length > 0) {
        if (offset === 0) {
          const firstSetofWell = batchWellGridData.slice(0, rowSize);
          convertRecordDataArrayToObject(batchWellGridData);
          updateConcatBatchesWellGridData(batchWellGridData);
          setTotalFetchedRows(batchWellGridData.length);

          if (batchWellGridData.length > rowSize) {
            setHasMore(true);
            setCurrentRows(rowSize);
          } else {
            setHasMore(false);
            setCurrentRows(batchWellGridData.length);
          }

          setLoadedRecordData(firstSetofWell);
          if (!sortByAnalysis) setSortBySelectedDataFromApi(false);
        }

        if (offset > 0) {
          const next500rows = currentRows + rowSize;
          const concatGridData =
            concatBatchesWellGridData.concat(batchWellGridData);
          const nextSetofWells = batchWellGridData.slice(0, rowSize);
          convertRecordDataArrayToObject(concatGridData);
          updateConcatBatchesWellGridData(concatGridData);
          setTotalFetchedRows(concatGridData.length);

          if (next500rows < concatGridData.length) {
            setHasMore(true);
            setCurrentRows(next500rows);
          } else {
            setHasMore(false);
            setCurrentRows(concatGridData.length);
          }

          setLoadedRecordData(loadedRecordData.concat(nextSetofWells));
          if (!sortByAnalysis) setSortBySelectedDataFromApi(false);
        }

        if (!sortByAnalysis && selectedGridDataKeys.length) {
          handledeterminateCheckbox();
        }
      } else {
        setHasMore(false);
        setCurrentRows(0);
        updateConcatBatchesWellGridData([]);
        setLoadedRecordData([]);
        setTotalFetchedRows(0);

        setMessage("No result/s to display.");
      }
      setLoading(false);
    } else {
      if (gridTotalCount === 0 && !isLoadingSavedSearchFileUpload) {
        setMessage("No result/s to display.");
        updateConcatBatchesWellGridData([]);
        setLoadedRecordData([]);
        setTotalFetchedRows(0);
        setCurrentRows(0);
        setLoading(false);
      }
    }
  }, [batchWellGridDataSuccess, batchWellGridData, gridTotalCount]);

  useEffect(() => {
    if (gridDataError) {
      setLoading(false);
      setMessage("Something Went Wrong");
    }
  }, [gridDataError]);

  useEffect(() => {
    if (batchWellGridDataLoading) {
      setLoading(true);

      if (RESET_GRID_TRIGGERS.includes(gridRecordDataTrigger)) {
        //reset scroll back on top of the grid
        if (apiRef?.current?.scroll) {
          apiRef.current.scroll({ top: 0 });
        }
        setOffset(0);
      }
    }
  }, [batchWellGridDataLoading]);

  useEffect(() => {
    if (selectedGridDataKeys.length) {
      handledeterminateCheckbox();
    } else {
      handleIndeterminateCheckbox();
    }
  }, [selectedGridDataKeys]);

  useEffect(() => {
    if (!concatBatchesWellGridData.length) return;

    if (sortByAnalysis) {
      if (isSelectedAllGrid || isSelectedRecordInGrid(selectedGridDataKeys)) {
        const sortedData = sortGridBySelected(
          concatBatchesWellGridData,
          sortModel
        );
        const currentPageSizeData = sortedData.slice(0, currentRows);

        setLoadedRecordData(currentPageSizeData);
      } else {
        setSortBySelectedDataFromApi(true);
        getGridDataForSortBySelected();
      }
    } else {
      if (sortBySelectedDataFromApi) {
        setSortBySelectedDataFromApi(false);
        getGridDataForSortBySelected();
      } else {
        const currentPageSizeData = concatBatchesWellGridData.slice(
          0,
          currentRows
        );
        setLoadedRecordData(currentPageSizeData);
      }
    }
  }, [sortByAnalysis, sortModel]);

  useEffect(() => {
    if (!concatBatchesWellGridData.length || !sortByAnalysis) return;

    if (recordSelectTrigger === SELECT_GRID_TRIGGER) {
      if (selectedGridDataKeys.length) {
        //call the api to avoid data confusion in datagrid
        if (selectedGridDataKeys.length > concatBatchesWellGridData.length) {
          setSortBySelectedDataFromApi(true);
          getGridDataForSortBySelected();
        } else {
          if (sortBySelectedDataFromApi) {
            if (isSelectedRecordInGrid(selectedGridDataKeys)) {
              //pass non sorted by selected data to preserve grid sort order
              const sortedData = sortGridBySelected(
                concatBatchesWellGridData,
                sortModel
              );
              const currentPageSizeData = sortedData.slice(0, currentRows);
              setLoadedRecordData(currentPageSizeData);
            } else {
              setSortBySelectedDataFromApi(true);
              getGridDataForSortBySelected();
            }
          } else {
            //pass non sorted by selected data to preserve grid sort order
            const sortedData = sortGridBySelected(
              concatBatchesWellGridData,
              sortModel
            );
            const currentPageSizeData = sortedData.slice(0, currentRows);

            setLoadedRecordData(currentPageSizeData);
          }
        }
      } else {
        updateSortByAnalysis(false); //disable sort by selected if all wells are deselected
      }
    } else if (
      recordSelectTrigger === SELECT_DRAW_TRIGGER ||
      recordSelectTrigger === SELECT_MAP_TRIGGER
    ) {
      if (isSelectedRecordInGrid(selectedGridDataKeys)) {
        //pass non sorted by selected data to preserve grid sort order
        const sortedData = sortGridBySelected(
          concatBatchesWellGridData,
          sortModel
        );
        const currentPageSizeData = sortedData.slice(0, currentRows);
        setLoadedRecordData(currentPageSizeData);
      } else {
        setSortBySelectedDataFromApi(true);
        getGridDataForSortBySelected();
      }
    }
  }, [selectedGridDataKeys, concatBatchesWellGridData, sortModel]);

  useEffect(() => {
    updateApiRef(apiRef);
  }, [apiRef]);

  return (
    <div className={getGridContainerClassName(isFullScreenGrid)}>
      <div
        className={getGridClassName(isFullScreenGrid, width)}
        ref={gridContainerRef}
      >
        {showGridHeader && (
          <GridPlotHeader
            searchRecordType={searchRecordType}
            exportAsExcel={exportAsExcel}
            resetFilters={handleFilterReset}
          />
        )}

        <Box className={getGridSubContainerClassName()}>
          <Box
            className={getHeaderClassName(
              isOpenAppLevelNav,
              isOpenLeftSidePanel,
              isOpenDstPanel
            )}
          >
            <DataGridPremium
              ref={gridRef}
              className={classNames(`tgs-data-grid ${searchRecordType}`, {
                "data-loading": allWellGridDataLoading,
              })}
              columnHeaderHeight={38}
              columns={[wellPanelColumn, ...orderedColumns]}
              getRowId={(row) => getRowIdByRecordType(searchRecordType, row)}
              rows={loadedRecordData}
              rowSelectionModel={selectedGridDataKeys}
              apiRef={apiRef}
              onRowSelectionModelChange={handleGridRecordSelection}
              onRowsScrollEnd={handleOnRowsScrollEnd}
              rowHeight={26}
              loading={loading}
              sortModel={sortModel}
              sortingMode="server"
              onSortModelChange={(newSortModel) => {
                handleSortingCall(newSortModel);
              }}
              onColumnOrderChange={handleColumnOrderChange}
              columnVisibilityModel={columnVisibilityModel}
              onColumnHeaderClick={handleColumnHeaderClick}
              slots={{
                loadingOverlay: LinearProgress,
                footer: Footer,
                columnMenu: CustomColumnMenu,
                filterPanel: () => <></>,
                columnHeaderFilterIconButton: HeaderFilterIconButton,
                noRowsOverlay: NoResultDisplay,
                noResultsOverlay: NoResultDisplay,
              }}
              slotProps={{
                footer: { gridFilteredCount, currentRows },
                noRowsOverlay: { message },
                noResultsOverlay: { message },
              }}
              filterMode="server"
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => {
                handleFilterCall(newFilterModel);
              }}
              keepNonExistentRowsSelected
              disableColumnSelector
              checkboxSelection
            />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default DataGrid;
