import { create } from "zustand";

import { GridRecordsSelectionSlice } from "../../../types/map/selection/grid/gridRecordsSelectionStore";
import {
  MapRecordsSelectionSlice,
  SelectionStoreRecordType,
} from "../../../types/map/selection/map/mapRecordsSelectionStore";

import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import { gridRecordsSelectionSlice } from "../common/gridRecordSelectionSlice";
import { mapRecordsSelectionSlice } from "../common/mapRecordsSelectionSlice";

const useWellSelectionStore = create<
  GridRecordsSelectionSlice &
    MapRecordsSelectionSlice &
    SelectionStoreRecordType
>()((...a) => ({
  ...{ selectionStoreRecordType: RECORD_TYPES.WELL },
  ...gridRecordsSelectionSlice(...a),
  ...mapRecordsSelectionSlice(...a),
}));

export default useWellSelectionStore;
