import { memo, useMemo, useState } from "react";

import classNames from "classnames";

import { AnalysisChartsPanelProps } from "../../../types/panels/analysisChartsPanel/analysisChartsPanel";

import { CHART_MODE_VIEW } from "../../../constants/charts/chartModeView";

import useDataGridStore from "../../../store/grid/dataGridStore";
import useModularityStore from "../../../store/modularity/modularityStore";
import usePanelsStore from "../../../store/panels/panelsStore";

import usePanelsLayout from "../../../customHooks/panels/usePanelsLayout";

import AnalysisChartModularWindow from "../../modularity/AnalysisChartModularWindow";
import PanelControls from "../common/PanelControl";
import AnalysisChartsPanelHeader from "./AnalysisChartsPanelHeader";

const AnalysisChartsPanel = ({ open }: AnalysisChartsPanelProps) => {
  const chartMode = CHART_MODE_VIEW.DEFAULT;
  const modules = useModularityStore((state) => state.modules);

  const showGrid = useDataGridStore((state) => state.showGrid);
  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const gridHeight = useDataGridStore((state) => state.gridHeight);
  const isExpandGridWidth = useDataGridStore(
    (state) => state.isExpandGridWidth
  );

  const isOpenAnalysisPanel = usePanelsStore(
    (state) => state.isOpenAnalysisPanel
  );
  const toggleAnalysisPanel = usePanelsStore(
    (state) => state.toggleAnalysisPanel
  );

  const { getPanelHeight } = usePanelsLayout(true);

  const [panelRef, setPanelRef] = useState<HTMLDivElement | null>(null);

  const isOpenPanel: boolean = useMemo(
    () => !!modules.length && isOpenAnalysisPanel,
    [modules, isOpenAnalysisPanel]
  );

  const getModularWindowHeight: string = useMemo(() => {
    let height = "calc(100vh - 125px)";

    if (isExpandGridWidth) {
      if (showGrid) {
        height = `calc(100vh - (${gridHeight} + 102px))`;
      } else if (!showGrid && showGridHeader) {
        height = "calc(100vh - 182px)";
      }
    }

    return height;
  }, [gridHeight, showGrid, showGridHeader, isExpandGridWidth]);

  return (
    <div
      ref={setPanelRef}
      className={classNames("modularized-window-container", {
        open: isOpenPanel,
      })}
      style={{ height: getPanelHeight }}
    >
      <AnalysisChartsPanelHeader chartMode={chartMode} />
      <div
        className="modules-window"
        style={{ height: getModularWindowHeight }}
      >
        <div className="react-grid-layout">
          <AnalysisChartModularWindow chartMode={chartMode} />
        </div>
      </div>
      <PanelControls
        anchorEl={panelRef}
        open={open}
        reverse
        anchorOrigin={{
          horizontal: "left",
        }}
        clickCallback={() => {
          toggleAnalysisPanel();
        }}
      />
    </div>
  );
};

export default memo(AnalysisChartsPanel);
