import { StateCreator } from "zustand";

import {
  SelectedKeys,
  SelectedKeysWithData,
} from "../../../types/map/selection/common/selectionStore";
import { GridRecordsSelectionSlice } from "../../../types/map/selection/grid/gridRecordsSelectionStore";
import {
  MapRecordsSelectionSlice,
  SelectionStoreRecordType,
} from "../../../types/map/selection/map/mapRecordsSelectionStore";

import { getRecordIdByRecordType } from "../../../utils/datagrid/getRecordSpecificConstants";
import { getUpdatedSelectedStates } from "../../../utils/map/selection/wellSelection";

import { clone } from "../../../utils";
import useDataGridWellsStore from "../../grid/wells/dataGridWellsStore";
import { resetGridRecordsSelectionSlice } from "./gridRecordSelectionSlice";

const sliceResetFns = new Set<() => void>();

const resetMapRecordsSelectionSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialMapWellSelectionStates = {
  selectedMapParentWellIDs: {},
  selectedBottomWellboreIDs: {},
};

const mapRecordsSelectionSlice: StateCreator<
  GridRecordsSelectionSlice &
    MapRecordsSelectionSlice &
    SelectionStoreRecordType,
  [],
  [],
  MapRecordsSelectionSlice
> = (set, get) => {
  sliceResetFns.add(() => set(clone(initialMapWellSelectionStates)));
  return {
    ...initialMapWellSelectionStates,
    updateSelectedMapParentWellIDs: (selectedMapParentWellIDs) =>
      set(() => ({ selectedMapParentWellIDs })),
    addSelectedMapParentWellID: (PWID) =>
      set((state) => {
        const familyWells = useDataGridWellsStore
          .getState()
          .allGridData.filter((data) => data.ParentWellID === PWID);

        const newStates = getUpdatedSelectedStates(
          state,
          familyWells,
          get().selectionStoreRecordType
        );
        return newStates;
      }),
    addSelectedMapParentWellIDs: (PWIDs) =>
      set((state) => {
        const familyWells = useDataGridWellsStore
          .getState()
          .allGridData.filter((data) => !!PWIDs[data.ParentWellID]);

        const newStates = getUpdatedSelectedStates(
          state,
          familyWells,
          get().selectionStoreRecordType
        );
        return newStates;
      }),
    updateSelectedBottomWellboreIDs: (selectedBottomWellboreIDs) =>
      set(() => ({ selectedBottomWellboreIDs })),
    addSelectedBottomWellboreID: (selectedBottomWellboreID) =>
      set((state) => {
        const wellBoreCompletionWells = useDataGridWellsStore
          .getState()
          .allGridData.filter(
            (data) => data.BottomWellboreID === selectedBottomWellboreID
          );

        const newStates = getUpdatedSelectedStates(
          state,
          wellBoreCompletionWells,
          get().selectionStoreRecordType
        );
        return newStates;
      }),
    addMapSelectedIDs: (selectedId) => {
      const selectedData = useDataGridWellsStore
        .getState()
        .allGridData.filter((data) => {
          const recordId = getRecordIdByRecordType(
            data,
            get().selectionStoreRecordType
          );

          return recordId && selectedId[recordId];
        });
      const selectedIds: SelectedKeys = {};
      const selectedRecordData: SelectedKeysWithData = {};
      const selectedIdsKeys: number[] = [];
      const selectedRecordUwis: string[] = [];

      selectedData.forEach((data) => {
        const recordKey = getRecordIdByRecordType(
          data,
          get().selectionStoreRecordType
        );
        if (recordKey) {
          const mapData = {
            WellID: data.WellID,
            PermitID: data.PermitID,
            ParentWellID: data.ParentWellID,
            BottomWellboreID: data.BottomWellboreID,
            ...(data.UWI && { UWI: data.UWI }), //make UWI optional
            RecordType: data.RecordType,
          };
          selectedIds[recordKey] = recordKey;
          selectedRecordData[recordKey] = mapData;
          selectedIdsKeys.push(recordKey);

          if (data.UWI) selectedRecordUwis.push(data.UWI);
        }
      });

      get().addSelectedRecords(
        selectedIds,
        selectedRecordData,
        selectedIdsKeys,
        selectedRecordUwis
      );
    },
    resetAllMapSelections: () => {
      resetMapRecordsSelectionSlice();
      resetGridRecordsSelectionSlice();
    },
  };
};

export { mapRecordsSelectionSlice, resetMapRecordsSelectionSlice };
