import { useMemo } from "react";

import {
  AddCircleOutlineRounded,
  ShowChart as ShowChartIcon,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import classNames from "classnames";

import { ChartTypesMapper } from "../../../types/charts/chartType/chartType";

import {
  ANALYSIS_LIST_KEY,
  ANALYSIS_PANE_CHART_LIST,
  CHART_TYPES,
} from "../../../constants/charts/charts";

import useAnalysisChartToggle from "../../../customHooks/charts/useAnalysisChartToggle";
import { useSelectedRecord } from "../../../customHooks/grid/useSelectedRecord";

import { darkTheme, lightTheme } from "../../../themes";
import CustomCloseButton from "../../common/CustomCloseButton";
import TooltipInfoIcon from "../../common/icons/TooltipInfoIcon";
import { CustomTooltip } from "../../mapSettingsAccordions/CustomTooltip";
import useOutsideClick from "../../useOutsideClick";

export const AnalysisChartToggle = () => {
  const { selectedRecordKeys } = useSelectedRecord();

  const {
    open,
    anchorEl,
    modulesMenu,
    handleSelectItem,
    handleMenuOpen,
    handleMenuClose,
  } = useAnalysisChartToggle();

  const chartImg = (chart: ChartTypesMapper) => {
    return <img src={chart.src} alt={`${chart.type} Selector`} />;
  };

  const analyzeChartSelection = useMemo(() => {
    return (
      <ThemeProvider theme={darkTheme}>
        <Menu
          keepMounted
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className="analyze-chart-menu"
          slotProps={{
            paper: {
              className: classNames({
                "analysis-paper": ANALYSIS_LIST_KEY.length > 3,
              }),
            },
          }}
        >
          <div className="add-new-chart">
            <Grid container>
              <Grid item xs={11}>
                <Typography className="analyze-menu-label">
                  Select to Analyze
                </Typography>
              </Grid>

              <Grid item xs={1}>
                <CustomCloseButton aria-label="close" />
              </Grid>
            </Grid>

            <Divider />
          </div>
          {ANALYSIS_LIST_KEY.map((chart) => {
            if (ANALYSIS_PANE_CHART_LIST[chart].hideOption) return;

            return (
              <MenuItem
                divider
                key={chart}
                onClick={() =>
                  handleSelectItem(ANALYSIS_PANE_CHART_LIST[chart].type)
                }
              >
                {ANALYSIS_PANE_CHART_LIST[chart].show200MaxTooltip && (
                  <CustomTooltip
                    title={"200K Wells Max"}
                    placement="right"
                    arrow
                  >
                    <span
                      className={classNames("tooltip-info", {
                        "cash-flow-chart-tooltip":
                          ANALYSIS_PANE_CHART_LIST[chart].type ===
                          CHART_TYPES.CASH_FLOW,
                      })}
                    >
                      <TooltipInfoIcon />
                    </span>
                  </CustomTooltip>
                )}
                {chartImg(ANALYSIS_PANE_CHART_LIST[chart])}
              </MenuItem>
            );
          })}
        </Menu>
      </ThemeProvider>
    );
  }, [modulesMenu]);

  const chartsRef: any = useOutsideClick(handleMenuClose);

  return (
    <div
      className={classNames("analyze-button-container", {
        "charts-empty": !selectedRecordKeys.length,
        "charts-open": modulesMenu,
      })}
    >
      <ThemeProvider theme={lightTheme}>
        <Button
          onClick={(e: any) => {
            handleMenuOpen(e);
          }}
          startIcon={<ShowChartIcon className="charts-icon-button" />}
          endIcon={<AddCircleOutlineRounded />}
          ref={chartsRef}
        >
          <Typography>ANALYZE</Typography>
        </Button>
      </ThemeProvider>
      {analyzeChartSelection}
    </div>
  );
};

export default AnalysisChartToggle;
