import { GridColumns } from "../../../types/grid/column";

import * as attribute from "../../../constants/attributes";
import * as constants from "../../../constants/grid";

import { formatAttributeByKey } from "../../formatters/attributeFormatter";
import { getNumberFilterOperators } from "../filterOperators";

// Note: List only contains the default columns
export const allPermitColumns: GridColumns[] = [
  {
    field: "UWI",
    headerName: "UWI",
    minWidth: 150,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.PERMIT_NUMBER.key,
    headerName: attribute.PERMIT_NUMBER.label,
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.STATE_NAME.key,
    headerName: attribute.STATE_NAME.label,
    minWidth: 150,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.COUNTY.key,
    headerName: attribute.COUNTY.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.BASIN_NAME.key,
    headerName: attribute.BASIN_NAME.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.FIELD_NAME.key,
    headerName: attribute.FIELD_NAME.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.WELL_NAME.key,
    headerName: attribute.WELL_NAME.label,
    minWidth: 100,
    valueGetter: ({ value }) => value?.toString().trim(),
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.WELL_NUMBER.key,
    headerName: attribute.WELL_NUMBER.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.PERMIT_APPROVAL_DATE.key,
    headerName: attribute.PERMIT_APPROVAL_DATE.label,
    minWidth: 140,
    bigQueryType: constants.COLUMN_TYPE_DATE,
  },
  {
    field: attribute.WELL_STATUS.key,
    headerName: "Permit Status",
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.OPERATOR_NAME.key,
    headerName: attribute.OPERATOR_NAME.label,
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.ULTIMATE_OWNER.key,
    headerName: attribute.ULTIMATE_OWNER.label,
    minWidth: 190,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.WELL_TYPE.key,
    headerName: attribute.WELL_TYPE.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.PERMIT_TOTAL_DEPTH.key,
    headerName: attribute.PERMIT_TOTAL_DEPTH.label,
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    type: "number",
    headerAlign: "left",
    valueGetter: (params) =>
      formatAttributeByKey(
        attribute.PERMIT_TOTAL_DEPTH.key,
        params.value,
        true,
        false
      ),
    applyFormatting: true,
  },
  {
    field: "ProducingFormation",
    headerName: "Permit Formation",
    minWidth: 150,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.PERMIT_FORMATION_DEPTH.key,
    headerName: attribute.PERMIT_FORMATION_DEPTH.label,
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    type: "number",
    headerAlign: "left",
    valueGetter: (params) =>
      formatAttributeByKey(
        attribute.PERMIT_FORMATION_DEPTH.key,
        params.value,
        true,
        false
      ),
    applyFormatting: true,
  },
  {
    field: attribute.SLANT.key,
    headerName: attribute.SLANT.label,
    minWidth: 40,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "SurfaceLatitudeWGS84",
    headerName: "Surface Latitude WGS84",
    minWidth: 180,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_BIG_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "SurfaceLongitudeWGS84",
    headerName: "Surface Longitude WGS84",
    minWidth: 180,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_BIG_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
];
