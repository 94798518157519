import { useMemo } from "react";

import { SearchCriteria } from "../../../types/common/search";
import { RecordType } from "../../../types/panels/searchPanel/search";

import useMapDrawStore from "../../../store/map/draw/mapDrawStore";
import useStore from "../../../store/useStore";

import { containsAtLeastOne } from "../../../utils/helper2";
import { getPolygonType } from "../../../utils/map/draw/polygon";

import useSearchCallbackByRecordType from "../common/useSearchCallbackByRecordType";
import useSearchPanelActions from "../useSearchPanelActions";
import useSearchStore from "../useSearchStore";

interface UseIdentifierActionButtonsProps {
  searchRecordType: RecordType;
}

const useIdentifierActionButtons = ({
  searchRecordType,
}: UseIdentifierActionButtonsProps) => {
  const updateSavedSearchFileName = useStore(
    (state) => state.updateSavedSearchFileName
  );

  const drawnPolygons = useMapDrawStore((state) => state.drawnPolygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);
  const isUploadingShapeFile = useMapDrawStore(
    (state) => state.isUploadingShapeFile
  );

  const searchStore = useSearchStore({ searchRecordType });
  const selectedIdentifier = searchStore((state) => state.selectedIdentifier);
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const identifierSearchUploadedFileId = searchStore(
    (state) => state.identifierSearchUploadedFileId
  );
  const identifierSearchPastedValueText = searchStore(
    (state) => state.identifierSearchPastedValueText
  );
  const identifierSearchPastedValueList = searchStore(
    (state) => state.identifierSearchPastedValueList
  );
  const isIdentifierFormInvalid = searchStore(
    (state) => state.isIdentifierFormInvalid
  );
  const isLoadingIdentifierSearchCount = searchStore(
    (state) => state.isLoadingIdentifierSearchCount
  );
  const isLoadingIdentifierFileUpload = searchStore(
    (state) => state.isLoadingIdentifierFileUpload
  );

  const resetIdentifierStates = searchStore(
    (state) => state.resetIdentifierStates
  );

  // TODO: Remove this when useSearchCallbackByRecordType have callbacks for reset and save
  const {
    resetCallback,
    resetPolygons,
    resetAttribsPolygons,
    hasLoadedIdentifierSavedSearch,
  } = useSearchPanelActions();

  const { searchCallbackByRecordType } =
    useSearchCallbackByRecordType(searchRecordType);
  // Saving for Reference
  // const isIdentifierValueChange = useMemo(
  //   () =>
  //     !(
  //       JSON.stringify(identifierSearchPastedValueList) ===
  //         JSON.stringify(searchCriteria.identifiers) &&
  //       JSON.stringify(identifierSearchUploadedFileId) ===
  //         JSON.stringify(searchCriteria.fileId)
  //     ),
  //   [
  //     identifierSearchPastedValueList,
  //     identifierSearchUploadedFileId,
  //     searchCriteria.identifiers,
  //     searchCriteria.fileId,
  //   ]
  // );

  const isSearchDisabled = useMemo(
    () =>
      isIdentifierFormInvalid ||
      isLoadingIdentifierSearchCount ||
      isLoadingIdentifierFileUpload ||
      isUploadingShapeFile ||
      (!Boolean(identifierSearchUploadedFile) &&
        !Boolean(identifierSearchPastedValueText) &&
        !containsAtLeastOne(drawnPolygons) &&
        !Boolean(shapeId)),

    [
      isIdentifierFormInvalid,
      isLoadingIdentifierSearchCount,
      isLoadingIdentifierFileUpload,
      identifierSearchUploadedFile,
      identifierSearchPastedValueText,
      isUploadingShapeFile,
      drawnPolygons,
      shapeId,
    ]
  );

  const isResetDisabled = useMemo(
    () =>
      (!Boolean(identifierSearchUploadedFile) &&
        !Boolean(identifierSearchPastedValueText) &&
        !containsAtLeastOne(drawnPolygons) &&
        !Boolean(shapeId)) ||
      isUploadingShapeFile ||
      isLoadingIdentifierFileUpload,
    [
      identifierSearchUploadedFile,
      identifierSearchPastedValueText,
      isLoadingIdentifierFileUpload,
      isUploadingShapeFile,
      drawnPolygons,
      shapeId,
    ]
  );

  const handleReset = () => {
    updateSavedSearchFileName("");
    resetIdentifierStates();
    resetCallback(hasLoadedIdentifierSavedSearch);
    resetPolygons();
  };

  const handleSearch = () => {
    const newSearchCriteria: Partial<SearchCriteria> = {
      drawnPolygons,
      polygonType: getPolygonType(drawnPolygons),
      currentBounds: [],
      identifier: {
        type: selectedIdentifier,
        identifiers: identifierSearchPastedValueList,
        fileId: identifierSearchUploadedFileId ?? "",
      },
      shapeId,
    };

    searchCallbackByRecordType(newSearchCriteria);
  };

  return {
    isResetDisabled: isResetDisabled,
    isSaveDisabled: isSearchDisabled,
    isSearchDisabled: isSearchDisabled,
    isShowPrompt: false,
    hasLoadedSavedSearch: hasLoadedIdentifierSavedSearch,
    handleResetClick: handleReset,
    onClickSaveCallback: resetAttribsPolygons,
    handleSearchClick: handleSearch,
  };
};

export default useIdentifierActionButtons;
