import axios from "axios";

import { SearchRequestPayload } from "../../types/common/api";
import { RecordDataResponse } from "../../types/grid";

import config from "../../configs/appSettings";

const productionWellsUrl = `${config.endpoints.wellService}api/records/search/`;

export const getFirstBatch: (
  body: SearchRequestPayload,
  pageLimit: number
) => Promise<RecordDataResponse> = async (body, pageLimit) => {
  const res = await axios.post(productionWellsUrl, {
    ...body,
    offset: 0,
    pageLimit,
  });

  return res.data;
};
