import { SearchCriteria } from "../../../types/common/search";
import { RecordType } from "../../../types/panels/searchPanel/search";

import useDataGridRecordStore from "../../grid/useDataGridRecordStore";
import useExtentRecordStore from "../../map/extent/useExtentRecordStore";
import useSearchStore from "../useSearchStore";

const useSearchCallbackByRecordType = (searchRecordType: RecordType) => {
  const searchStore = useSearchStore({ searchRecordType });
  const updateSearchCriteria = searchStore(
    (state) => state.updateSearchCriteria
  );
  const isSearchAccordionToggledOn = searchStore(
    (state) => state.isSearchAccordionToggledOn
  );
  const updateIsSearchedRecordType = searchStore(
    (state) => state.updateIsSearchedRecordType
  );
  const searchTypeTab = searchStore((state) => state.searchTypeTab);
  const updateActiveSearchTypeTab = searchStore(
    (state) => state.updateActiveSearchTypeTab
  );

  const dataGridRecordStore = useDataGridRecordStore({ searchRecordType });
  const updateGridRecordDataTrigger = dataGridRecordStore(
    (state) => state.updateGridRecordDataTrigger
  );
  const updateSortByAnalysis = dataGridRecordStore(
    (state) => state.updateSortByAnalysis
  );
  const resetSortFilterModels = dataGridRecordStore(
    (state) => state.resetSortFilterModels
  );

  const extentRecordStore = useExtentRecordStore({ searchRecordType });
  const updateExtentInfo = extentRecordStore((state) => state.updateExtentInfo);

  const searchCallbackByRecordType = (
    searchCriteria: Partial<SearchCriteria>
  ) => {
    const newSearchCriteria = {
      ...searchCriteria,
      offset: 0,
      filters: [],
    };
    updateSearchCriteria(newSearchCriteria);
    updateIsSearchedRecordType(isSearchAccordionToggledOn);
    updateActiveSearchTypeTab(searchTypeTab);

    resetSortFilterModels();
    updateSortByAnalysis(false);
    updateGridRecordDataTrigger("searchPanel");

    updateExtentInfo({
      isLoading: true,
      data: [],
      error: "",
    });
  };

  return { searchCallbackByRecordType };
};

export default useSearchCallbackByRecordType;
