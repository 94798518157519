import { useCallback, useState } from "react";

import {
  ParsedProductionDataInfo,
  ProductionDataBody,
} from "../../types/charts/chartHooks/productionData";
import {
  ChartDataFields,
  IChartData,
} from "../../types/charts/chartType/chartType";
import { GroupedProductionResponseData } from "../../types/charts/chartType/productionChartData";

import config from "../../configs/appSettings";

import {
  CHARTS_FIELDS_TO_CHART_TYPE_MAPPER,
  CHART_DATA_SOURCE,
  CHART_GROUP_BY_TYPE,
} from "../../constants/charts/charts";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useSearchWellsStore from "../../store/search/wells/searchWellsStore";

import { extractGData, groupDataByFields } from "../../utils/charts/aggregator";

import { callServiceAPI } from "../../action/callServiceAPI";
import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useIdentifierFileUpload from "../search/identifier/useIdentifierFileUpload";
import useChartSearchRequest from "./useChartSearchRequest";

const productionChartsURL = `${config.endpoints.wellService}api/wells/productions`;

export const useProductionData = () => {
  const identifierSearchUploadedFile = useSearchWellsStore(
    (state) => state.identifierSearchUploadedFile
  );
  const searchCriteria = useSearchWellsStore((state) => state.searchCriteria);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ParsedProductionDataInfo | null>(null);

  const { getIdentifierFileIdWithRetry } = useIdentifierFileUpload(
    RECORD_TYPES.WELL
  );
  const { buildChartPostRequest } = useChartSearchRequest();

  const { isMetricOnSearch } = useUnitOfMeasure();

  const parseProductionData = useCallback(
    (data: any, chartData: IChartData) => {
      const groupByFieldsToUse =
        chartData?.groupBy === CHART_GROUP_BY_TYPE.DEFAULT
          ? ["production"]
          : ["name"];

      const extractedGData = extractGData(
        data.productions.filter(
          (p: any) =>
            p.source === CHART_DATA_SOURCE.PRODUCTION ||
            p.source === CHART_DATA_SOURCE.INJECTION ||
            p.source === CHART_DATA_SOURCE.VENTFLARE
        )
      );

      const groupedByChartData = groupDataByFields(
        extractedGData,
        groupByFieldsToUse
      );
      let groupedByChartDataForecast = {};
      if (chartData?.chartShowForecast) {
        const extractedForecastGData = extractGData(
          data.productions.filter(
            (p: any) => p.source === CHART_DATA_SOURCE.PRODUCTION_FORECAST
          )
        );

        groupedByChartDataForecast = groupDataByFields(
          extractedForecastGData,
          groupByFieldsToUse
        );
      }

      setData({
        groupedByChartData: groupedByChartData as GroupedProductionResponseData,
        groupedByChartDataForecast:
          groupedByChartDataForecast as GroupedProductionResponseData,
      });
    },
    []
  );

  const getProductionData = useCallback(
    async (chartData: IChartData) => {
      setIsLoading(true);
      setData(null);

      try {
        const forecastDatasets: any[] = []; //list for forecast data
        const dataSets: any[] = [];
        chartData.chartDisplayedDataFields.forEach(
          (dataField: ChartDataFields) => {
            const dataSetInfo = {
              accumulationMethod: dataField.accumMethod,
              data: dataField.name,
              source:
                dataField.dataSource === CHART_DATA_SOURCE.PRODUCTION &&
                chartData.chartShowForecast
                  ? CHART_DATA_SOURCE.PRODUCTION_UNION_FORECAST
                  : dataField.dataSource,
            };

            dataSets.push(dataSetInfo);
          }
        );
        const body: ProductionDataBody = {
          chartType: CHARTS_FIELDS_TO_CHART_TYPE_MAPPER[chartData.chartType],
          groupBy:
            chartData.groupBy === CHART_GROUP_BY_TYPE.DEFAULT
              ? "ALL"
              : chartData.groupBy,
          dataSet: dataSets,
          flags: {
            daily: chartData.chartShowDailyValues ?? false,
            normalizeByLateralLength:
              chartData.chartShowByNormalizedLateralLength ?? false,
          },
        };

        if (CHARTS_FIELDS_TO_CHART_TYPE_MAPPER[chartData.chartType]) {
          body.dataSet = [...body.dataSet, ...forecastDatasets];
        }

        body.searchRequest = {
          ...buildChartPostRequest(chartData.chartType),
          ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
        };

        const response: any = await callServiceAPI(
          productionChartsURL,
          body,
          getIdentifierFileIdWithRetry,
          identifierSearchUploadedFile,
          Boolean(searchCriteria.identifier.fileId)
        );

        parseProductionData(response.data, chartData);
      } catch (e) {
        console.log("getProductionData error", e);
      }

      setIsLoading(false);
    },
    [
      searchCriteria,
      isMetricOnSearch,
      identifierSearchUploadedFile,
      getIdentifierFileIdWithRetry,
      parseProductionData,
    ]
  );

  return { getProductionData, isLoading, data };
};

export default useProductionData;
