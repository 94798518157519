import { StateCreator } from "zustand";

import { ExtentRecordSlice } from "../../../../../types/map/gis/store";

import { clone } from "../../../../../utils";

const initialExtentInfo = { isLoading: false, data: [], error: "" };

const extentRecordSlice: StateCreator<ExtentRecordSlice> = (set, get) => ({
  extentInfo: clone(initialExtentInfo),
  updateExtentInfo: (extentInfo) =>
    set(() => ({
      extentInfo: {
        ...get().extentInfo,
        ...extentInfo,
      },
    })),
  resetExtentInfo: () =>
    set(() => ({
      extentInfo: clone(initialExtentInfo),
    })),
});

export { extentRecordSlice };
