import { useCallback, useEffect, useMemo } from "react";

import { UseSwitchRasterLayersProps } from "../../types/map/customHooks/useSwitchRasterLayers";

import { DYNAMIC_VECTOR_TILES } from "../../constants/constants";

import useDataGridStore from "../../store/grid/dataGridStore";
import useDataGridPermitsStore from "../../store/grid/permits/dataGridPermitsStore";
import useDataGridWellsStore from "../../store/grid/wells/dataGridWellsStore";
import useMapStore from "../../store/map/mapStore";
import useStore from "../../store/useStore";

import {
  getLayerDefinitionsKeys,
  getLayerGroupNamesByFetchType,
} from "../../utils";

export const useSwitchRasterLayers = ({
  selectedLayerNames,
  removeLayers,
  addLayers,
  map,
}: UseSwitchRasterLayersProps) => {
  const DVTUpdated = useMapStore((state) => state.DVTUpdated);
  const setDVTUpdated = useMapStore((state) => state.setDVTUpdated);
  const currentZoom = useMapStore((state) => state.currentZoom);
  const currentlySwitchedToDVT = useMapStore(
    (state) => state.currentlySwitchedToDVT
  );
  const setCurrentlySwitchedToDVT = useMapStore(
    (state) => state.setCurrentlySwitchedToDVT
  );

  const fetchedSavedSearchTrigger = useStore(
    (state) => state.fetchedSavedSearchTrigger
  );

  // TODO: Check if it is correct to combine these loaders in the condition
  const allWellGridDataLoading = useDataGridWellsStore(
    (state) => state.allWellGridDataLoading
  );
  const allPermitGridDataLoading = useDataGridPermitsStore(
    (state) => state.allWellGridDataLoading
  );

  const initialGridSearchMade = useDataGridStore(
    (state) => state.initialGridSearchMade
  );

  const selectedLayersWithDVT = useMemo(() => {
    const layerNamesWithDVT = getLayerGroupNamesByFetchType(
      getLayerDefinitionsKeys(),
      DYNAMIC_VECTOR_TILES
    );

    return selectedLayerNames.filter((name) =>
      layerNamesWithDVT.includes(name)
    );
  }, [selectedLayerNames]);

  const stringifiedSelectedLayersWithDVT = useMemo(
    () => JSON.stringify(selectedLayersWithDVT),
    [selectedLayersWithDVT]
  );

  const switchRasterAndDVTOnZoom: () => void = useCallback(() => {
    const cartoEnabledZoom = currentZoom >= 12;

    if (
      (!currentlySwitchedToDVT && cartoEnabledZoom) ||
      (currentlySwitchedToDVT && !cartoEnabledZoom)
    ) {
      removeLayers(selectedLayersWithDVT);
      addLayers(selectedLayersWithDVT);
      setCurrentlySwitchedToDVT(cartoEnabledZoom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stringifiedSelectedLayersWithDVT,
    currentZoom,
    initialGridSearchMade,
    removeLayers,
    addLayers,
  ]);

  const hasMap = useMemo(() => !!map, [map]);

  useEffect(() => {
    if (
      !hasMap ||
      DVTUpdated ||
      allWellGridDataLoading ||
      allPermitGridDataLoading ||
      fetchedSavedSearchTrigger
    )
      return;
    if (initialGridSearchMade) setDVTUpdated(true);

    if (
      selectedLayersWithDVT.length &&
      (currentZoom == 11 || currentZoom == 12) &&
      !initialGridSearchMade
    ) {
      switchRasterAndDVTOnZoom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasMap,
    DVTUpdated,
    allWellGridDataLoading,
    allPermitGridDataLoading,
    fetchedSavedSearchTrigger,
    initialGridSearchMade,
    currentZoom,
  ]);
};
