import { FC, useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { GridSlotsComponentsProps } from "@mui/x-data-grid-premium";

import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useDataGridRecordStore from "../../../customHooks/grid/useDataGridRecordStore";

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    currentRows: number;
    gridFilteredCount: number;
  }
}

const Footer: FC = (props: NonNullable<GridSlotsComponentsProps["footer"]>) => {
  const zeroPaginationText = "0 out of 0";
  const [paginationText, setPaginationText] = useState(zeroPaginationText);
  // TEMP: temporarily set to well
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const batchWellGridDataLoading = dataGridRecordStore(
    (state) => state.batchWellGridDataLoading
  );
  const batchWellGridDataSuccess = dataGridRecordStore(
    (state) => state.batchWellGridDataSuccess
  );
  const gridRecordDataTrigger = dataGridRecordStore(
    (state) => state.gridRecordDataTrigger
  );

  useEffect(() => {
    if (batchWellGridDataLoading) {
      if (["searchPanel", "savedSearch"].includes(gridRecordDataTrigger)) {
        setPaginationText(zeroPaginationText);
      } else {
        setPaginationText(
          `${props.currentRows} out of ${props.gridFilteredCount}`
        );
      }
    } else {
      if (batchWellGridDataSuccess) {
        setPaginationText(
          `${props.currentRows} out of ${props.gridFilteredCount}`
        );
      } else {
        setPaginationText(zeroPaginationText);
      }
    }
  }, [gridRecordDataTrigger, batchWellGridDataLoading, props]);

  return (
    <Typography variant="h6" className="grid-custom-footer">
      {paginationText}
    </Typography>
  );
};

export default Footer;
