import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { MenuItem, PopoverVirtualElement } from "@mui/material";

import { RecordData } from "../../types/common/records";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useMapHoverStore from "../../store/map/hover/mapHoverStore";

import useMapDistinctionMenuEvents from "../../customHooks/map/useMapDistinctionMenuEvents";

import { getTrimmedWellNamePlusNumber } from "../../utils";
import { Permit, WellSpot } from "../mapOverlays/legendsOverlay/LegendSymbol";
import HoverCard from "./hoverCard/HoverCard";

const MapDistinctionMenu = () => {
  const mapHoveredFeatures = useMapHoverStore(
    (state) => state.mapHoveredFeatures
  );
  const updateMapHoverData = useMapHoverStore(
    (state) => state.updateMapHoverData
  );

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<PopoverVirtualElement | null>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  const { handleOnClick, handleOnContextMenu, handleOnMouseEnter } =
    useMapDistinctionMenuEvents();

  const hasHoveredFeatures = useMemo(
    () => !!mapHoveredFeatures.length,
    [mapHoveredFeatures]
  );

  const getWellNameNumber = useCallback((data: RecordData | null) => {
    const { WellName, WellNumber } = data ?? {};
    return getTrimmedWellNamePlusNumber(WellName, WellNumber) ?? "N/A";
  }, []);

  const getKey = useCallback(
    (data: RecordData, layerName: string) => {
      return `${layerName.toLowerCase().replaceAll(" ", "")}-${
        data.RecordType
      }-${getWellNameNumber(data)}`;
    },
    [getWellNameNumber]
  );

  useEffect(() => {
    updateMapHoverData(undefined);
    if (mapHoveredFeatures.length) {
      const { x, y } = mapHoveredFeatures[0].info;
      const targetEl: PopoverVirtualElement = {
        nodeType: 1,
        getBoundingClientRect: () => new DOMRect(x, y, 0, 0),
      };
      setAnchorEl(targetEl);
      setOpen(true);
    } else {
      setAnchorEl(null);
      setOpen(false);
    }
  }, [mapHoveredFeatures]);

  return (
    hasHoveredFeatures && (
      <HoverCard
        popperRef={popperRef}
        open={open}
        anchorEl={anchorEl}
        placement="right-start"
        handleOnHoverAway={() => null}
        className="distinction-menu"
      >
        {mapHoveredFeatures.map(({ info, data }, idx) => (
          <MenuItem
            key={data ? getKey(data, info.layer?.id ?? "") : idx}
            onMouseEnter={() =>
              handleOnMouseEnter(anchorEl, popperRef, info, data, idx)
            }
            onClick={() => handleOnClick(info)}
            onContextMenu={(e) => handleOnContextMenu(e, data, info)}
          >
            {data?.RecordType === RECORD_TYPES.WELL ? (
              <WellSpot className="distinction-menu-icon" />
            ) : (
              <Permit className="distinction-menu-icon" />
            )}
            <div className="distinction-menu-labels">
              <span className="distinction-menu-uwi">{data?.UWI ?? "N/A"}</span>
              <span className="distinction-menu-well-name-number">
                {getWellNameNumber(data)}
              </span>
            </div>
          </MenuItem>
        ))}
      </HoverCard>
    )
  );
};

export default MapDistinctionMenu;
