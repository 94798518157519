import { create } from "zustand";

import { ExtentRecordState } from "../../../../../types/map/gis/store";

import { extentRecordSlice } from "../common/extentRecordSlice";

const useExtentWellsStore = create<ExtentRecordState>()((...a) => ({
  ...extentRecordSlice(...a),
}));

export default useExtentWellsStore;
