import { FC, PropsWithChildren, useMemo } from "react";

import { Resizable } from "re-resizable";
import "split-pane-react/esm/themes/default.css";

import useDataGridStore from "../../store/grid/dataGridStore";
import useModularityStore from "../../store/modularity/modularityStore";
import usePanelsStore from "../../store/panels/panelsStore";

import { useModularity } from "../../customHooks/modularity/useModularity";

import TabbedDataGrids from "../DataGrid/TabbedDataGrids";
import ChartTypeFullScreen from "../charts/fullScreen/ChartTypeFullScreen";
import AppFooter from "../layout/AppFooter";
import MapActionControls from "../mapActionControls/MapActionControls";
import AnalysisChartsPanel from "../panels/analysisChartsPanel/AnalysisChartsPanel";
import AnalysisChartsPanelFullScreen from "../panels/analysisChartsPanel/fullscreen/AnalysisChartsPanelFullScreen";
import PanelControls from "../panels/common/PanelControl";

const SagaModularWindow: FC<PropsWithChildren> = (props) => {
  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const showGrid = useDataGridStore((state) => state.showGrid);
  const gridHeight = useDataGridStore((state) => state.gridHeight);

  const isOpenAnalysisPanel = usePanelsStore(
    (state) => state.isOpenAnalysisPanel
  );
  const toggleAnalysisPanel = usePanelsStore(
    (state) => state.toggleAnalysisPanel
  );
  const modules = useModularityStore((state) => state.modules);

  const { onResize, getGridClassName } = useModularity();

  const isOpenPanel = useMemo(
    () => !!modules.length && isOpenAnalysisPanel,
    [modules, isOpenAnalysisPanel]
  );

  return (
    <div className="saga-modular-window">
      <div style={{ height: "100%" }}>{props.children}</div>
      <MapActionControls />

      {(showGrid || showGridHeader) && (
        <div className={`grid-modular-window ${getGridClassName}`}>
          <Resizable
            size={{ height: gridHeight, width: "100%" }}
            defaultSize={{
              height: 440,
              width: "100%",
            }}
            maxHeight="50vh"
            minHeight="83px"
            enable={{ top: showGrid, bottom: false }}
            onResize={onResize}
          >
            <TabbedDataGrids />
          </Resizable>
        </div>
      )}

      <AppFooter />

      {modules.length && (
        <PanelControls
          open={isOpenAnalysisPanel}
          reverse
          anchorOrigin={{
            horizontal: "left",
          }}
          clickCallback={() => toggleAnalysisPanel()}
        />
      )}
      {/* Charts Analysis */}
      <AnalysisChartsPanel open={isOpenPanel} />

      {/* Full Screen */}
      <AnalysisChartsPanelFullScreen />
      <ChartTypeFullScreen />
    </div>
  );
};

export default SagaModularWindow;
