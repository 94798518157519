import { useEffect, useMemo } from "react";

import { Button, Stack } from "@mui/material";

import classNames from "classnames";

import {
  DRAW_MODE_CIRCLE,
  DRAW_MODE_NONE,
  DRAW_MODE_POLYGON,
  DRAW_TO_SELECT_MODE,
} from "../../../constants/map/mapSettings";

import useDataGridStore from "../../../store/grid/dataGridStore";
import useMapDrawStore from "../../../store/map/draw/mapDrawStore";
import usePanelsStore from "../../../store/panels/panelsStore";
import usePermitSelectionStore from "../../../store/selection/permits/permitSelectionStore";
import useWellSelectionStore from "../../../store/selection/wells/wellSelectionStore";
import useStore from "../../../store/useStore";

import useDrawPolygon from "../../../customHooks/search/useDrawPolygon";

import LassoSelectIcon from "../../common/icons/LassoSelectIcon";
import TrashCanOutlineIcon from "../../common/icons/TrashCanOutlineIcon";

import "./../../../assets/scss/drawControls.scss";

const DrawToSelectControls = () => {
  const drawToSelectMode = useStore((state) => state.drawToSelectMode);
  const updateDrawToSelectMode = useStore(
    (state) => state.updateDrawToSelectMode
  );

  const drawToSelectModeListener = useStore(
    (state) => state.drawToSelectModeListener
  );
  const updateDrawToSelectModeListener = useStore(
    (state) => state.updateDrawToSelectModeListener
  );

  //Wells
  const removeAllCurrentlySelectedWellsIds = useWellSelectionStore(
    (state) => state.removeAllCurrentlySelectedIds
  );
  const updateSelectedMapParentWellIDs = useWellSelectionStore(
    (state) => state.updateSelectedMapParentWellIDs
  );
  const updateSelectedBottomWellboreIDs = useWellSelectionStore(
    (state) => state.updateSelectedBottomWellboreIDs
  );

  //Permits
  const removeAllCurrentlySelectedPermitIds = usePermitSelectionStore(
    (state) => state.removeAllCurrentlySelectedIds
  );
  const updateSelectedPermitIds = usePermitSelectionStore(
    (state) => state.updateSelectedRecords
  );

  const drawPolygonModeListener = useMapDrawStore(
    (state) => state.drawPolygonModeListener
  );

  const initialGridSearchMade = useDataGridStore(
    (state) => state.initialGridSearchMade
  );

  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );
  const isOpenAnalysisPanel = usePanelsStore(
    (state) => state.isOpenAnalysisPanel
  );

  const {
    drawToSelectCallback,
    drawToSelectCircleCallBack,
    updateDrawMode,
    drawToSelectDataLoading,
  } = useDrawPolygon();

  const updateIsDrawToSelectLoading = useStore(
    (state) => state.updateIsDrawToSelectLoading
  );

  useEffect(() => {
    updateIsDrawToSelectLoading(drawToSelectDataLoading);
  }, [drawToSelectDataLoading]);

  useEffect(() => {
    if (drawToSelectMode) {
      let drawToSelectCallbackFunction;

      switch (drawToSelectMode) {
        case DRAW_MODE_CIRCLE:
          drawToSelectCallbackFunction = drawToSelectCircleCallBack;
          break;
        default:
          drawToSelectCallbackFunction = drawToSelectCallback;
          break;
      }

      updateDrawMode({
        type: DRAW_TO_SELECT_MODE,
        mode: drawToSelectMode,
        listener: drawToSelectModeListener,
        externalListener: drawPolygonModeListener,
        updateListener: updateDrawToSelectModeListener,
        callback: drawToSelectCallbackFunction,
      });
    }
  }, [drawToSelectMode]);

  const handleSelection = (value: string) => {
    if (drawToSelectMode !== value) {
      updateDrawToSelectMode(value);
    } else {
      updateDrawToSelectMode(DRAW_MODE_NONE);
    }
  };

  const handleClear = () => {
    removeAllCurrentlySelectedWellsIds();
    removeAllCurrentlySelectedPermitIds();

    updateSelectedMapParentWellIDs({});
    updateSelectedBottomWellboreIDs({});
    updateSelectedPermitIds({}, {}, [], []);
  };

  const drawControlClassNames = useMemo(
    () =>
      classNames("draw-controls", {
        enabled: initialGridSearchMade,
        "app-level-open": isOpenAppLevelNav,
        "left-panel-open": isOpenAppLevelNav && isOpenLeftSidePanel,
        "analysis-panel-open": isOpenAnalysisPanel,
        "no-panel-open": !isOpenAppLevelNav && !isOpenAnalysisPanel,
      }),
    [
      initialGridSearchMade,
      isOpenAppLevelNav,
      isOpenLeftSidePanel,
      isOpenAnalysisPanel,
    ]
  );

  return (
    <div className={drawControlClassNames}>
      <Stack
        direction="row"
        spacing={1}
        style={{
          height: 30,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          disableRipple
          aria-label="free-form"
          onClick={() => handleSelection(DRAW_MODE_POLYGON)}
        >
          <LassoSelectIcon />
          <div className="draw-controls-text">
            <span className="fs-13">SELECT</span>
          </div>
        </Button>
        <Button disableRipple aria-label="clear" onClick={handleClear}>
          <TrashCanOutlineIcon />
          <div className="draw-controls-text">
            <span className="fs-13">CLEAR</span>
          </div>
        </Button>
      </Stack>
    </div>
  );
};

export default DrawToSelectControls;
