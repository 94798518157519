import { FC, useEffect, useState } from "react";

import { Box } from "@mui/material";
import { GridFilterItem, useGridApiContext } from "@mui/x-data-grid-premium";
import { DateValidationError } from "@mui/x-date-pickers";

import dayjs from "dayjs";

import {
  BtwDateValues,
  DateFilterItem,
  ExpressionBuilderProps,
} from "../../../../../../../types/grid";

import {
  YEAR_DATE_FORMAT,
  YEAR_FORMAT,
} from "../../../../../../../constants/constants";
import {
  BETWEEN,
  FILTER_DATE_EXPRESSION_BUILDER_OPERATORS,
  FILTER_EQUALS_OPERATOR,
  FILTER_TYPE_EXPRESSION_BUILDER,
  IS_ANY_OF,
  IS_EMPTY,
  YEAR,
} from "../../../../../../../constants/grid";
import { RECORD_TYPES } from "../../../../../../../constants/panels/searchPanel/search";

import useDataGridRecordStore from "../../../../../../../customHooks/grid/useDataGridRecordStore";
import usePrevious from "../../../../../../../customHooks/usePrevious";

import { defaultBetweenValues } from "../../../../../../../utils/datagrid/defaults";

import {
  generate5DigitUniqueRandomNumber,
  getFormattedDate,
} from "../../../../../../../utils";
import DateField from "../../../../../../common/DateField";
import YearField from "../../../../../../common/YearField";
import FilterFooter from "../../FilterFooter";
import FilterOperatorField from "./FilterOperatorField";

const DateFilter: FC<ExpressionBuilderProps> = ({ field, cancelPanel }) => {
  const apiRef = useGridApiContext();
  const INVALID_DATE = "Invalid Date";
  const MIN_DATE = dayjs("01/01/1800");
  const MAX_DATE = dayjs("12/31/2099");
  const [disableFilterButton, setDisableFilterButton] = useState(false);
  const filterItemDefault = {
    operator: FILTER_EQUALS_OPERATOR.key,
    payload: FILTER_EQUALS_OPERATOR.payload,
    value: null,
    field: field,
  };
  const [filterItem, setFilterItem] =
    useState<DateFilterItem>(filterItemDefault);
  const [btwValue, setBtwValue] = useState<BtwDateValues>(defaultBetweenValues);
  const [hasError, setHasError] = useState(false);

  // TEMP: temporarily set to well
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const filterModel = dataGridRecordStore((state) => state.filterModel);
  const updateColumnsFilterType = dataGridRecordStore(
    (state) => state.updateColumnsFilterType
  );
  const columnsFilterType = dataGridRecordStore(
    (state) => state.columnsFilterType
  );
  const prevFilterItem = usePrevious(filterItem);

  const handleFilterOperatorChange = (operator: string) => {
    const filterOperator = FILTER_DATE_EXPRESSION_BUILDER_OPERATORS.find(
      (item) => item.key === operator
    );

    if (filterOperator) {
      setFilterItem((prevItem) => ({
        ...prevItem,
        operator,
        payload: filterOperator.payload,
        value:
          operator === BETWEEN ||
          (operator !== BETWEEN && Array.isArray(prevItem.value))
            ? null
            : prevItem.value,
      }));
    }

    if (operator === BETWEEN) setDisableFilterButton(true);
  };

  const handleOnDateChange = (date: dayjs.Dayjs) => {
    setFilterItem({
      ...filterItem,
      value: date,
    });
  };

  const handleCancel = () => {
    cancelPanel();
  };

  const getFormattedValueByOperator = (
    value: dayjs.Dayjs[] | dayjs.Dayjs,
    operator: string
  ) => {
    switch (operator) {
      case YEAR:
        return Number(getFormattedDate(value, YEAR_FORMAT));
      case BETWEEN:
        if (Array.isArray(value)) {
          const val1 = getFormattedDate(value[0], YEAR_DATE_FORMAT);
          const val2 = getFormattedDate(value[1], YEAR_DATE_FORMAT);

          return [val1, val2];
        }
        return getFormattedDate(value, YEAR_DATE_FORMAT);
      default:
        return getFormattedDate(value, YEAR_DATE_FORMAT);
    }
  };

  const applyFilter = () => {
    if (filterItem.value) {
      const formattedDateValue = getFormattedValueByOperator(
        filterItem.value,
        filterItem.operator
      );
      const newFilterItem: GridFilterItem = {
        field: "",
        operator: "",
      };

      if (filterItem.id) {
        newFilterItem.id = filterItem.id;
        newFilterItem.field = field;
        newFilterItem.operator = filterItem.operator;
        newFilterItem.value = formattedDateValue;
      } else {
        const filterIds = filterModel.items.map((value) => value.id);
        const newFilterId = generate5DigitUniqueRandomNumber(filterIds);
        newFilterItem.id = newFilterId;
        newFilterItem.field = field;
        newFilterItem.operator = filterItem.operator;
        newFilterItem.value = formattedDateValue;

        setFilterItem({
          ...filterItem,
          id: newFilterId,
        });
      }

      //this only updates the filter model in grid, will not trigger api call
      //datagrid will call the api once changes are detected
      if (columnsFilterType[field] !== FILTER_TYPE_EXPRESSION_BUILDER) {
        const newFilterItems = filterModel.items.filter(
          (item) => item.field !== field
        );
        newFilterItems.push(newFilterItem);

        const newFilterModel = {
          ...filterModel,
          items: newFilterItems,
        };

        if (filterItem.operator !== BETWEEN)
          setBtwValue({ value1: null, value2: null });

        apiRef.current.setFilterModel(newFilterModel);
      } else {
        apiRef.current.upsertFilterItem(newFilterItem);
      }
    } else {
      if (filterItem.id && filterItem) {
        apiRef.current.deleteFilterItem(filterItem);
        setFilterItem(filterItemDefault);
      }
    }

    updateColumnsFilterType(field, FILTER_TYPE_EXPRESSION_BUILDER);
    handleCancel();
    apiRef.current.hideColumnMenu();
  };

  useEffect(() => {
    if (filterModel?.items.length) {
      const item = filterModel.items.find(
        (item) =>
          item.field === field &&
          item.operator !== IS_ANY_OF &&
          item.operator !== IS_EMPTY
      );

      if (item) {
        const operatorValue = FILTER_DATE_EXPRESSION_BUILDER_OPERATORS.find(
          (op) => op.key === item.operator
        );

        if (item.operator === BETWEEN) {
          const val1 = dayjs(item.value[0]);
          const val2 = dayjs(item.value[1]);
          setBtwValue({
            value1: val1,
            value2: val2,
          });

          setFilterItem((prevItem) => ({
            ...prevItem,
            id: item.id,
            payload: operatorValue?.payload ?? "",
            value: [val1, val2],
            operator: operatorValue?.key ?? "",
          }));
        } else {
          const val =
            item.operator === YEAR
              ? dayjs(`${item.value}-01-01`)
              : dayjs(item.value);
          setFilterItem((prevItem) => ({
            ...prevItem,
            id: item.id,
            payload: operatorValue?.payload ?? "",
            value: val,
            operator: operatorValue?.key ?? "",
          }));
        }
      }
    }
    setDisableFilterButton(true);
  }, []);

  useEffect(() => {
    if (hasError) {
      setDisableFilterButton(true);
    } else {
      if (filterItem.operator === BETWEEN) {
        if (
          Array.isArray(filterItem.value) &&
          filterItem.value?.length &&
          filterItem.value.every(
            (val) => getFormattedDate(val, YEAR_DATE_FORMAT) !== INVALID_DATE
          )
        ) {
          setDisableFilterButton(false);
        } else {
          setDisableFilterButton(true);
        }
      } else {
        if (prevFilterItem && prevFilterItem["value"] !== filterItem?.value) {
          setDisableFilterButton(true);
        } else {
          if (
            getFormattedDate(
              filterItem.value,
              filterItem.operator === YEAR ? YEAR_FORMAT : YEAR_DATE_FORMAT
            ) !== INVALID_DATE
          ) {
            setDisableFilterButton(false);
          } else {
            setDisableFilterButton(true);
          }
        }
      }
    }
  }, [hasError, filterItem.operator]);

  useEffect(() => {
    if (filterItem.operator === BETWEEN && btwValue.value1 && btwValue.value2) {
      const filterItemValue = [btwValue.value1, btwValue.value2];
      setFilterItem((prevItem) => ({
        ...prevItem,
        value: filterItemValue,
      }));
    }
  }, [btwValue]);

  return (
    <Box width={"280px"}>
      <FilterOperatorField
        operators={FILTER_DATE_EXPRESSION_BUILDER_OPERATORS}
        value={filterItem.operator}
        onChange={(val: string) => handleFilterOperatorChange(val)}
      />
      {filterItem.operator === BETWEEN ? (
        <>
          <DateField
            key={`filter-datefield-start`}
            handleChange={(val: dayjs.Dayjs) => {
              setBtwValue((prevVal) => ({
                ...prevVal,
                value1: val,
              }));
            }}
            handleError={(err: DateValidationError) =>
              setHasError(err ? true : false)
            }
            className="grid-filter-date-field"
            label={"Start"}
            value={btwValue.value1}
          />
          <DateField
            key={`filter-datefield-end`}
            handleChange={(val: dayjs.Dayjs) => {
              setBtwValue((prevVal) => ({
                ...prevVal,
                value2: val,
              }));
            }}
            handleError={(err: DateValidationError) =>
              setHasError(err ? true : false)
            }
            className="grid-filter-date-field"
            label={"End"}
            value={btwValue.value2}
          />
        </>
      ) : filterItem.operator === YEAR ? (
        <YearField
          label="Year"
          value={filterItem.operator === YEAR ? filterItem.value : null}
          handleChange={(val: dayjs.Dayjs) => {
            handleOnDateChange(val);
          }}
          handleError={(err: DateValidationError) => {
            setHasError(err ? true : false);
          }}
          className="grid-filter-date-field"
          maxDate={MAX_DATE}
          minDate={MIN_DATE}
        />
      ) : (
        <DateField
          key={`filter-datefield-date`}
          handleChange={(val: dayjs.Dayjs) => {
            handleOnDateChange(val);
          }}
          handleError={(err: DateValidationError) =>
            setHasError(err ? true : false)
          }
          className="grid-filter-date-field"
          label={"Start"}
          value={
            filterItem.operator !== YEAR && filterItem.operator !== BETWEEN
              ? filterItem.value
              : null
          }
          maxDate={MAX_DATE}
          minDate={MIN_DATE}
        />
      )}
      <FilterFooter
        buttons={[
          {
            text: "Cancel",
            disabled: false,
            variant: "text",
            onClick: handleCancel,
          },
          {
            text: "Apply Filter",
            disabled: disableFilterButton,
            variant: "outlined",
            onClick: applyFilter,
          },
        ]}
        paddingTop="20px"
      />
    </Box>
  );
};

export default DateFilter;
