import { Deck, MapView, PickingInfo } from "deck.gl";

import { isEmpty } from "lodash";

import { DataGridSelector } from "../../../types/grid";
import { CartoClickBackParams } from "../../../types/map/layers/carto";
import {
  RightClickTrigger,
  UpdateMapHoverData,
} from "../../../types/map/wellSpot/store";

import {
  PERMIT_PANEL_RIGHT_CLICK_ACTION,
  WELL_PANEL_RIGHT_CLICK_ACTION,
} from "../../../constants/panels/wellPanel/wellPanel";

import { getWellDataByPropertiesAndDataGrid } from "../gis/common";
import {
  getOverlappingFeatures,
  isHoveredLayerPermit,
  isHoveredLayerWellSpot,
} from "../mapHover";
import { mapWellSelection } from "../selection/wellSelection";

export const cartoClickCallback = (params: CartoClickBackParams) => {
  const { info, event, allGridData, deckGl } = params;
  const { ParentWellID, BottomWellboreID, PermitId } = info.object.properties;
  console.log("-------------");
  console.log("Full Info", info);
  console.log("event", event);
  console.log(`Layer Name: ${info.layer?.props.id}`);
  console.log(`ParentWellID: ${ParentWellID}`);
  console.log(`BottomWellboreID: ${BottomWellboreID}`);
  console.log(`PermitId: ${PermitId}`);
  console.log("-------------");

  if (
    event.rightButton &&
    params.dataGridSelector &&
    params.updateMapHoverData
  ) {
    window.addEventListener("contextmenu", (e) => e.preventDefault());
    rightClickCallback(
      info,
      params.dataGridSelector,
      params.updateMapHoverData,
      deckGl
    );
  } else if (
    allGridData?.length &&
    params.addSelectedMapParentWellID &&
    params.addSelectedBottomWellboreID &&
    params.addMapSelectedPermitIDs &&
    params.toggleLayer &&
    params.updateRecordSelectTrigger &&
    //prevent right click if has more than 1 overlapping features
    deckGl &&
    getOverlappingFeatures(deckGl, info).length === 1
  ) {
    mapWellSelection({
      info,
      addSelectedMapParentWellID: params.addSelectedMapParentWellID,
      addSelectedBottomWellboreID: params.addSelectedBottomWellboreID,
      addMapSelectedPermitIDs: params.addMapSelectedPermitIDs,
      toggleLayer: params.toggleLayer,
      updateRecordSelectTrigger: params.updateRecordSelectTrigger,
    });
  }
};

const rightClickCallback = (
  info: PickingInfo,
  dataGridSelector: DataGridSelector,
  updateMapHoverData: UpdateMapHoverData,
  deckGl?: Deck<MapView>
) => {
  if (
    (info?.object?.properties?.ParentWellID ||
      info?.object?.properties?.BottomWellboreID) &&
    !isEmpty(dataGridSelector.groupedByBottomWellboreID) &&
    !isEmpty(dataGridSelector.groupedByParentWellID) &&
    isHoveredLayerWellSpot(info)
  ) {
    setAndUpdateMapHoverData(
      info,
      dataGridSelector,
      updateMapHoverData,
      WELL_PANEL_RIGHT_CLICK_ACTION
    );
  } else if (
    info?.object?.properties?.PermitId &&
    isHoveredLayerPermit(info) &&
    !isEmpty(dataGridSelector.byPermitID) &&
    deckGl
  ) {
    if (getOverlappingFeatures(deckGl, info).length === 1) {
      setAndUpdateMapHoverData(
        info,
        dataGridSelector,
        updateMapHoverData,
        PERMIT_PANEL_RIGHT_CLICK_ACTION
      );
    }
  }
};

const setAndUpdateMapHoverData = (
  info: PickingInfo,
  dataGridSelector: DataGridSelector,
  updateMapHoverData: UpdateMapHoverData,
  trigger: RightClickTrigger
) => {
  const data = getWellDataByPropertiesAndDataGrid(
    info?.object,
    dataGridSelector,
    info?.layer?.id ?? ""
  );

  if (data && info.layer) {
    updateMapHoverData({
      info: data,
      layer: info.layer,
      trigger,
    });
  }
};
