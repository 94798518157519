import { create } from "zustand";

import { DataGridStates } from "../../types/grid/store/dataGridStore";

import dataGridSlice from "./dataGridSlice";

const useDataGridStore = create<DataGridStates>()((...a) => ({
  ...dataGridSlice(...a),
}));

export default useDataGridStore;
