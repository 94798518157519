import { RecordType } from "../../types/panels/searchPanel/search";

import DataGrid from "./Datagrid";

interface DataGridTabPanelProps {
  searchRecordType: RecordType;
  activeSearchRecordType: RecordType;
}

const DataGridTabPanel = ({
  searchRecordType,
  activeSearchRecordType,
}: DataGridTabPanelProps) => {
  return (
    <div
      role="tabpanel"
      className="data-grid-tab-panel"
      hidden={searchRecordType !== activeSearchRecordType}
    >
      <DataGrid searchRecordType={searchRecordType} />
    </div>
  );
};

export default DataGridTabPanel;
