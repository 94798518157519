import { SearchCriteria } from "../../../types/common/search";
import { RecordType } from "../../../types/panels/searchPanel/search";

import useMapDrawStore from "../../../store/map/draw/mapDrawStore";
import useSearchPanelStore from "../../../store/search/panel/useSearchPanelStore";

import { containsAtLeastOne } from "../../../utils/helper2";
import { getPolygonType } from "../../../utils/map/draw/polygon";

import useSearchCallbackByRecordType from "../common/useSearchCallbackByRecordType";
import useSearchPanelActions from "../useSearchPanelActions";
import useSearchStore from "../useSearchStore";

interface UseAttributeActionButtonsProps {
  searchRecordType: RecordType;
}

const useAttributeActionButtons = ({
  searchRecordType,
}: UseAttributeActionButtonsProps) => {
  const openedQBEditorPanel = useSearchPanelStore(
    (state) => state.openedQBEditorPanel
  );
  const updateOpenedQBEditorPanel = useSearchPanelStore(
    (state) => state.updateOpenedQBEditorPanel
  );

  const drawnPolygons = useMapDrawStore((state) => state.drawnPolygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);
  const isUploadingShapeFile = useMapDrawStore(
    (state) => state.isUploadingShapeFile
  );

  const searchStore = useSearchStore({ searchRecordType });
  const currentQBInfo = searchStore((state) => state.currentQBInfo);
  const currentBounds = searchStore((state) => state.currentBounds);
  const resetIdentifierStates = searchStore(
    (state) => state.resetIdentifierStates
  );

  // TODO: Remove this when useSearchCallbackByRecordType have callbacks for reset and save
  const { resetCallback, resetAttribsPolygons, hasLoadedAttribSavedSearch } =
    useSearchPanelActions();

  const { searchCallbackByRecordType } =
    useSearchCallbackByRecordType(searchRecordType);

  // Saving for Reference
  // Current Criteria vs. Searched Criteria
  // const isCriteriaChanged = useMemo(
  //   () =>
  //     !(
  //       JSON.stringify(currentBounds) ===
  //         JSON.stringify(searchCriteria.currentBounds) &&
  //       JSON.stringify(drawnPolygons) ===
  //         JSON.stringify(searchCriteria.drawnPolygons) &&
  //       JSON.stringify(shapeId) === JSON.stringify(searchCriteria.shapeId)
  //     ),
  //   [
  //     currentBounds,
  //     drawnPolygons,
  //     shapeId,
  //     searchCriteria.currentBounds,
  //     searchCriteria.drawnPolygons,
  //     searchCriteria.shapeId,
  //   ]
  // );

  const hasNoCriteriaOrPolygons = (isReset: boolean) => {
    let hasValue = false;
    if (containsAtLeastOne(drawnPolygons) || shapeId) hasValue = true;

    if (!isReset && !currentBounds.length && !hasValue) return true;

    const attributeValues = Object.values(currentQBInfo);
    let hasErrorValue = false;

    for (const attribValues of attributeValues) {
      const { hasError, values } = attribValues;

      // check if any attribute has error
      if (hasError) {
        hasErrorValue = true;
        break;
      }

      // check if any attribute has value and operationType
      if (!hasValue && containsAtLeastOne(values)) {
        hasValue = true;
      }
    }

    return hasErrorValue || !hasValue || isUploadingShapeFile;
  };

  const handleSearchAttribute = () => {
    const newSearchCriteria: Partial<SearchCriteria> = {
      drawnPolygons,
      polygonType: getPolygonType(drawnPolygons),
      currentBounds,
      identifier: { type: "", identifiers: [], fileId: "" },
      shapeId,
    };
    searchCallbackByRecordType(newSearchCriteria);
    closeAttributesPanel();
  };

  const handleResetAttribute = () => {
    resetAttribsPolygons();
    resetCallback(hasLoadedAttribSavedSearch);
  };

  const closeAttributesPanel = () => {
    if (openedQBEditorPanel) {
      updateOpenedQBEditorPanel(null);
    }
  };

  return {
    isResetDisabled: hasNoCriteriaOrPolygons(true),
    isSaveDisabled: hasNoCriteriaOrPolygons(false),
    isSearchDisabled: hasNoCriteriaOrPolygons(false),
    isShowPrompt: false,
    hasLoadedSavedSearch: hasLoadedAttribSavedSearch,
    handleResetClick: handleResetAttribute,
    onClickSaveCallback: resetIdentifierStates,
    handleSearchClick: handleSearchAttribute,
  };
};

export default useAttributeActionButtons;
