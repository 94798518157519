import { FC, MouseEvent, useEffect, useRef, useState } from "react";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Checkbox, ListItemIcon, MenuItem } from "@mui/material";
import {
  gridColumnMenuSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";

import { FILTER_TYPE_EXPRESSION_BUILDER } from "../../../../../../constants/grid";
import { RECORD_TYPES } from "../../../../../../constants/panels/searchPanel/search";

import useDataGridRecordStore from "../../../../../../customHooks/grid/useDataGridRecordStore";

import {
  dateFields,
  numberFields,
  varcharFields,
} from "../../../../../../utils/datagrid/wells/columns";

import { DateFilter, NumberFilter, VarcharFilter } from "./ExpressionBuilder";
import ExpressionBuilderContainer from "./ExpressionBuilderContainer";

const ExpressionBuilder: FC = () => {
  const apiRef = useGridApiContext();
  const openColumnMenuInfo = useGridSelector(apiRef, gridColumnMenuSelector);
  const [menuText, setMenuText] = useState("");
  const [fieldType, setFieldType] = useState("");
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const menuTriggerRef = useRef<{ onClickAway?: boolean } | null>(null);
  // TEMP: temporarily set to well
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const columnsFilterType = dataGridRecordStore(
    (state) => state.columnsFilterType
  );

  const handleItemClick = (event: MouseEvent) => {
    if (!menuTriggerRef.current?.["onClickAway"]) {
      setMenuAnchor(event.currentTarget);
    }
    if (menuTriggerRef.current) {
      menuTriggerRef.current = {
        ...menuTriggerRef["current"],
        onClickAway: false,
      };
    }
  };

  const handleOnClickAway = () => {
    menuTriggerRef.current = { ...menuTriggerRef.current, onClickAway: true };
    setMenuAnchor(null);
  };

  const closeExpressionBuilderPanel = () => {
    setMenuAnchor(null);
  };

  useEffect(() => {
    if (openColumnMenuInfo.field) {
      if (numberFields.includes(openColumnMenuInfo.field)) {
        setMenuText("Number Filter");
        setFieldType("number");
      } else if (dateFields.includes(openColumnMenuInfo.field)) {
        setMenuText("Date Filter");
        setFieldType("date");
      } else if (varcharFields.includes(openColumnMenuInfo.field)) {
        setMenuText("Text Filter");
        setFieldType("varchar");
      }
    }
  }, []);

  return menuText !== "" ? (
    <>
      {openColumnMenuInfo.field && (
        <>
          <MenuItem
            onClick={handleItemClick}
            className="grid-filter-expression-builder-menu"
            key={`${openColumnMenuInfo.field}exp-builder-menu-item`}
          >
            <Box className="expression-filter-list">
              <Checkbox
                edge="start"
                checked={
                  columnsFilterType[openColumnMenuInfo.field] ===
                  FILTER_TYPE_EXPRESSION_BUILDER
                }
                tabIndex={-1}
                disableRipple
              />
              {menuText}
            </Box>
            <ListItemIcon className="arrow-icon-container">
              <ArrowForwardIosIcon fontSize="small" />
            </ListItemIcon>
          </MenuItem>

          <ExpressionBuilderContainer
            menuAnchor={menuAnchor}
            onClickAway={handleOnClickAway}
          >
            <Box>
              {fieldType === "number" && (
                <NumberFilter
                  field={openColumnMenuInfo.field}
                  cancelPanel={closeExpressionBuilderPanel}
                />
              )}
              {fieldType === "date" && (
                <DateFilter
                  field={openColumnMenuInfo.field}
                  cancelPanel={closeExpressionBuilderPanel}
                />
              )}
              {fieldType === "varchar" && (
                <VarcharFilter
                  field={openColumnMenuInfo.field}
                  cancelPanel={closeExpressionBuilderPanel}
                />
              )}
            </Box>
          </ExpressionBuilderContainer>
        </>
      )}
    </>
  ) : (
    <></>
  );
};

export default ExpressionBuilder;
