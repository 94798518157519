import { FC } from "react";

import useWellSelectionStore from "../../../../store/selection/wells/wellSelectionStore";

import WarningIcon from "../../../common/icons/analysis/WarningIcon";

const AssetEconomicsMessageDisplay: FC<{ message: string }> = ({ message }) => {
  const selectedWellUwis = useWellSelectionStore(
    (state) => state.selectedRecordUwis
  );

  return (
    <div className="asset-economics-warning-message-container">
      <div className="warning-message-item">
        {Boolean(selectedWellUwis.length) && (
          <div>
            <WarningIcon />
          </div>
        )}
        <div className="analysis-warning-text">{message}</div>
      </div>
    </div>
  );
};

export default AssetEconomicsMessageDisplay;
