import { useEffect, useMemo } from "react";

import {
  ATTRIBUTES_TAB,
  IDENTIFIER_TAB,
} from "../../../constants/panels/searchPanel/common/accordion";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useExportCardStore from "../../../store/exportCard/exportCardStore";
import useExportMapStore from "../../../store/exportMap/exportMapStore";
import useDataGridWellsStore from "../../../store/grid/wells/dataGridWellsStore";
import useMapDrawStore from "../../../store/map/draw/mapDrawStore";
import usePanelsStore from "../../../store/panels/panelsStore";
import useSearchPanelStore from "../../../store/search/panel/useSearchPanelStore";
import useSearchWellsStore from "../../../store/search/wells/searchWellsStore";

import useSearchCountAttribute from "../../../customHooks/search/useSearchCountAttribute";
import useSearchCountIdentifier from "../../../customHooks/search/useSearchCountIdentifier";

import SearchAttributesContent from "./attributes/SearchAttributesContent";
import AccordionActionButtons from "./common/AccordionActionButtons";
import SearchPanelAccordion from "./common/SearchPanelAccordion";
import SearchIdentifierContent from "./identifier/SearchIdentifierContent";

const SearchWellsAccordion = () => {
  const drawnPolygons = useMapDrawStore((state) => state.drawnPolygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);

  const activePanel = usePanelsStore((state) => state.activePanel);
  const openedQBEditorPanel = useSearchPanelStore(
    (state) => state.openedQBEditorPanel
  );
  const updateOpenedQBEditorPanel = useSearchPanelStore(
    (state) => state.updateOpenedQBEditorPanel
  );

  const searchAttributeCount = useSearchWellsStore(
    (state) => state.searchAttributeCount
  );
  const searchIdentifierCount = useSearchWellsStore(
    (state) => state.searchIdentifierCount
  );
  const searchTypeTab = useSearchWellsStore((state) => state.searchTypeTab);
  const isSearchAccordionToggledOn = useSearchWellsStore(
    (state) => state.isSearchAccordionToggledOn
  );
  const updateSearchTypeTab = useSearchWellsStore(
    (state) => state.updateSearchTypeTab
  );
  const toggleIsSearchAccordionToggledOn = useSearchWellsStore(
    (state) => state.toggleIsSearchAccordionToggledOn
  );
  const resetCurrentQBInfo = useSearchWellsStore(
    (state) => state.resetCurrentQBInfo
  );
  const identifierSearchUploadedFile = useSearchWellsStore(
    (state) => state.identifierSearchUploadedFile
  );

  const allGridData = useDataGridWellsStore((state) => state.allGridData);

  const updateExportCard = useExportCardStore(
    (state) => state.updateExportCard
  );
  const updateExportUwiList = useExportCardStore(
    (state) => state.updateExportUwiList
  );

  const updateExportMapWellUwiList = useExportMapStore(
    (state) => state.updateExportMapWellUwiList
  );

  useSearchCountAttribute({ searchRecordType: RECORD_TYPES.WELL });
  useSearchCountIdentifier({ searchRecordType: RECORD_TYPES.WELL });

  const searchCount = useMemo(() => {
    if (searchTypeTab === ATTRIBUTES_TAB) return searchAttributeCount;
    if (searchTypeTab === IDENTIFIER_TAB) return searchIdentifierCount;
    return { count: 0, isLoading: false };
  }, [searchTypeTab, searchAttributeCount, searchIdentifierCount]);

  useEffect(() => {
    resetCurrentQBInfo("", "isUpdated");
    resetCurrentQBInfo("", "options");
  }, [drawnPolygons, shapeId]);

  useEffect(() => {
    if (openedQBEditorPanel !== RECORD_TYPES.WELL) return;

    if (searchTypeTab !== ATTRIBUTES_TAB) {
      updateOpenedQBEditorPanel(null);
    }
  }, [searchTypeTab]);

  useEffect(() => {
    updateExportCard(false);
  }, [activePanel]);

  useEffect(() => {
    if (allGridData.length > 0 && !identifierSearchUploadedFile) {
      const exportUwi: string[] = [];
      const exportMapUwi: string[] = [];
      allGridData.forEach((data) => {
        if (data.UWI) exportUwi.push(data.UWI);

        if (data.UWI && data.RecordType === RECORD_TYPES.WELL) {
          exportMapUwi.push(data.UWI);
        }
      });
      updateExportUwiList(exportUwi);
      updateExportMapWellUwiList(exportMapUwi);
    } else if (identifierSearchUploadedFile) {
      updateExportUwiList([]);
      updateExportMapWellUwiList([]);
    }
  }, [allGridData, identifierSearchUploadedFile]);

  return (
    <SearchPanelAccordion
      title="Wells"
      searchRecordType={RECORD_TYPES.WELL}
      searchCount={searchCount.count}
      isSearchCountLoading={searchCount.isLoading}
      isSearchToggledOn={isSearchAccordionToggledOn}
      toggleIsSearchToggledOn={toggleIsSearchAccordionToggledOn}
    >
      <AccordionActionButtons
        searchTypeTab={searchTypeTab}
        searchRecordType={RECORD_TYPES.WELL}
        updateSearchTypeTab={updateSearchTypeTab}
      />
      <div
        hidden={searchTypeTab !== ATTRIBUTES_TAB}
        className="accordion-search-fields-container"
      >
        <SearchAttributesContent searchRecordType={RECORD_TYPES.WELL} />
      </div>
      <div
        hidden={searchTypeTab !== IDENTIFIER_TAB}
        className="accordion-search-fields-container"
      >
        <SearchIdentifierContent searchRecordType={RECORD_TYPES.WELL} />
      </div>
    </SearchPanelAccordion>
  );
};

export default SearchWellsAccordion;
