import * as attribute from "../../../constants/attributes";

export const permitsColumnVisibilityList = {
  __check__: true,
  UWI: true,
  [attribute.STATE_NAME.key]: true,
  [attribute.COUNTY.key]: true,
  [attribute.WELL_NAME.key]: true,
  [attribute.WELL_NUMBER.key]: true,
  [attribute.FIELD_NAME.key]: true,
  [attribute.OPERATOR_NAME.key]: true,
  [attribute.WELL_TYPE.key]: true,
  [attribute.WELL_STATUS.key]: true,
  ProducingFormation: true, // DisplayFormation
  [attribute.SLANT.key]: true,
  [attribute.BASIN_NAME.key]: true,
  [attribute.PERMIT_NUMBER.key]: true,
  [attribute.PERMIT_APPROVAL_DATE.key]: true,
  [attribute.PERMIT_TOTAL_DEPTH.key]: true,
  [attribute.ULTIMATE_OWNER.key]: true,
  SurfaceLatitudeWGS84: true,
  SurfaceLongitudeWGS84: true,
  [attribute.PERMIT_FORMATION_DEPTH.key]: true,

  [attribute.COUNTRY.key]: false,
  [attribute.LEASE_NAME.key]: false,
  // PermitID: false, // need to check
  // PermitTypeCode: false, // new??
  [attribute.SECTION.key]: false,
  [attribute.TOWNSHIP.key]: false,
  [attribute.TOWNSHIP_DIRECTION.key]: false,
  [attribute.RANGE.key]: false,
  [attribute.RANGE_DIRECTION.key]: false,
  QuarterQuarter: false,
  DisplayLocation: false,
  Survey: false,
  [attribute.DISTRICT.key]: false,
  Abstract: false,
  Block: false,
  DistanceNS: false,
  DistanceNSD: false,
  DistanceEW: false,
  DistanceEWD: false,
  BottomLatitudeWGS84: false,
  BottomLongitudeWGS84: false,
  [attribute.PERMIT_POSTED_DATE.key]: false,
  [attribute.PERMIT_HAS_H2S.key]: false,
};
