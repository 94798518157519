import { useMemo } from "react";

import { RecordType } from "../../types/panels/searchPanel/search";

import useDataGridRecordStore from "./useDataGridRecordStore";
import useRecordSelectionStore from "./useRecordSelectionStore";

interface UseSelectedRecordByRecordTypeProps {
  searchRecordType: RecordType;
}

export const useSelectedRecordByRecordType = ({
  searchRecordType,
}: UseSelectedRecordByRecordTypeProps) => {
  const recordSelectionStore = useRecordSelectionStore({
    searchRecordType,
  });
  const selectedIdsKeys = recordSelectionStore(
    (state) => state.selectedIdsKeys
  );
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType,
  });
  const allGridData = dataGridRecordStore((state) => state.allGridData);
  const gridFilteredCount = dataGridRecordStore(
    (state) => state.gridFilteredCount
  );

  const isSelectedAllGrid = useMemo(() => {
    return Boolean(
      selectedIdsKeys.length === allGridData.length &&
        selectedIdsKeys.length &&
        allGridData.length
    );
  }, [selectedIdsKeys, allGridData]);

  const isDeselectedIds = useMemo(() => {
    if (selectedIdsKeys.length && selectedIdsKeys.length < gridFilteredCount) {
      const midpointCount = gridFilteredCount / 2;
      if (selectedIdsKeys.length > midpointCount) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [selectedIdsKeys, gridFilteredCount]);

  return {
    isSelectedAllGrid,
    isDeselectedIds,
  };
};
