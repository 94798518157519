import { FC } from "react";

import { styled } from "@mui/material";
import { GridColumnMenuContainerProps } from "@mui/x-data-grid-premium";

import { RECORD_TYPES } from "../../../../constants/panels/searchPanel/search";

import useDataGridStore from "../../../../store/grid/dataGridStore";

import useDataGridRecordStore from "../../../../customHooks/grid/useDataGridRecordStore";

import CustomFilter from "./Filter/Filter";
import MenuHeader from "./MenuHeader";
import PinColumnItem from "./PinColumnItem";
import SortColumnItem from "./SortColumnItem";

interface GridCustomMenuProp {
  gridHeight: number;
  isFullScreen: boolean;
}

const GridCustomMenu = styled("div")(
  ({ gridHeight, isFullScreen }: GridCustomMenuProp) => ({
    width: 270,
    maxHeight: isFullScreen ? 520 : `calc(100vh - ${65 + gridHeight}px)`,
    paddingTop: 0,
    overflowY: "auto",
  })
);

const CustomColumnMenu: FC<GridColumnMenuContainerProps> = ({
  colDef,
  hideMenu,
}) => {
  // TEMP: temporarily set to well
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const isFullScreenGrid = dataGridRecordStore(
    (state) => state.isFullScreenGrid
  );
  const gridHeight = useDataGridStore((state) => state.gridHeight);

  return (
    <GridCustomMenu
      gridHeight={parseInt(
        gridHeight.slice(0, gridHeight.length - 2) ?? "350px"
      )}
      isFullScreen={isFullScreenGrid}
    >
      <MenuHeader />
      {/* Sort Options */}
      <SortColumnItem hideMenu={hideMenu} colDef={colDef} />

      {/* Pin Column Options */}
      <PinColumnItem hideMenu={hideMenu} colDef={colDef} />

      {/* Custom Filter */}
      <CustomFilter />
    </GridCustomMenu>
  );
};

export default CustomColumnMenu;
