import { useMemo } from "react";

import { useOktaAuth } from "@okta/okta-react";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useMapHoverStore from "../../store/map/hover/mapHoverStore";
import useMapStore from "../../store/map/mapStore";
import usePanelsStore from "../../store/panels/panelsStore";
import usePermitSelectionStore from "../../store/selection/permits/permitSelectionStore";
import useWellSelectionStore from "../../store/selection/wells/wellSelectionStore";

import { getToken } from "../../utils/common/getToken";

import useDataGridRecordStore from "../grid/useDataGridRecordStore";

const useCartoClickProps = () => {
  const { authState } = useOktaAuth();
  // TEMP: temporarily set to well
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const allGridData = dataGridRecordStore((state) => state.allGridData);
  const updateRecordSelectTrigger = dataGridRecordStore(
    (state) => state.updateRecordSelectTrigger
  );
  const dataGridSelector = dataGridRecordStore(
    (state) => state.allWellSelectors
  );

  const toggleLayer = useMapStore((state) => state.toggleLayer);
  const deckGl = useMapStore((state) => state.deckGl);

  const selectedWellCardPWIDs = usePanelsStore(
    (state) => state.selectedWellCardPWIDs
  );
  const addSelectedWellCardPWID = usePanelsStore(
    (state) => state.addSelectedWellCardPWID
  );
  const selectedWellCardBWIDs = usePanelsStore(
    (state) => state.selectedWellCardBWIDs
  );
  const addSelectedWellCardBWID = usePanelsStore(
    (state) => state.addSelectedWellCardBWID
  );
  const selectedCardPermitIDs = usePanelsStore(
    (state) => state.selectedCardPermitIDs
  );
  const addSelectedCardPermitID = usePanelsStore(
    (state) => state.addSelectedCardPermitID
  );

  //Wells
  const addSelectedMapParentWellID = useWellSelectionStore(
    (state) => state.addSelectedMapParentWellID
  );
  const addSelectedMapParentWellIDs = useWellSelectionStore(
    (state) => state.addSelectedMapParentWellIDs
  );
  const addSelectedBottomWellboreID = useWellSelectionStore(
    (state) => state.addSelectedBottomWellboreID
  );

  //Permits
  const addMapSelectedPermitIDs = usePermitSelectionStore(
    (state) => state.addMapSelectedIDs
  );

  const updateMapHoverData = useMapHoverStore(
    (state) => state.updateMapHoverData
  );

  const cartoClickProps = useMemo(
    () => ({
      token: getToken(authState),
      allGridData,
      updateRecordSelectTrigger,

      selectedWellCardPWIDs,
      addSelectedWellCardPWID,
      selectedWellCardBWIDs,
      addSelectedWellCardBWID,
      selectedCardPermitIDs,
      addSelectedCardPermitID,

      // Map Selection
      addSelectedMapParentWellID,
      addSelectedMapParentWellIDs,
      addSelectedBottomWellboreID,
      addMapSelectedPermitIDs,

      // MISC
      toggleLayer,

      // right click well panel
      dataGridSelector,
      updateMapHoverData,
      deckGl,
    }),
    [
      authState,
      allGridData,
      updateRecordSelectTrigger,

      selectedWellCardPWIDs,
      addSelectedWellCardPWID,
      selectedWellCardBWIDs,
      addSelectedWellCardBWID,
      selectedCardPermitIDs,
      addSelectedCardPermitID,

      addSelectedMapParentWellID,
      addSelectedMapParentWellIDs,
      addSelectedBottomWellboreID,
      addMapSelectedPermitIDs,

      // MISC
      toggleLayer,

      //right click well panel
      dataGridSelector,
      updateMapHoverData,
      deckGl,
    ]
  );

  return {
    cartoClickProps,
  };
};

export default useCartoClickProps;
