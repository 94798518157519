import { useMemo } from "react";

import usePermitSelectionStore from "../../store/selection/permits/permitSelectionStore";
import useWellSelectionStore from "../../store/selection/wells/wellSelectionStore";

export const useSelectedRecord = () => {
  const selectedWellIdsKeys = useWellSelectionStore(
    (state) => state.selectedIdsKeys
  );

  const selectedPermitIdsKeys = usePermitSelectionStore(
    (state) => state.selectedIdsKeys
  );

  const selectedRecordKeys = useMemo(
    () => [...selectedPermitIdsKeys, ...selectedWellIdsKeys],
    [selectedPermitIdsKeys, selectedWellIdsKeys]
  );

  return { selectedRecordKeys };
};
