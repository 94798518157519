import { useCallback, useEffect } from "react";

import useExtentPermitsStore from "../../../store/map/gis/extent/permits/extentPermitsStore";
import useExtentWellsStore from "../../../store/map/gis/extent/wells/extentWellsStore";
import useMapStore from "../../../store/map/mapStore";
import useStore from "../../../store/useStore";

import { applyZoomToFit } from "../../../utils/map/extent";

import useZoomToFitPadding from "../useZoomToFitPadding";

const useMapExtent = () => {
  const map = useMapStore((state) => state.map);

  const extentWellsInfo = useExtentWellsStore((state) => state.extentInfo);
  const extentPermitsInfo = useExtentPermitsStore((state) => state.extentInfo);

  const enableZoomFit = useStore((state) => state.enableZoomFit);
  const toggleEnableZoomFit = useStore((state) => state.toggleEnableZoomFit);
  const DVTProcessing = useStore((state) => state.DVTProcessing);

  const {
    zoomToFitLeftPadding,
    zoomToFitRightPadding,
    zoomToFitBottomPadding,
  } = useZoomToFitPadding();

  const getMergedExtent = useCallback(() => {
    if (extentWellsInfo.data.length && extentPermitsInfo.data.length) {
      const [wLongMin, wLatMin, wLongMax, wLatMax] = extentWellsInfo.data;
      const [pLongMin, pLatMin, pLongMax, pLatMax] = extentPermitsInfo.data;
      return [
        Math.min(...[wLongMin, pLongMin]),
        Math.min(...[wLatMin, pLatMin]),
        Math.max(...[wLongMax, pLongMax]),
        Math.max(...[wLatMax, pLatMax]),
      ];
    }

    if (extentPermitsInfo.data.length) {
      return extentPermitsInfo.data;
    }
    return extentWellsInfo.data;
  }, [extentWellsInfo.data, extentPermitsInfo.data]);

  useEffect(() => {
    if (
      !!map &&
      !extentWellsInfo.isLoading &&
      !extentPermitsInfo.isLoading &&
      enableZoomFit &&
      !DVTProcessing
    ) {
      const zoomToFitPadding = {
        top: 50,
        right: zoomToFitRightPadding,
        bottom: zoomToFitBottomPadding,
        left: zoomToFitLeftPadding,
      };
      const mergedExtent = getMergedExtent();
      applyZoomToFit(map, mergedExtent, zoomToFitPadding);

      toggleEnableZoomFit(false);
    }
  }, [
    map,
    extentWellsInfo,
    extentPermitsInfo,
    DVTProcessing,
    enableZoomFit,
    toggleEnableZoomFit,
    zoomToFitBottomPadding,
    zoomToFitLeftPadding,
    zoomToFitRightPadding,
    getMergedExtent,
  ]);
};

export default useMapExtent;
