import { RecordType } from "../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import usePermitSelectionStore from "../../store/selection/permits/permitSelectionStore";
import useWellSelectionStore from "../../store/selection/wells/wellSelectionStore";

interface UseRecordSelectionStoreProps {
  searchRecordType: RecordType;
}

const useRecordSelectionStore = ({
  searchRecordType,
}: UseRecordSelectionStoreProps) => {
  const wellRecordSelectionStore = useWellSelectionStore;
  const permitRecordSelectionStore = usePermitSelectionStore;

  const getRecordSelectionStore = () => {
    if (searchRecordType === RECORD_TYPES.PERMIT) {
      return permitRecordSelectionStore;
    }

    return wellRecordSelectionStore;
  };

  return getRecordSelectionStore();
};

export default useRecordSelectionStore;
