import { StateCreator } from "zustand";

import {
  SelectedKeys,
  SelectedKeysWithData,
} from "../../../types/map/selection/common/selectionStore";
import { GridRecordsSelectionSlice } from "../../../types/map/selection/grid/gridRecordsSelectionStore";

import { clone } from "../../../utils";

const sliceResetFns = new Set<() => void>();

const resetGridRecordsSelectionSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialGridRecordSelectionStates = {
  //NON-FILTERED: list of selected WellHeaderPermitKey
  allSelectedGridData: {},

  //FILTERED: list of selected
  selectedIds: {},
  selectedRecordData: {}, //selected map data
  selectedRecordUwis: [],
  selectedIdsKeys: [],
  deselectedIdsKeys: [],
};

const gridRecordsSelectionSlice: StateCreator<GridRecordsSelectionSlice> = (
  set,
  get
) => {
  sliceResetFns.add(() => set(clone(initialGridRecordSelectionStates)));
  return {
    ...initialGridRecordSelectionStates,
    updateAllSelectedGridData: (allSelectedGridData) =>
      set(() => ({ allSelectedGridData })),
    updateDeselectedRecords: (deselectedIdsKeys) =>
      set(() => ({ deselectedIdsKeys })),
    updateSelectedRecords: (
      selectedIds,
      selectedRecordData,
      selectedIdsKeys,
      selectedRecordUwis
    ) =>
      set(() => ({
        selectedIds,
        selectedRecordData,
        selectedIdsKeys,
        selectedRecordUwis,
      })),
    addSelectedRecords: (
      selectedIds,
      selectedRecordData,
      selectedIdsKeys,
      selectedRecordUwis
    ) =>
      set((state) => ({
        selectedIds: { ...state.selectedIds, ...selectedIds },
        selectedRecordData: {
          ...state.selectedRecordData,
          ...selectedRecordData,
        },
        selectedIdsKeys: [...state.selectedIdsKeys, ...selectedIdsKeys],
        allSelectedGridData: {
          ...state.allSelectedGridData,
          ...selectedIds,
        },
        selectedRecordUwis: [
          ...state.selectedRecordUwis,
          ...selectedRecordUwis,
        ],
      })),
    removeSelectedIds: (keysToRemove) =>
      set((state) => {
        const updateObj = {
          selectedIds: state.selectedIds,
          selectedRecordUwis: state.selectedRecordUwis,
          selectedRecordData: state.selectedRecordData,
          allSelectedGridData: state.allSelectedGridData,
          selectedIdsKeys: state.selectedIdsKeys,
        };

        // Convert Object into Map
        const allSelectedGridDataMap = new Map(
          Object.entries(updateObj.allSelectedGridData)
        );

        // Remove key on the Map
        keysToRemove.forEach((key) => {
          allSelectedGridDataMap.delete(`${key}`);
        });

        // Convert Map back to Object
        updateObj.allSelectedGridData = Object.fromEntries(
          allSelectedGridDataMap
        );

        // For Optimization
        // Manual creation of object based on the allSelectedGridDataMap
        // This is faster than doing the convertion to Map three times.
        const newSelectedIds: SelectedKeys = {};
        const newSelectedRecordUwis: string[] = [];
        const newSelectedRecordData: SelectedKeysWithData = {};
        const newSelectedIdsKeys: number[] = [];
        for (const key of allSelectedGridDataMap.keys()) {
          if (updateObj.selectedIds[key]) {
            newSelectedIds[key] = updateObj.selectedIds[key];
            newSelectedRecordData[key] = updateObj.selectedRecordData[key];
            newSelectedIdsKeys.push(updateObj.selectedIds[key]);

            if (updateObj.selectedRecordData[key]) {
              const uwi = updateObj.selectedRecordData[key].UWI;
              if (uwi) newSelectedRecordUwis.push(uwi);
            }
          }
        }

        updateObj.selectedIds = newSelectedIds;
        updateObj.selectedRecordData = newSelectedRecordData;
        updateObj.selectedRecordUwis = newSelectedRecordUwis;
        updateObj.selectedIdsKeys = newSelectedIdsKeys;

        return updateObj;
      }),

    removeAllCurrentlySelectedIds: () => {
      get().removeSelectedIds(get().selectedIdsKeys);
    },
  };
};

export { gridRecordsSelectionSlice, resetGridRecordsSelectionSlice };
