import { create } from "zustand";

import { SearchStates } from "../../../types/panels/searchPanel/search";

import { searchAccordionSlice } from "../common/searchAccordionSlice";
import { searchCriteriaSlice } from "../common/searchCriteriaSlice";
import {
  identifierCountSlice,
  identifierFileUploadSlice,
  identifierPastedSlice,
  identifierSearchResetSlice,
  identifierTypeSlice,
  identifierValidationSlice,
} from "../common/searchIdentifierSlice";
import {
  currentBoundsSlice,
  queryBuilderEditorSlice,
  queryBuilderInfoSlice,
  queryBuilderUtilitySlice,
} from "../common/searchQueryBuilderSlice";
import wellsSearchCriteriaSlice from "./wellsSearchCriteriaSlice";

const useSearchWellsStore = create<SearchStates>()((...a) => ({
  ...searchAccordionSlice(...a),
  ...queryBuilderInfoSlice(...a),
  ...queryBuilderEditorSlice(...a),
  ...queryBuilderUtilitySlice(...a),
  ...currentBoundsSlice(...a),
  ...identifierTypeSlice(...a),
  ...identifierCountSlice(...a),
  ...identifierValidationSlice(...a),
  ...identifierFileUploadSlice(...a),
  ...identifierPastedSlice(...a),
  ...identifierSearchResetSlice(...a),
  ...searchCriteriaSlice(...a),
  ...{
    ...wellsSearchCriteriaSlice(...a),
  },
}));

export default useSearchWellsStore;
