import { useCallback } from "react";

import { ChartType } from "../../types/charts/chartType/chartType";
import {
  DashboardChartState,
  DashboardChartType,
} from "../../types/charts/dashboardChartType/dashboardChartType";
import {
  BaseSearchRequestNoPaginationPayload,
  ChartsSearchRequestPayload,
} from "../../types/common/api";

import { CHART_DATA_STATE, PERMIT_CHARTS } from "../../constants/charts/charts";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import usePermitSelectionStore from "../../store/selection/permits/permitSelectionStore";
import useWellSelectionStore from "../../store/selection/wells/wellSelectionStore";

import useRecordType from "../common/useRecordType";
import useSearchRequest from "../common/useSearchRequest";
import useDataGridRecordStore from "../grid/useDataGridRecordStore";
import { useSelectedRecordByRecordType } from "../grid/useSelectedRecordByRecordType";
import useSearchStore from "../search/useSearchStore";

// TODO: Decoupling | Re-construct to consider charts that only supports wells only and both wells and permits.
const useChartSearchRequest = () => {
  // TEMP: temporarily set to well
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const allGridData = dataGridRecordStore((state) => state.allGridData);

  // TEMP: temporarily set to well
  const searchStore = useSearchStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const searchCriteria = searchStore((state) => state.searchCriteria);
  // END TEMP: temporarily set to well

  //Wells
  const selectedWellIdsKeys = useWellSelectionStore(
    (state) => state.selectedIdsKeys
  );
  const deselectedWellIdsKeys = useWellSelectionStore(
    (state) => state.deselectedIdsKeys
  );

  //Permits
  const selectedPermitIdsKeys = usePermitSelectionStore(
    (state) => state.selectedIdsKeys
  );
  const deselectedPermitIdsKeys = usePermitSelectionStore(
    (state) => state.deselectedIdsKeys
  );

  // TEMP: temporarily set to well
  const { isSelectedAllGrid, isDeselectedIds } = useSelectedRecordByRecordType({
    searchRecordType: RECORD_TYPES.WELL,
  });

  const { searchedRecordTypes } = useRecordType();
  const { buildSearchRequest } = useSearchRequest();

  const isDashboardChartEmpty = useCallback(
    (chartDataState: DashboardChartState) =>
      // TODO: Double check if this is correct.
      searchedRecordTypes.includes(RECORD_TYPES.PERMIT) ||
      (!selectedWellIdsKeys.length &&
        chartDataState === CHART_DATA_STATE.POST_SEARCH),
    [selectedWellIdsKeys, searchedRecordTypes]
  );

  const buildChartPostRequest = useCallback(
    (chartType: DashboardChartType | ChartType) => {
      if (isSelectedAllGrid) {
        return buildSearchRequest(chartType);
      } else {
        if (isDeselectedIds) {
          let deSelectedSearchRequest = buildSearchRequest(chartType);

          if (deselectedWellIdsKeys.length) {
            deSelectedSearchRequest = {
              ...deSelectedSearchRequest,
              wellIds: deselectedWellIdsKeys,
              isDeselected: true,
            };
          }

          if (
            deselectedPermitIdsKeys.length &&
            PERMIT_CHARTS.includes(chartType)
          ) {
            deSelectedSearchRequest = {
              ...deSelectedSearchRequest,
              permitIds: deselectedPermitIdsKeys,
              isDeselected: true,
            };
          }
          return deSelectedSearchRequest;
        } else {
          const selectedSearchRequest: ChartsSearchRequestPayload<BaseSearchRequestNoPaginationPayload> =
            {};
          if (selectedWellIdsKeys.length) {
            selectedSearchRequest.wellIds = selectedWellIdsKeys;
          }

          if (
            selectedPermitIdsKeys.length &&
            PERMIT_CHARTS.includes(chartType)
          ) {
            selectedSearchRequest.permitIds = selectedPermitIdsKeys;
          }
          return selectedSearchRequest;
        }
      }
    },
    [
      isSelectedAllGrid,
      searchCriteria,
      selectedWellIdsKeys,
      selectedPermitIdsKeys,
      deselectedWellIdsKeys,
      deselectedPermitIdsKeys,
      allGridData,
      isDeselectedIds,
    ]
  );

  return {
    buildChartPostRequest,
    isDashboardChartEmpty,
  };
};

export default useChartSearchRequest;
