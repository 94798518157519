import { FC } from "react";
import { TransitionGroup } from "react-transition-group";

import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import classNames from "classnames";
import moment from "moment";

import { SavedSearchDeleteState } from "../../../types/panels/savedSearchPanel/component";
import { GetAllSavedSearchesResponse } from "../../../types/panels/savedSearchPanel/savedSearchData";

import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useExtentWellsStore from "../../../store/map/gis/extent/wells/extentWellsStore";
import useMapStore from "../../../store/map/mapStore";
import { resetMapOverlaySlice } from "../../../store/map/settings/mapOverlaySlice";
import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";
import usePanelsStore from "../../../store/panels/panelsStore";
import useSearchPermitsStore from "../../../store/search/permits/searchPermitsStore";
import useSearchWellsStore from "../../../store/search/wells/searchWellsStore";
import usePermitSelectionStore from "../../../store/selection/permits/permitSelectionStore";
import useWellSelectionStore from "../../../store/selection/wells/wellSelectionStore";
import useStore from "../../../store/useStore";

import useDataGridRecordStore from "../../../customHooks/grid/useDataGridRecordStore";
import useSearchPanelActions from "../../../customHooks/search/useSearchPanelActions";

import ActionButtons from "./ActionButtons";

interface Props {
  savedSearchList: GetAllSavedSearchesResponse;
  savedSearchDeleteState: SavedSearchDeleteState;
  getSavedSearch: (id: string) => void;
  handleDeleteClick: (id: number) => void;
  handleCancelClick: (id: number) => void;
  deletingCallback: (id: number) => void;
  deletedCallback: (id: number) => void;
}

const SavedSearchList: FC<Props> = ({
  savedSearchList,
  savedSearchDeleteState,
  getSavedSearch,
  handleDeleteClick,
  handleCancelClick,
  deletingCallback,
  deletedCallback,
}) => {
  const savedSearchFileName = useStore((state) => state.savedSearchFileName);
  const updateFetchedSavedSearchTrigger = useStore(
    (state) => state.updateFetchedSavedSearchTrigger
  );
  const resetSelectedSavedSearch = useStore(
    (state) => state.resetSelectedSavedSearch
  );
  const resetWellCard = useStore((state) => state.resetWellCard);

  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);
  const resetWellList = usePanelsStore((state) => state.resetWellList);
  const resetPermitList = usePanelsStore((state) => state.resetPermitList);

  const currentlySwitchedToDVT = useMapStore(
    (state) => state.currentlySwitchedToDVT
  );
  const setDVTUpdated = useMapStore((state) => state.setDVTUpdated);

  const toggleIsHighlightSelectedSpots = useMapSettingsStore(
    (state) => state.toggleIsHighlightSelectedSpots
  );
  const resetLayerStylingStates = useMapSettingsStore(
    (state) => state.resetLayerStylingStates
  );

  // TEMP: temporarily set to well
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const updateAllGridData = dataGridRecordStore(
    (state) => state.updateAllGridData
  );
  const updateGridRecordDataTrigger = dataGridRecordStore(
    (state) => state.updateGridRecordDataTrigger
  );

  const extentWellsStore = useExtentWellsStore();

  const searchWellsStore = useSearchWellsStore();
  const searchPermitsStore = useSearchPermitsStore();
  const selectionWellsStore = useWellSelectionStore();
  const selectionPermitsStore = usePermitSelectionStore();

  const { resetPolygons } = useSearchPanelActions();

  const handleResetStates = () => {
    resetPolygons();
    toggleIsHighlightSelectedSpots(true);
    resetSelectedSavedSearch();
    selectionWellsStore.resetAllMapSelections();
    selectionPermitsStore.resetAllMapSelections();
    searchWellsStore.resetQBStates();
    searchWellsStore.resetIdentifierStates();
    searchPermitsStore.resetQBStates();
    searchPermitsStore.resetIdentifierStates();
    updateAllGridData([]);
    resetMapOverlaySlice();
    resetLayerStylingStates();
    resetWellCard();
    resetWellList();
    resetPermitList();
    if (currentlySwitchedToDVT) {
      setDVTUpdated(true);
    }
  };

  const handleSavedSearchesListItemClick = async (id: string) => {
    updateFetchedSavedSearchTrigger(true);
    // TODO: handle updating of extent of permits
    // when loading a save search with permits
    extentWellsStore.updateExtentInfo({
      isLoading: true,
      data: [],
      error: "",
    });

    updateGridRecordDataTrigger("savedSearch");
    handleResetStates();
    getSavedSearch(id);
  };

  return (
    <List className="saved-search-list">
      <TransitionGroup>
        {savedSearchList.map((entity) => (
          <Collapse key={entity.id}>
            <ListItem
              divider
              disablePadding
              className={classNames("saved-search-list-item", {
                "not-delete-mode":
                  !savedSearchDeleteState[entity.id]?.onDeleteMode &&
                  !savedSearchDeleteState[entity.id]?.isDeleting,
              })}
              secondaryAction={
                <ActionButtons
                  saveSearchInfo={entity}
                  itemState={savedSearchDeleteState[entity.id]}
                  disabled={entity.searchName === savedSearchFileName}
                  handleDeleteClick={handleDeleteClick}
                  handleCancelClick={handleCancelClick}
                  deletingCallback={deletingCallback}
                  deletedCallback={deletedCallback}
                />
              }
            >
              <ListItemButton
                key={entity.id}
                disabled={
                  savedSearchDeleteState[entity.id]?.onDeleteMode ||
                  savedSearchDeleteState[entity.id]?.isDeleting
                }
                onClick={() => {
                  handleSavedSearchesListItemClick(entity.id.toString());
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="h5">{entity.searchName}</Typography>
                  }
                  secondary={
                    <Typography variant="h6">
                      {moment(new Date(entity.lastRunTime)).format(
                        "MMM DD, YYYY"
                      )}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </Collapse>
        ))}
      </TransitionGroup>
    </List>
  );
};

export default SavedSearchList;
