import { StateCreator } from "zustand";

import {
  DataGridCountStore,
  DataGridExportStore,
  DataGridRefStore,
  DataGridStateStore,
  DataGridTriggerStore,
} from "../../../types/grid";

const dataGridRefStore: StateCreator<DataGridRefStore> = (set) => ({
  apiRef: null,
  updateApiRef: (apiRef) => set(() => ({ apiRef })),
});

const dataGridExportStore: StateCreator<DataGridExportStore> = (set, get) => ({
  isExportToGridLoading: false,
  updateIsExportToGridLoading: (isExportToGridLoading) =>
    set((state) => ({ isExportToGridLoading })),
});

const dataGridCountSlice: StateCreator<DataGridCountStore> = (set, get) => ({
  gridTotalCount: 0,
  gridFilteredCount: 0,
  updateGridTotalCount: (gridTotalCount) =>
    set((state) => ({ gridTotalCount })),
  updateGridFilteredCount: (gridFilteredCount) =>
    set((state) => ({ gridFilteredCount })),
});

const dataGridStateStore: StateCreator<DataGridStateStore> = (set, get) => ({
  initialWellDataFetched: false,
  updateInitialWellDataFetched: (initialWellDataFetched) =>
    set((state) => ({
      initialWellDataFetched,
    })),
  isFullScreenGrid: false,
  toggleFullScreenGrid: (status) =>
    set((state) => ({ isFullScreenGrid: status })),
});

const dataGridTriggerStore: StateCreator<DataGridTriggerStore> = (
  set,
  get
) => ({
  gridRecordDataTrigger: "",
  updateGridRecordDataTrigger: (gridRecordDataTrigger) =>
    set((state) => ({ gridRecordDataTrigger })),
});

export {
  dataGridCountSlice,
  dataGridExportStore,
  dataGridRefStore,
  dataGridStateStore,
  dataGridTriggerStore,
};
