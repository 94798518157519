import { useEffect, useMemo } from "react";

import { Chip } from "@mui/material";

import { IdentifierFormError } from "../../../../types/panels/searchPanel/identifier/formError";
import { RecordType } from "../../../../types/panels/searchPanel/search";

import { IDENTIFIER_TYPE_INFO } from "../../../../constants/panels/searchPanel/identifier/types";

import useIdentifierFileUploadHandler from "../../../../customHooks/search/identifier/useIdentifierFileUploadHandler";
import useSearchStore from "../../../../customHooks/search/useSearchStore";

import ButtonUpload from "../../../common/ButtonUpload";
import TooltipInfoIcon from "../../../common/icons/TooltipInfoIcon";
import { CustomTooltip } from "../../../mapSettingsAccordions/CustomTooltip";
import IdentifierUploadProgress from "./IdentifierUploadProgress";

import { IdentifierUploadIcon } from "../../../../assets/CustomIcons";

interface IdentifierUploadFileProps {
  searchRecordType: RecordType;
  setError: React.Dispatch<React.SetStateAction<IdentifierFormError>>;
}

const IdentifierUploadFile = ({
  searchRecordType,
  setError,
}: IdentifierUploadFileProps) => {
  const searchStore = useSearchStore({ searchRecordType });
  const selectedIdentifier = searchStore((state) => state.selectedIdentifier);
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const uploadFileProgress = searchStore((state) => state.uploadFileProgress);
  const updateUploadFileProgress = searchStore(
    (state) => state.updateUploadFileProgress
  );
  const isLoadingIdentifierFileUpload = searchStore(
    (state) => state.isLoadingIdentifierFileUpload
  );
  const identifierSearchPastedValueText = searchStore(
    (state) => state.identifierSearchPastedValueText
  );

  const { handleUpload, handleRemoveUpload } = useIdentifierFileUploadHandler({
    searchRecordType,
    setError,
  });

  const hasUploadedFile = useMemo(
    () =>
      Boolean(identifierSearchUploadedFile) &&
      !Boolean(identifierSearchPastedValueText),
    [identifierSearchUploadedFile, identifierSearchPastedValueText]
  );

  const isUploadDisabled = useMemo(
    () =>
      Boolean(identifierSearchPastedValueText) ||
      Boolean(identifierSearchUploadedFile) ||
      isLoadingIdentifierFileUpload,
    [
      identifierSearchPastedValueText,
      isLoadingIdentifierFileUpload,
      identifierSearchUploadedFile,
    ]
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isLoadingIdentifierFileUpload && uploadFileProgress !== 99) {
      intervalId = setInterval(() => {
        updateUploadFileProgress(uploadFileProgress + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isLoadingIdentifierFileUpload, uploadFileProgress]);

  const allowedFileTypes = useMemo(
    () => IDENTIFIER_TYPE_INFO[selectedIdentifier].allowedFileTypes.toString(),
    [selectedIdentifier]
  );

  return (
    <div className="identifier-upload-container">
      <div className="identifier-upload-btn-group">
        <ButtonUpload
          label="UPLOAD FILES"
          customIcon={<IdentifierUploadIcon />}
          disabled={isUploadDisabled}
          allowedFileExtension={allowedFileTypes}
          handleFileChange={(evt) => {
            const files = evt.target.files;
            if (!files || files.length === 0) return;
            handleUpload(files[0], setError);
          }}
          buttonClickCallback={() => handleRemoveUpload()}
        />
        <CustomTooltip
          title={"Upload .TXT, .CSV file formats"}
          placement="right"
          arrow
        >
          <span className="tooltip-info">
            <TooltipInfoIcon
              pathFill={isUploadDisabled ? "#FFFFFF1A" : "#FFFFFFB2"}
            />
          </span>
        </CustomTooltip>
      </div>
      {hasUploadedFile && (
        <Chip
          classes={{ root: "chip-root", label: "chip-label" }}
          label={identifierSearchUploadedFile?.name}
          onDelete={() => handleRemoveUpload()}
          size="small"
          deleteIcon={
            isLoadingIdentifierFileUpload ? (
              <IdentifierUploadProgress progress={uploadFileProgress} />
            ) : undefined
          }
        />
      )}
    </div>
  );
};

export default IdentifierUploadFile;
