import { useCallback, useEffect } from "react";

import { PickingInfo } from "deck.gl";

import { useDebouncedCallback } from "use-debounce";

import { Feature } from "../../types/map/layers/carto";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useMapHoverStore from "../../store/map/hover/mapHoverStore";
import useMapStore from "../../store/map/mapStore";

import { getWellDataByPropertiesAndDataGrid } from "../../utils/map/gis/common";
import {
  displayHoverCard,
  getOverlappingFeatures,
  isHoveredLayerOpensCard,
  isHoveredLayerWellPathSticks,
  showHoverCard,
} from "../../utils/map/mapHover";

import useDataGridRecordStore from "../grid/useDataGridRecordStore";

const useMapHover = () => {
  const deckGl = useMapStore((state) => state.deckGl);
  // TEMP: temporarily set to well
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const allWellSelectors = dataGridRecordStore(
    (state) => state.allWellSelectors
  );
  const mapHoverEnabled = useMapHoverStore((state) => state.mapHoverEnabled);
  const updateMapHoveredFeatures = useMapHoverStore(
    (state) => state.updateMapHoveredFeatures
  );
  const updateMapHoverData = useMapHoverStore(
    (state) => state.updateMapHoverData
  );

  const updateHoveredFeaturesWithDebounce = useDebouncedCallback(
    (features: PickingInfo[]) => {
      const featuresInfo = features.map((info) => {
        const data = getWellDataByPropertiesAndDataGrid(
          info?.object,
          allWellSelectors,
          info?.layer?.id ?? ""
        );
        return { info, data: data ?? null };
      });
      updateMapHoveredFeatures(featuresInfo);
    },
    900
  );

  const updateHoverDataWithDebounce = useDebouncedCallback(
    (info: PickingInfo<Feature>) => {
      displayHoverCard(info, allWellSelectors, updateMapHoverData);
    },
    900
  );

  const resetHoverData = useCallback(() => {
    updateHoverDataWithDebounce.cancel();
    updateMapHoverData(undefined);
  }, [updateHoverDataWithDebounce, updateMapHoverData]);

  const resetHoveredFeatures = useCallback(() => {
    updateHoveredFeaturesWithDebounce.cancel();
    updateMapHoveredFeatures([]);
  }, [updateHoveredFeaturesWithDebounce, updateMapHoveredFeatures]);

  useEffect(() => {
    if (!deckGl) return;
    deckGl.setProps({
      onHover: (info: PickingInfo<Feature>) => {
        if (
          showHoverCard(info, allWellSelectors) &&
          isHoveredLayerOpensCard(info) &&
          mapHoverEnabled
        ) {
          if (isHoveredLayerWellPathSticks(info)) {
            updateHoverDataWithDebounce(info);
            resetHoveredFeatures();
            return;
          }

          const features = getOverlappingFeatures(deckGl, info);
          if (features.length > 1) {
            updateHoveredFeaturesWithDebounce(features);
            resetHoverData();
          } else if (features.length === 1) {
            updateHoverDataWithDebounce(info);
            resetHoveredFeatures();
          }
        } else {
          if (document.querySelector(".hover-card-container:hover") === null) {
            resetHoveredFeatures();
            resetHoverData();
          }
        }
      },
    });
  }, [
    deckGl,
    allWellSelectors,
    mapHoverEnabled,
    updateHoverDataWithDebounce,
    updateHoveredFeaturesWithDebounce,
    resetHoverData,
    resetHoveredFeatures,
  ]);
};

export default useMapHover;
