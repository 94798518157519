import { FC, useCallback, useEffect, useState } from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import classNames from "classnames";

import { RecordCounts } from "../../../../../../types/common/records";
import { VarcharBooleanProps } from "../../../../../../types/panels/searchPanel/queryBuilder";

import { TEXT_SELECT } from "../../../../../../constants/attributes";
import { initialOperationType } from "../../../../../../constants/panels/searchPanel/queryBuilder/store";

import useAttributeOptionsPostProcess from "../../../../../../customHooks/common/useAttributeOptionsPostProcess";
import useSearchStore from "../../../../../../customHooks/search/useSearchStore";

import Loading from "../../../../../common/Loading";

const QBTextSelectAttribute: FC<VarcharBooleanProps> = ({
  attribute,
  attributeQBInfo,
  searchRecordType,
  isOptionsLoading,
  getOptionsWithWellCounts,
  modifyAttributeBounds,
}) => {
  const searchStore = useSearchStore({ searchRecordType });
  const updateCurrentQBInfo = searchStore((state) => state.updateCurrentQBInfo);
  const [options, setOptions] = useState<RecordCounts[]>([]);

  const { getOptionLabelByAttribute } = useAttributeOptionsPostProcess();

  const onAttributeChange = (newValue: string | null) => {
    const attribValue = newValue;
    const value = attribValue ? [attribValue] : [];
    updateCurrentQBInfo(attribute.key, "values", value);
    modifyAttributeBounds(value, initialOperationType[TEXT_SELECT]);
  };

  useEffect(() => {
    if (isOptionsLoading) {
      setOptions([{ attributeValue: "Loading...", count: -1 }]);
    } else {
      if (attributeQBInfo.options.length) {
        setOptions(attributeQBInfo.options);
      } else {
        setOptions([{ attributeValue: "No options", count: -1 }]);
      }
    }
  }, [attributeQBInfo, isOptionsLoading]);

  const isOptionInvalid = useCallback(
    (option: RecordCounts) => {
      return option.count === -1;
    },
    [options]
  );

  const valueLabel = useCallback(
    (val: string) => {
      return getOptionLabelByAttribute(attribute.key, val);
    },
    [attribute.key, attributeQBInfo.values]
  );

  return (
    <FormControl fullWidth className="select-root">
      <InputLabel className="select-form-label">{attribute.label}</InputLabel>
      <Select
        className="autocomplete-textfield-root"
        variant="outlined"
        label={attribute.label}
        value={
          attributeQBInfo?.values.length
            ? attributeQBInfo?.values[0].toString()
            : null
        }
        renderValue={(selected) => {
          return (
            <div className="select-value-box">
              <div>{valueLabel(selected ?? "") ?? ""}</div>
              {isOptionsLoading && <Loading size={20} />}
            </div>
          );
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: "black",
            },
          },
        }}
        onChange={(e) => onAttributeChange(e.target.value)}
        onOpen={() => getOptionsWithWellCounts()}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={!isOptionInvalid(option) ? option.attributeValue : ""}
            className={classNames("select-list", {
              disabled: isOptionInvalid(option),
            })}
            disabled={isOptionInvalid(option)}
          >
            <span className="option-label">
              {option.label ?? option.attributeValue}
            </span>
            {!isOptionInvalid(option) && (
              <span className="option-count">{`(${option.count})`}</span>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default QBTextSelectAttribute;
