import { StateCreator } from "zustand";

import { SearchCriteria } from "../../../types/common/search";
import { SearchCriteriaSlice } from "../../../types/panels/searchPanel/search";

import { clone } from "../../../utils";

const initialSearchCriteriaState: SearchCriteria = {
  // Note: wells and permits share the same drawnPolygons, polygonType and shapeId.
  drawnPolygons: [],
  polygonType: "",
  shapeId: "",
  currentBounds: [],
  identifier: { type: "", identifiers: [], fileId: "" },
};

// Store for active search criteria, record types and search types
const searchCriteriaSlice: StateCreator<SearchCriteriaSlice> = (set, get) => ({
  searchCriteria: clone(initialSearchCriteriaState),
  // update some object from searchCriteria
  updateSearchCriteria: (searchCriteria) =>
    set((state) => ({
      searchCriteria: {
        ...get().searchCriteria,
        ...searchCriteria,
      },
    })),
  // override searchCriteria
  updateNewSearchCriteria: (searchCriteria) => set(() => ({ searchCriteria })),
  resetSearchCriteria: () =>
    set(() => ({
      searchCriteria: clone(initialSearchCriteriaState),
      isSearchedRecordType: false,
      activeSearchTypeTab: null,
    })),

  isSearchedRecordType: false,
  updateIsSearchedRecordType: (isSearchedRecordType) =>
    set(() => ({ isSearchedRecordType })),

  activeSearchTypeTab: null,
  updateActiveSearchTypeTab: (activeSearchTypeTab) =>
    set(() => ({ activeSearchTypeTab })),
});

export { searchCriteriaSlice };
