import { RecordData } from "../../../types/common/records";
import {
  SelectedKeys,
  SelectionStates,
} from "../../../types/map/selection/common/selectionStore";
import {
  DrawToSelectCallback,
  MapWellSelection,
} from "../../../types/map/selection/wellSelection";
import { RecordType } from "../../../types/panels/searchPanel/search";

import {
  DRAW_TO_SELECT_MAX_ALERT_MESSAGE,
  DRAW_TO_SELECT_MAX_SELECTION,
  DYNAMIC_BOTTOM_PERMIT_SPOTS,
  DYNAMIC_BOTTOM_WELL_SPOTS,
  DYNAMIC_PERMIT_SPOTS,
  DYNAMIC_WELL_SPOTS,
  PERMIT_SPOTS,
  WELL_PATHS,
  WELL_SPOTS,
  WELL_STICKS,
} from "../../../constants/constants";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import { getRecordIdByRecordType } from "../../datagrid/getRecordSpecificConstants";

export const mapWellSelection = async ({
  info,
  addSelectedMapParentWellID,
  addSelectedBottomWellboreID,
  addMapSelectedPermitIDs,
  toggleLayer,
  updateRecordSelectTrigger,
}: MapWellSelection) => {
  try {
    const { ParentWellID, BottomWellboreID, PermitId } = info.object.properties;

    if (
      info.layer?.props.id === WELL_SPOTS ||
      info.layer?.props.id === DYNAMIC_WELL_SPOTS
    ) {
      addSelectedMapParentWellID(ParentWellID);
    } else if (
      info.layer?.props.id === DYNAMIC_BOTTOM_WELL_SPOTS ||
      info.layer?.props.id === WELL_PATHS ||
      info.layer?.props.id === WELL_STICKS
    ) {
      addSelectedBottomWellboreID(BottomWellboreID);

      if (
        info.layer?.props.id === WELL_PATHS ||
        info.layer?.props.id === WELL_STICKS
      ) {
        toggleLayer(WELL_SPOTS, true);
        toggleLayer(DYNAMIC_BOTTOM_WELL_SPOTS, true);
      }
    } else if (
      info.layer?.props.id === PERMIT_SPOTS ||
      info.layer?.props.id === DYNAMIC_PERMIT_SPOTS ||
      info.layer?.props.id === DYNAMIC_BOTTOM_PERMIT_SPOTS
    ) {
      const PermitIDs: SelectedKeys = {};
      PermitIDs["P" + PermitId] = PermitId;
      addMapSelectedPermitIDs(PermitIDs);
    }

    updateRecordSelectTrigger("map");
  } catch (error) {
    console.debug("mapWellSelection error", error);
  }
};

export const drawToSelectDataCallback = async ({
  drawToSelectData,
  addSelectedMapParentWellIDs,
  addMapSelectedPermitIDs,
  updateRecordSelectTrigger,
  updateAlertState,
}: DrawToSelectCallback) => {
  try {
    if (drawToSelectData.length > DRAW_TO_SELECT_MAX_SELECTION) {
      updateAlertState({
        severity: "error",
        message: DRAW_TO_SELECT_MAX_ALERT_MESSAGE,
      });
    } else {
      const PWIDS: SelectedKeys = {};
      const PermitIDs: SelectedKeys = {};
      drawToSelectData.forEach((data) => {
        switch (data.RecordType) {
          case RECORD_TYPES.WELL:
            if (data.WellID) {
              PWIDS[data.ParentWellID] = data.WellID;
            }
            break;
          case RECORD_TYPES.PERMIT:
            if (data.PermitID) {
              PermitIDs["P" + data.PermitID] = data.PermitID;
            }
            break;
        }
      });

      if (PWIDS) addSelectedMapParentWellIDs(PWIDS);
      if (PermitIDs) addMapSelectedPermitIDs(PermitIDs);

      updateRecordSelectTrigger("draw");
    }
  } catch (error) {
    console.debug("mapWellSelection error", error);
  }
};

export const getUpdatedSelectedStates = (
  state: SelectionStates,
  records: RecordData[],
  recordType: RecordType
) => {
  const newStates: SelectionStates = {
    selectedMapParentWellIDs: { ...state.selectedMapParentWellIDs },
    selectedBottomWellboreIDs: { ...state.selectedBottomWellboreIDs },
    selectedIds: { ...state.selectedIds },
    selectedRecordData: { ...state.selectedRecordData },
    allSelectedGridData: { ...state.allSelectedGridData },
    selectedIdsKeys: [...state.selectedIdsKeys],
    selectedRecordUwis: [...state.selectedRecordUwis],
  };

  records.forEach((data) => {
    const recordKey = getRecordIdByRecordType(data, recordType);
    if (recordKey) {
      const mapData = {
        WellID: data.WellID,
        PermitID: data.PermitID,
        ParentWellID: data.ParentWellID,
        BottomWellboreID: data.BottomWellboreID,
        ...(data.UWI && { UWI: data.UWI }), //make UWI optional
        RecordType: data.RecordType,
      };

      newStates.selectedMapParentWellIDs[mapData.ParentWellID] = recordKey;
      newStates.selectedBottomWellboreIDs[mapData.BottomWellboreID] = recordKey;
      newStates.selectedIds[recordKey] = recordKey;
      newStates.selectedRecordData[recordKey] = mapData;
      newStates.allSelectedGridData[recordKey] = recordKey;

      if (!newStates.selectedIdsKeys.includes(recordKey)) {
        newStates.selectedIdsKeys.push(recordKey);
      }

      if (mapData.UWI && !newStates.selectedRecordUwis.includes(mapData.UWI)) {
        newStates.selectedRecordUwis.push(mapData.UWI);
      }
    }
  });

  return newStates;
};
