import { useCallback, useEffect, useMemo, useState } from "react";

import { Button, ButtonGroup } from "component-library";

import { Country } from "../../../../types/common/records";
import { LogsDocsType } from "../../../../types/panels/wellPanel/logsDocsType";
import {
  MapLayer,
  WellSystemData,
} from "../../../../types/panels/wellPanel/wellPanel";

import { COUNTRY } from "../../../../constants/constants";
import { DOCSTAB, LOGSTAB } from "../../../../constants/panels/wellPanel/logs";
import {
  WELL_PANEL_FLAGS,
  WELL_PANEL_TAB,
} from "../../../../constants/panels/wellPanel/wellInfo";

import { PillButtonTypes } from "component-library/components/buttons/pill/typings";
import { ButtonStates } from "component-library/components/buttons/typings";

import CustomTabPanel from "../../../common/CustomTabPanel";
import LasViewerForm from "../../../form/LasViewerForm";
import DocsPanel from "../sections/DocsPanel";
import LogsPanel from "../sections/LogsPanel";

type LogsAndDocsProps = {
  hidden: boolean;
  wellSystem: WellSystemData;
  activePage: number;
  groupedWellID: number;
  layer: MapLayer;
  surfaceUWI: string;
  country: Country;
};

const LogsAndDocsTab: React.FC<LogsAndDocsProps> = ({
  groupedWellID,
  hidden,
  wellSystem,
  activePage,
  layer,
  surfaceUWI,
  country,
}) => {
  const [enabledTab, setEnabledTab] = useState<LogsDocsType | "">("");
  const [disabledLogs, setDisabledLogs] = useState(false);
  const [disabledDocs, setDisabledDocs] = useState(false);

  const openLogViewerR360 = () => {
    const form = document.getElementById("lasWorxFormPost") as HTMLFormElement;
    form.submit();
  };

  const openWellDocsPage = useCallback(() => {
    window.open(`/docs-viewer/${surfaceUWI}`, "_blank");
  }, [surfaceUWI]);

  const isLogsViewerEnabled = useMemo(() => {
    return (
      Boolean(wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_LAS.key]) ||
      Boolean(wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_RASTER.key])
    );
  }, [wellSystem.wellCardData]);

  const isDocsViewerEnabled = useMemo(() => {
    return Boolean(wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_WELL_FILE.key]);
  }, [wellSystem.wellCardData]);

  const genericUWIByCountry = useMemo(
    () =>
      ([wellSystem.wellCardData.uwi] as string[]).map((uwi) =>
        country === COUNTRY.US ? uwi.slice(0, 12) + "00" : uwi
      ),
    [wellSystem.wellCardData.uwi, country]
  );

  const handleToggleClick = (type: LogsDocsType) => {
    setEnabledTab(type);
  };

  const toggleEnabledProp = {
    state: "pressed" as ButtonStates,
  };

  const toggleDisabledProp = {
    className: "custom-disabled",
    type: "tertiary" as PillButtonTypes,
  };

  useEffect(() => {
    if (
      Boolean(wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_LAS.key]) ||
      Boolean(wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_RASTER.key])
    ) {
      setEnabledTab(LOGSTAB);
    } else if (
      Boolean(wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_WELL_FILE.key])
    ) {
      setEnabledTab(DOCSTAB);
    }

    if (!Boolean(wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_WELL_FILE.key])) {
      setDisabledDocs(true);
    }

    if (
      !Boolean(wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_LAS.key]) ||
      !Boolean(wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_RASTER.key])
    ) {
      setDisabledLogs(true);
    }
  }, [
    wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_LAS.key],
    wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_RASTER.key],
    wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_WELL_FILE.key],
  ]);

  return (
    <CustomTabPanel
      hidden={hidden}
      name={WELL_PANEL_TAB.LOGS_AND_DOCS_TAB}
      id={wellSystem.wellId}
    >
      <ButtonGroup className="logs-docs-group">
        <Button
          text="Logs"
          onClick={() => handleToggleClick(LOGSTAB)}
          {...(enabledTab === LOGSTAB ? toggleEnabledProp : toggleDisabledProp)}
          disabled={disabledLogs}
        />
        <Button
          text="Docs"
          onClick={() => handleToggleClick(DOCSTAB)}
          {...(enabledTab === DOCSTAB ? toggleEnabledProp : toggleDisabledProp)}
          disabled={disabledDocs}
        />
      </ButtonGroup>

      {enabledTab === LOGSTAB && (
        <LogsPanel
          groupedWellID={groupedWellID}
          layer={layer}
          wellSystem={wellSystem}
          activePage={activePage}
          country={country}
        />
      )}

      {enabledTab === DOCSTAB && (
        <DocsPanel
          groupedWellID={groupedWellID}
          layer={layer}
          wellSystem={wellSystem}
          activePage={activePage}
          country={country}
          surfaceUWI={surfaceUWI}
        />
      )}

      <div className="logs-footer-container">
        <Button
          text="Log Viewer"
          type="secondary"
          onClick={openLogViewerR360}
          disabled={!isLogsViewerEnabled}
        />

        <Button
          text="Doc Viewer"
          type="secondary"
          onClick={openWellDocsPage}
          className="docs-viewer-button"
          disabled={!isDocsViewerEnabled}
        />
      </div>
      <LasViewerForm uwis={genericUWIByCountry} />
    </CustomTabPanel>
  );
};

export default LogsAndDocsTab;
