import { useMemo } from "react";

import useDataGridStore from "../../store/grid/dataGridStore";
import usePanelsStore from "../../store/panels/panelsStore";

const useZoomToFitPadding = () => {
  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );
  const isOpenAnalysisPanel = usePanelsStore(
    (state) => state.isOpenAnalysisPanel
  );

  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const showGrid = useDataGridStore((state) => state.showGrid);
  const gridHeight = useDataGridStore((state) => state.gridHeight);

  const zoomToFitLeftPadding = useMemo(() => {
    let value = 0;
    if (isOpenAppLevelNav && isOpenLeftSidePanel) {
      value = 464;
    } else if (isOpenAppLevelNav) {
      value = 64;
    }
    return value;
  }, [isOpenAppLevelNav, isOpenLeftSidePanel]);

  const zoomToFitRightPadding = useMemo(() => {
    let value = 0;
    if (isOpenAnalysisPanel) {
      value = 464;
    }
    return value;
  }, [isOpenAnalysisPanel]);

  const zoomToFitBottomPadding = useMemo(() => {
    let bottom = Number(gridHeight.replace("px", ""));

    if (!showGrid && !showGridHeader) {
      bottom = 0;
    } else if (!showGrid && showGridHeader) {
      bottom = 82;
    }

    return bottom;
  }, [gridHeight, showGrid, showGridHeader]);

  return {
    zoomToFitLeftPadding,
    zoomToFitRightPadding,
    zoomToFitBottomPadding,
  };
};

export default useZoomToFitPadding;
