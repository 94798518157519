import { useCallback } from "react";

import { ChartType } from "../../types/charts/chartType/chartType";
import { DashboardChartType } from "../../types/charts/dashboardChartType/dashboardChartType";
import {
  BaseSearchRequestNoPaginationPayload,
  ChartsSearchRequestPayload,
  SearchRequestWithFileID,
  SearchRequestWithUWIs,
} from "../../types/common/api";
import { Bounds } from "../../types/common/attributes";
import { BaseSearchCriteria, SearchCriteria } from "../../types/common/search";
import { RecordType } from "../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import { getGeometryFromPolygonArray } from "../../data/map/mapUtils";
import { clone } from "../../utils";
import useSearchStore from "../search/useSearchStore";

const useSearchRequest = (searchRecordType: RecordType = RECORD_TYPES.WELL) => {
  const searchStore = useSearchStore({ searchRecordType });
  const searchCriteria = searchStore((state) => state.searchCriteria);

  // Note: This function is used by pre-search and post-search APIs
  const buildSearchRequestByParam = ({
    pSearchCriteria,
    viewport,
  }: {
    pSearchCriteria: BaseSearchCriteria;
    viewport?: any;
  }) => {
    let geometry = null;
    let shapesCriteria = null;
    if (pSearchCriteria.shapeId || pSearchCriteria.drawnPolygons.length) {
      //shape id & drawnPolygon: for polygon search or shape file upload
      if (viewport) {
        geometry = getGeometryFromPolygonArray(
          pSearchCriteria.drawnPolygons,
          viewport
        );
      } else {
        geometry = getGeometryFromPolygonArray(pSearchCriteria.drawnPolygons);
      }

      shapesCriteria = {
        ...(geometry && { geometry: geometry }),
        ...(pSearchCriteria.shapeId && {
          shapeId: pSearchCriteria.shapeId,
        }),
      };
    }

    if (pSearchCriteria.currentBounds.length) {
      const boundsSearchRequest: BaseSearchRequestNoPaginationPayload = {
        bounds: pSearchCriteria.currentBounds,
        ...(shapesCriteria && { ...shapesCriteria }),
        ...(pSearchCriteria.filters?.length && {
          filters: pSearchCriteria.filters,
        }),
      };
      return boundsSearchRequest;
    } else if (pSearchCriteria.identifier.identifiers.length) {
      //TODO: Change and rename Once different Identifier types are introduced
      const identifierSearchRequest: SearchRequestWithUWIs<BaseSearchRequestNoPaginationPayload> =
        {
          uwis: pSearchCriteria.identifier.identifiers,
          ...(shapesCriteria && { ...shapesCriteria }),
          ...(pSearchCriteria.filters?.length && {
            filters: pSearchCriteria.filters,
          }),
        };
      return identifierSearchRequest;
    } else if (pSearchCriteria.identifier.fileId) {
      const identifierFileUploadSearch: SearchRequestWithFileID<BaseSearchRequestNoPaginationPayload> =
        {
          fileId: pSearchCriteria.identifier.fileId,
          ...(shapesCriteria && { ...shapesCriteria }),
          ...(pSearchCriteria.filters?.length && {
            filters: pSearchCriteria.filters,
          }),
        };
      return identifierFileUploadSearch;
    } else if (shapesCriteria) {
      const shapesSearchRequest: BaseSearchRequestNoPaginationPayload = {
        ...shapesCriteria,
        ...(pSearchCriteria.filters?.length && {
          filters: pSearchCriteria.filters,
        }),
      };
      return shapesSearchRequest;
    }
  };

  const buildSearchRequest = useCallback(
    (chartType: DashboardChartType | ChartType) => {
      if (searchCriteria) {
        let copiedSearchCriteria: SearchCriteria = clone(searchCriteria);

        if (searchCriteria.currentBounds.length) {
          const currentBoundsCopy: Bounds = clone(searchCriteria.currentBounds);

          // TODO: Check if this can be removed.
          // Saving for reference
          // if (!PERMIT_CHARTS.includes(chartType)) {
          //   currentBoundsCopy = currentBoundsCopy?.map((bound) => {
          //     if (bound.bound === RECORD_TYPE.key) {
          //       bound = createRecordTypeBounds(RECORD_TYPES.WELL);
          //     }
          //     return bound;
          //   });
          // }

          copiedSearchCriteria = {
            ...copiedSearchCriteria,
            currentBounds: currentBoundsCopy,
          };

          const request = {
            ...buildSearchRequestByParam({
              pSearchCriteria: copiedSearchCriteria,
            }),
          } as ChartsSearchRequestPayload<BaseSearchRequestNoPaginationPayload>;

          return request;
        }
      }
    },
    [searchCriteria]
  );

  return {
    buildSearchRequest,
    buildSearchRequestByParam,
  };
};

export default useSearchRequest;
