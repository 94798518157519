import { useEffect } from "react";

import { Tab, Tabs } from "@mui/material";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useDataGridStore from "../../store/grid/dataGridStore";
import useDataGridPermitsStore from "../../store/grid/permits/dataGridPermitsStore";
import useDataGridWellsStore from "../../store/grid/wells/dataGridWellsStore";
import useSearchPermitsStore from "../../store/search/permits/searchPermitsStore";
import useSearchWellsStore from "../../store/search/wells/searchWellsStore";

import { getNumberWithComma } from "../../utils/formatters/numberFormatter";

import { PermitsIcon, WellsIcon } from "../common/icons/grid/RecordTypeIcons";
import DataGridTabPanel from "./DataGridTabPanel";

const TabbedDataGrids = () => {
  const searchWellsStore = useSearchWellsStore();
  const searchPermitsStore = useSearchPermitsStore();
  const dataGridWellsStore = useDataGridWellsStore();
  const dataGridPermitsStore = useDataGridPermitsStore();

  const activeRecordTypeTab = useDataGridStore(
    (state) => state.activeRecordTypeTab
  );
  const updateActiveRecordTypeTab = useDataGridStore(
    (state) => state.updateActiveRecordTypeTab
  );

  useEffect(() => {
    if (searchWellsStore.isSearchedRecordType) {
      updateActiveRecordTypeTab(RECORD_TYPES.WELL);
    } else if (searchPermitsStore.isSearchedRecordType) {
      updateActiveRecordTypeTab(RECORD_TYPES.PERMIT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchWellsStore.isSearchedRecordType,
    searchPermitsStore.isSearchedRecordType,
  ]);

  return (
    <div className="tabbed-grid">
      <Tabs
        value={activeRecordTypeTab}
        onChange={(_, newValue) => updateActiveRecordTypeTab(newValue)}
      >
        {searchWellsStore.isSearchedRecordType && (
          <Tab
            label={
              <div className="custom-tab-label">
                {<WellsIcon />}
                <span className="tab-label">WELLS</span>
                <span className="tab-count">
                  {`(${
                    dataGridWellsStore.allWellGridDataLoading
                      ? "..."
                      : getNumberWithComma(dataGridWellsStore.gridFilteredCount)
                  })`}
                </span>
              </div>
            }
            value={RECORD_TYPES.WELL}
          />
        )}
        {searchPermitsStore.isSearchedRecordType && (
          <Tab
            label={
              <div className="custom-tab-label">
                {<PermitsIcon />}
                <span className="tab-label">PERMITS</span>
                <span className="tab-count">
                  {`(${
                    dataGridPermitsStore.allWellGridDataLoading
                      ? "..."
                      : getNumberWithComma(
                          dataGridPermitsStore.gridFilteredCount
                        )
                  })`}
                </span>
              </div>
            }
            value={RECORD_TYPES.PERMIT}
          />
        )}
      </Tabs>
      {searchWellsStore.isSearchedRecordType && (
        <DataGridTabPanel
          searchRecordType={RECORD_TYPES.WELL}
          activeSearchRecordType={activeRecordTypeTab}
        />
      )}
      {searchPermitsStore.isSearchedRecordType && (
        <DataGridTabPanel
          searchRecordType={RECORD_TYPES.PERMIT}
          activeSearchRecordType={activeRecordTypeTab}
        />
      )}
    </div>
  );
};

export default TabbedDataGrids;
