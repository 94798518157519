import { useMemo } from "react";

import classNames from "classnames";
import { Button } from "component-library";

import { LEGEND, MAP_SETTINGS } from "../../../constants/map/mapSettings";

import useDataGridStore from "../../../store/grid/dataGridStore";
import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";
import usePanelsStore from "../../../store/panels/panelsStore";

import "../../../assets/scss/mapControls.scss";

const MapControls = () => {
  const mapOverlayOpened = useMapSettingsStore(
    (state) => state.mapOverlayOpened
  );
  const removeMapOverlayOpened = useMapSettingsStore(
    (state) => state.removeMapOverlayOpened
  );
  const appendMapOverlayOpened = useMapSettingsStore(
    (state) => state.appendMapOverlayOpened
  );

  const isOpenAnalysisPanel = usePanelsStore(
    (state) => state.isOpenAnalysisPanel
  );

  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const showGrid = useDataGridStore((state) => state.showGrid);
  const gridHeight = useDataGridStore((state) => state.gridHeight);

  const getBottomPosition = useMemo(() => {
    let bottom = `calc(${gridHeight} + 30px)`;

    if (!showGrid && !showGridHeader) {
      bottom = "30px";
    } else if (!showGrid && showGridHeader) {
      bottom = "127px";
    }

    return bottom;
  }, [gridHeight, showGrid, showGridHeader]);

  const changeMapOverlay = (key: string) => {
    if (mapOverlayOpened.includes(key)) {
      removeMapOverlayOpened(key);
    } else {
      appendMapOverlayOpened(key);
    }
  };

  return (
    <div
      className={classNames("wrapper map-settings-legends-container", {
        open: isOpenAnalysisPanel,
      })}
      style={{ bottom: getBottomPosition }}
    >
      <Button
        iconLeft={"layers"}
        text={MAP_SETTINGS.label}
        onClick={() => changeMapOverlay(MAP_SETTINGS.key)}
        state="pressed"
        className={classNames("mapOverlaysActionButton", {
          activeControl: mapOverlayOpened.includes(MAP_SETTINGS.key),
        })}
      />
      <Button
        iconLeft={"palette"}
        text={LEGEND.label}
        onClick={() => changeMapOverlay(LEGEND.key)}
        state="pressed"
        className={classNames("mapOverlaysActionButton", {
          activeControl: mapOverlayOpened.includes(LEGEND.key),
        })}
      />
    </div>
  );
};

export default MapControls;
