import { Country } from "../../types/common/records";
import { Feature } from "../../types/map/layers/carto";

import {
  COUNTRY,
  DYNAMIC_BOTTOM_PERMIT_SPOTS,
  DYNAMIC_BOTTOM_WELL_SPOTS,
  DYNAMIC_PERMIT_SPOTS,
  DYNAMIC_WELL_SPOTS,
  WELL_PATHS,
  WELL_STICKS,
} from "../../constants/constants";

export const getCountryByUWI = (uwi: string) => {
  return COUNTRY.US;
  // Uncomment to apply CA Logic
  // return uwi.length === 16 ? COUNTRY.CA : COUNTRY.US;
};

export const formatUWI12ByCountry = (uwi: string, country?: Country) => {
  return country === COUNTRY.CA ? uwi : uwi.slice(0, 12);
};

export const formatUWI10ByCountry = (uwi: string, country?: Country) => {
  return country === COUNTRY.CA ? uwi : uwi.slice(0, 10);
};

export const getWellIDOnFeature = (f: Feature, layerName: string) => {
  return getWellIDByLayerName(layerName, f.properties);
};

export const getWellIDByLayerName = (
  layerName: string,
  data: Feature["properties"]
) => {
  switch (layerName) {
    case DYNAMIC_WELL_SPOTS:
      return data.ParentWellID;
    case DYNAMIC_BOTTOM_WELL_SPOTS:
    case WELL_PATHS:
    case WELL_STICKS:
      return data.BottomWellboreID;
    case DYNAMIC_PERMIT_SPOTS:
    case DYNAMIC_BOTTOM_PERMIT_SPOTS:
      return data.PermitId;
    default:
      return data.ParentWellID;
  }
};

export const checkWellIDByLayerName = (
  layerName: string,
  wellID: number,
  data: { ParentWellID?: number; BottomWellboreID?: number }
) => {
  return getWellIDByLayerName(layerName, data) === wellID;
};

export const convertUWI = (uwi: string, digitsToRemove: number) => {
  return uwi.slice(0, digitsToRemove);
};

export const convertWellIdToHeaderKey = (id: number) => `W${id}`;
export const convertPermitIdToHeaderKey = (id: number) => `P${id}`;
