export const WellsIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6.00013"
        cy="6"
        r="5.34142"
        transform="rotate(11.4384 6.00013 6)"
        fill="#23569C"
        stroke="#D9D9D9"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const PermitsIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7.00013"
        cy="7"
        r="5.59142"
        transform="rotate(11.4384 7.00013 7)"
        stroke="#00B2A9"
      />
    </svg>
  );
};
