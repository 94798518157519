import { StateCreator } from "zustand";

import { DataGridColumnStore } from "../../../types/grid";

import {
  defaultPermitColumnsOrder,
  getColumnKeyNames,
} from "../../../utils/datagrid";
import { permitsColumnVisibilityList } from "../../../utils/datagrid/permits/permitsColumnVisibilityList";

import { clone } from "../../../utils";

const permitsDataGridColumnSlice: StateCreator<
  Partial<DataGridColumnStore>
> = () => ({
  columnsOrder: clone(defaultPermitColumnsOrder),
  columnVisibilityModel: clone(permitsColumnVisibilityList),
  columnsFetched: clone(getColumnKeyNames(permitsColumnVisibilityList)),
  columnsPayload: clone(getColumnKeyNames(permitsColumnVisibilityList)),
});

export { permitsDataGridColumnSlice };
