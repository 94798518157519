import { useMemo } from "react";

import { RecordType } from "../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useSearchStore from "./useSearchStore";

const useSearchCriteria = (
  searchRecordType: RecordType = RECORD_TYPES.WELL
) => {
  const searchStore = useSearchStore({ searchRecordType });
  const searchCriteria = searchStore((state) => state.searchCriteria);

  const hasSearchCriteria = useMemo(
    () =>
      searchCriteria.currentBounds.length > 0 ||
      searchCriteria.drawnPolygons.length > 0 ||
      searchCriteria.identifier.identifiers.length > 0 ||
      !!searchCriteria.identifier.fileId ||
      !!searchCriteria.shapeId,
    [searchCriteria]
  );

  return { hasSearchCriteria };
};

export default useSearchCriteria;
