import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button, ButtonGroup, Tooltip, Typography } from "@mui/material";

import classNames from "classnames";
import { CircularButton } from "component-library";

import config from "../../../../configs/appSettings";

import { RECORD_TYPES } from "../../../../constants/panels/searchPanel/search";

import useDataGridStore from "../../../../store/grid/dataGridStore";
import useMapStore from "../../../../store/map/mapStore";
import usePanelsStore from "../../../../store/panels/panelsStore";
import useStore from "../../../../store/useStore";

import useDataGridRecordStore from "../../../../customHooks/grid/useDataGridRecordStore";
import useSagaMap from "../../../../customHooks/map/useSagaMap";

import ZoomLevelsPopper from "./ZoomLevelsMenu";

const ZoomLevel: FC = () => {
  const { incrementZoomLevel, decrementZoomLevel, setZoomLevel, map } =
    useSagaMap();
  const menuRef = useRef<HTMLButtonElement>(null);

  const currentZoom = useMapStore((state) => state.currentZoom);
  const setCurrentZoom = useMapStore((state) => state.setCurrentZoom);
  const setCurrentMapCenter = useMapStore((state) => state.setCurrentMapCenter);

  const verticalPageSize = useMapStore((state) => state.verticalPageSize);

  const updateToastMessage = useStore((state) => state.updateToastMessage);

  // TEMP: temporarily set to well
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const allGridData = dataGridRecordStore((state) => state.allGridData);

  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const showGrid = useDataGridStore((state) => state.showGrid);
  const gridHeight = useDataGridStore((state) => state.gridHeight);

  const isOpenAnalysisPanel = usePanelsStore(
    (state) => state.isOpenAnalysisPanel
  );

  const [zoomState, setzoomState] = useState(false);
  const [zoomItemText, setzoomItemText] = useState(
    config.defaultViewSettings.zoom
  );

  const defaultZoomref = useRef(null);

  /********* Chroma-js*********/

  /***********Update Wind Image based on slider value End**********/

  /***********checkbox on and off fnaddwindImageChange start **********/

  /***********Map Custom Zoom functionality Start **********/
  const zoomPanelToggle = () => {
    setzoomState(!zoomState);
  };

  const defaultZoomView = () => {
    setzoomItemText(6);
    setzoomState(false);
    setZoomLevel(6);
  };

  const zoomSingleItem = (zoomLevel: number) => {
    setzoomItemText(zoomLevel);
    setzoomState(!zoomState);
    setZoomLevel(zoomLevel);
  };

  const handleChangeResolution = useCallback((evt: any) => {
    const mapZoom = parseInt(evt.target.getZoom());
    setzoomItemText(mapZoom);
    setzoomState(false);
    setCurrentZoom(mapZoom);
  }, []);

  const handleMoveEnd = useCallback((evt: any) => {
    setCurrentMapCenter(evt.map.getView().getCenter());
  }, []);

  const handleMapLeftClick = useCallback(() => {
    if (allGridData?.length) return;
    updateToastMessage("Please execute a search to select wells");
  }, [allGridData]);

  const clearAllListenersByKey = (map: any, key: any) => {
    try {
      map?.listeners_[key]?.forEach((listener: any) => {
        map.un(key, listener);
      });
    } catch (error) {
      console.error("clearAllListenersByKey", error);
    }
  };

  useEffect(() => {
    if (map) {
      map.getView().on("change:resolution", handleChangeResolution);
      map.on("moveend", handleMoveEnd);
    }
  }, [map, verticalPageSize]);

  useEffect(() => {
    if (map) {
      clearAllListenersByKey(map, "singleclick");
      map.on("singleclick", handleMapLeftClick);
    }
  }, [map, allGridData, currentZoom]);

  const zoomIn = () => {
    incrementZoomLevel();
    setzoomItemText((prevState) => prevState + 1);
  };

  const zoomOut = () => {
    if (zoomItemText > 3) {
      decrementZoomLevel();
      setzoomItemText((prevState) => prevState - 1);
    }
  };

  const getBottomPosition = useMemo(() => {
    let bottom = `calc(${gridHeight} + 6px)`;

    if (!showGrid && !showGridHeader) {
      bottom = "6px";
    } else if (!showGrid && showGridHeader) {
      bottom = "100px";
    }

    return bottom;
  }, [gridHeight, showGrid, showGridHeader]);

  return (
    <div className="action-control-wrapper">
      <div
        className={classNames("map-tools-wrapper map-tools-container", {
          open: isOpenAnalysisPanel,
        })}
        style={{ bottom: getBottomPosition }}
      >
        <div className="map-action">
          <ul className="map-center-action default-view-button">
            <Tooltip title="Default View" placement="top" arrow>
              <CircularButton
                icon={"zoomOutMap"}
                onClick={() => defaultZoomView()}
                state="pressed"
              />
            </Tooltip>
          </ul>
          <ul className="map-left-action zoom-button-container">
            <ButtonGroup
              variant="contained"
              size="small"
              aria-label="zoom-action-buttons"
              className="zoom-map-action-buttons"
            >
              <Tooltip title="Zoom Out" placement="top" arrow>
                <Button
                  className="zoom-button zoom-left-button"
                  onClick={zoomOut}
                >
                  <RemoveIcon className="zoom-out-icon" />
                </Button>
              </Tooltip>
              <Tooltip
                title="Click Here For More Zoom Levels"
                placement="top"
                arrow
              >
                <Button
                  className={`zoom-button zoom-center-button ${
                    zoomState ? "zoom-center-menu-open" : ""
                  }`}
                  onClick={zoomPanelToggle}
                  ref={menuRef}
                >
                  <Typography>{zoomItemText}</Typography>
                </Button>
              </Tooltip>

              <Tooltip title="Zoom In" placement="top" arrow>
                <Button
                  className="zoom-button zoom-right-button"
                  onClick={zoomIn}
                >
                  <AddIcon className="zoom-in-icon" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </ul>

          <ZoomLevelsPopper
            zoomNum={zoomItemText}
            zoomSingleItem={zoomSingleItem}
            anchorRef={menuRef}
            open={zoomState}
            handleClose={() => setzoomState(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default ZoomLevel;
