import { Coordinate } from "ol/coordinate";
import { LineString } from "ol/geom";

import { Deck, MapView, PickingInfo } from "deck.gl";

import { PopoverVirtualElement } from "@mui/material";

import { isEmpty } from "lodash";

import { RecordData } from "../../types/common/records";
import { DataGridSelector } from "../../types/grid";
import { Feature } from "../../types/map/layers/carto";
import { SetHoverCardDataParams } from "../../types/map/wellSpot/mapHover";
import { UpdateMapHoverData } from "../../types/map/wellSpot/store";

import {
  BUBBLE_MAP,
  DYNAMIC_BOTTOM_PERMIT_SPOTS,
  DYNAMIC_BOTTOM_WELL_SPOTS,
  DYNAMIC_PERMIT_SPOTS,
  DYNAMIC_WELL_SPOTS,
  GEOMETRY_LINESTRING_TYPE,
  GEOMETRY_MULTILINESTRINGS_TYPE,
  WELL_PATHS,
  WELL_PATH_AND_STICKS,
  WELL_STICKS,
} from "../../constants/constants";
import {
  WELL_PANEL_DISTINCTION_MENU_ACTION,
  WELL_PANEL_HOVER_ACTION,
} from "../../constants/panels/wellPanel/wellPanel";

import { getWellDataByPropertiesAndDataGrid } from "./gis/common";

export const showHoverCard = (
  info: PickingInfo<Feature>,
  dataGridSelector: DataGridSelector
) => {
  const layerName = info.layer?.id;
  const { PermitId, ParentWellID, BottomWellboreID } =
    info?.object?.properties ?? {};

  if (
    layerName === DYNAMIC_PERMIT_SPOTS ||
    layerName === DYNAMIC_BOTTOM_PERMIT_SPOTS
  ) {
    return PermitId && !isEmpty(dataGridSelector.byPermitID);
  }

  return (
    (ParentWellID || BottomWellboreID) &&
    !isEmpty(dataGridSelector.groupedByBottomWellboreID) &&
    !isEmpty(dataGridSelector.groupedByParentWellID)
  );
};

// for well/permit that doesn't overlap with each other
// and well paths/sticks
export const displayHoverCard = (
  info: PickingInfo,
  dataGridSelector: DataGridSelector,
  updateMapHoverData?: UpdateMapHoverData
) => {
  const data = getWellDataByPropertiesAndDataGrid(
    info?.object,
    dataGridSelector,
    info?.layer?.id ?? ""
  );

  if (
    data &&
    info.object.geometry.coordinates &&
    info.layer &&
    updateMapHoverData
  ) {
    if (isHoveredLayerWellSpot(info) || isHoveredLayerPermit(info)) {
      setHoverCardData({
        info,
        data,
        updateMapHoverData,
        triggerType: WELL_PANEL_HOVER_ACTION,
        coordinateTargetPoint: info.object.geometry.coordinates,
      });
    } else if (isHoveredLayerWellPathSticks(info)) {
      const middleIndex = getWellPathPixelProjection(info);

      if (middleIndex) {
        setHoverCardData({
          info,
          data,
          updateMapHoverData,
          triggerType: WELL_PANEL_HOVER_ACTION,
          coordinateTargetPoint: middleIndex,
        });
      }
    }
  } else {
    if (updateMapHoverData) updateMapHoverData(undefined);
  }
};

export const displayHoverCardViaMenu = (
  info: PickingInfo<Feature>,
  data: RecordData | null,
  updateMapHoverData: UpdateMapHoverData,
  anchorEl: PopoverVirtualElement,
  idx: number,
  offset: number
) => {
  if (!data) return;
  const { x, y } = anchorEl.getBoundingClientRect();
  setHoverCardData({
    info,
    data,
    updateMapHoverData,
    triggerType: WELL_PANEL_DISTINCTION_MENU_ACTION,
    coordinateTargetPoint: [x + offset, y + 42 * idx],
    offset,
  });
};

export const isHoveredLayerWellSpot = (info: PickingInfo) => {
  return (
    info.layer?.id !== BUBBLE_MAP &&
    info.layer?.id !== WELL_PATHS &&
    info.layer?.id !== WELL_STICKS &&
    info.layer?.id !== WELL_PATH_AND_STICKS &&
    info.layer?.id !== DYNAMIC_PERMIT_SPOTS &&
    info.layer?.id !== DYNAMIC_BOTTOM_PERMIT_SPOTS
  );
};

export const isHoveredLayerWellPathSticks = (info: PickingInfo) => {
  return Boolean(
    info.layer?.id === WELL_PATHS || info.layer?.id === WELL_STICKS
  );
};

export const isHoveredLayerPermit = (info: PickingInfo) => {
  return (
    info.layer?.id === DYNAMIC_PERMIT_SPOTS ||
    info.layer?.id === DYNAMIC_BOTTOM_PERMIT_SPOTS
  );
};

export const isHoveredLayerOpensCard = (info: PickingInfo) => {
  return Boolean(info.layer?.id !== BUBBLE_MAP);
};

const getWellPathPixelProjection = (info: PickingInfo) => {
  const coordinates = info.object.geometry.coordinates;
  let middleIndex: Coordinate = [];

  if (info.object.geometry.type === GEOMETRY_LINESTRING_TYPE) {
    middleIndex = new LineString(coordinates).getCoordinateAt(0.5);
  } else if (info.object.geometry.type === GEOMETRY_MULTILINESTRINGS_TYPE) {
    middleIndex = new LineString(coordinates.flat()).getCoordinateAt(0.5);
  }

  return middleIndex;
};

const setHoverCardData = ({
  info,
  data,
  updateMapHoverData,
  triggerType,
  coordinateTargetPoint,
  offset = 0,
}: SetHoverCardDataParams) => {
  if (!info.layer) return;

  if (triggerType === WELL_PANEL_DISTINCTION_MENU_ACTION) {
    updateMapHoverData({
      info: data,
      layer: info.layer,
      anchor: { x: coordinateTargetPoint[0], y: coordinateTargetPoint[1] },
      trigger: WELL_PANEL_DISTINCTION_MENU_ACTION,
      offset,
    });
  }

  if (coordinateTargetPoint) {
    const pointProjection = info.viewport?.project(coordinateTargetPoint);
    if (!pointProjection) return;
    updateMapHoverData({
      info: data,
      layer: info.layer,
      anchor: { x: pointProjection[0], y: pointProjection[1] },
      trigger: WELL_PANEL_HOVER_ACTION,
      offset: 0,
    });
  }
};

export const getOverlappingFeatures = (
  deckGl: Deck<MapView>,
  info: PickingInfo
) => {
  return deckGl.pickObjects({
    x: info.x - 2,
    y: info.y - 2,
    layerIds: [
      DYNAMIC_WELL_SPOTS,
      DYNAMIC_BOTTOM_WELL_SPOTS,
      DYNAMIC_PERMIT_SPOTS,
      DYNAMIC_BOTTOM_PERMIT_SPOTS,
    ],
    height: 4,
    width: 4,
  });
};
