import { Button } from "component-library";

import { GridSelectedForAnalysisProps } from "../../../../types/grid";

import useDataGridRecordStore from "../../../../customHooks/grid/useDataGridRecordStore";
import useRecordSelectionStore from "../../../../customHooks/grid/useRecordSelectionStore";

import { getNumberWithComma } from "../../../../utils/formatters/numberFormatter";

const SelectedForAnalysis = ({
  searchRecordType,
}: GridSelectedForAnalysisProps) => {
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType,
  });
  const sortByAnalysis = dataGridRecordStore((state) => state.sortByAnalysis);
  const updateSortByAnalysis = dataGridRecordStore(
    (state) => state.updateSortByAnalysis
  );

  const recordSelectionStore = useRecordSelectionStore({
    searchRecordType,
  });
  const selectedIdsKeys = recordSelectionStore(
    (state) => state.selectedIdsKeys
  );

  return (
    <>
      {!!selectedIdsKeys.length && (
        <Button
          type={"tertiary"}
          text={`Selected for Analysis ${getNumberWithComma(
            selectedIdsKeys.length
          )}`}
          onClick={() => updateSortByAnalysis(!sortByAnalysis)}
          state={sortByAnalysis ? "pressed" : "enabled"}
        />
      )}
    </>
  );
};

export default SelectedForAnalysis;
