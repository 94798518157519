import { create } from "zustand";

import { DataGridRecordState } from "../../../types/grid";

import { bulkResetStateSlice } from "../common/bulkResetStateSlice";
import { dataGridColumnSlice } from "../common/dataGridColumnSlice";
import {
  dataGridAllWellDataSlice,
  dataGridBatchWellDataSlice,
  dataGridConcatBatchWellDataSlice,
} from "../common/dataGridDataSlice";
import { dataGridFilterSlice } from "../common/dataGridFilterSlice";
import {
  dataGridCountSlice,
  dataGridExportStore,
  dataGridRefStore,
  dataGridStateStore,
  dataGridTriggerStore,
} from "../common/dataGridSlice";
import { dataGridSortSlice } from "../common/dataGridSortSlice";
import { recordSelectSlice } from "../common/recordSelectSlice";

const useDataGridWellsStore = create<DataGridRecordState>()((...a) => ({
  ...dataGridCountSlice(...a),
  ...dataGridColumnSlice(...a),
  ...dataGridAllWellDataSlice(...a),
  ...dataGridBatchWellDataSlice(...a),
  ...dataGridConcatBatchWellDataSlice(...a),
  ...dataGridFilterSlice(...a),
  ...dataGridRefStore(...a),
  ...dataGridExportStore(...a),
  ...dataGridStateStore(...a),
  ...dataGridTriggerStore(...a),
  ...dataGridSortSlice(...a),
  ...bulkResetStateSlice(...a),
  ...recordSelectSlice(...a),
}));

export default useDataGridWellsStore;
