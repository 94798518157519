import { PropsWithChildren } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Switch,
} from "@mui/material";

import classNames from "classnames";

import { RecordType } from "../../../../types/panels/searchPanel/search";

import useSearchPanelStore from "../../../../store/search/panel/useSearchPanelStore";

import { getNumberWithComma } from "../../../../utils/formatters/numberFormatter";

interface SearchPanelAccordionProps {
  title: string;
  searchRecordType: RecordType;
  searchCount: number;
  isSearchCountLoading: boolean;
  isSearchToggledOn?: boolean;
  toggleIsSearchToggledOn: (status?: boolean) => void;
}

const SearchPanelAccordion = ({
  title,
  searchRecordType,
  searchCount,
  isSearchCountLoading,
  isSearchToggledOn,
  toggleIsSearchToggledOn,
  children,
}: PropsWithChildren<SearchPanelAccordionProps>) => {
  const openedSearchAccordion = useSearchPanelStore(
    (state) => state.openedSearchAccordion
  );
  const updateOpenedSearchAccordion = useSearchPanelStore(
    (state) => state.updateOpenedSearchAccordion
  );

  return (
    <Accordion
      disableGutters
      expanded={openedSearchAccordion === searchRecordType}
      onChange={() => {
        if (!isSearchToggledOn) return;
        updateOpenedSearchAccordion(
          openedSearchAccordion !== searchRecordType ? searchRecordType : ""
        );
      }}
      className={classNames("search-panel-accordion", {
        disabled: !isSearchToggledOn,
      })}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <span
          className={classNames("accordion-title-text", {
            disabled: !isSearchToggledOn,
          })}
        >
          {title}
          <span className="accordion-title-sub-text">{`(${
            isSearchCountLoading ? "..." : getNumberWithComma(searchCount)
          })`}</span>
        </span>
        {/* Can't use component library Toggle since e.stopPropagation is only working for onClick events */}
        <Switch
          size="small"
          checked={isSearchToggledOn}
          onClick={(e) => {
            e.stopPropagation();
            if (
              isSearchToggledOn &&
              openedSearchAccordion === searchRecordType
            ) {
              updateOpenedSearchAccordion("");
            } else if (!isSearchToggledOn) {
              updateOpenedSearchAccordion(searchRecordType);
            }
            toggleIsSearchToggledOn();
          }}
        />
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default SearchPanelAccordion;
