import { useEffect, useState } from "react";

import { MAX_EXPORT } from "../../constants/constants";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useExportCardStore from "../../store/exportCard/exportCardStore";

import useDataGridRecordStore from "../grid/useDataGridRecordStore";
import useRecordSelectionStore from "../grid/useRecordSelectionStore";
import useSearchStore from "../search/useSearchStore";

const useR360GridExport = () => {
  const [isR360Disabled, setIsR360Disabled] = useState(true);

  // TEMP: temporarily set to well
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const allWellGridDataLoading = dataGridRecordStore(
    (state) => state.allWellGridDataLoading
  );
  const gridTotalCount = dataGridRecordStore((state) => state.gridTotalCount);

  // TEMP: temporarily set to well
  const searchStore = useSearchStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const isIdentifierFormInvalid = searchStore(
    (state) => state.isIdentifierFormInvalid
  );
  // END TEMP: temporarily set to well

  const isSearchCriteriaChange = useExportCardStore(
    (state) => state.isSearchCriteriaChange
  );

  const allGridData = dataGridRecordStore((state) => state.allGridData);

  // TEMP: temporarily set to well
  const recordSelectionStore = useRecordSelectionStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const selectedIdsKeys = recordSelectionStore(
    (state) => state.selectedIdsKeys
  );

  useEffect(() => {
    if (
      allGridData.length === 0 ||
      (allGridData.length > 0 && allGridData.length > MAX_EXPORT) ||
      isIdentifierFormInvalid ||
      allWellGridDataLoading ||
      gridTotalCount === 0 ||
      isSearchCriteriaChange
    ) {
      if (selectedIdsKeys.length > 0 && selectedIdsKeys.length <= MAX_EXPORT) {
        setIsR360Disabled(false);
      } else {
        setIsR360Disabled(true);
      }
    } else {
      setIsR360Disabled(false);
    }
  }, [
    allGridData,
    isIdentifierFormInvalid,
    allWellGridDataLoading,
    gridTotalCount,
    selectedIdsKeys,
  ]);

  return {
    isR360Disabled,
  };
};

export default useR360GridExport;
