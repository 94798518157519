import { useEffect } from "react";

import { RecordType } from "../../types/panels/searchPanel/search";

import useMapDrawStore from "../../store/map/draw/mapDrawStore";

import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useRecordCountData from "../useRecordCountData";
import useSearchStore from "./useSearchStore";

interface UseSearchCountAttributeProps {
  searchRecordType: RecordType;
}

const useSearchCountAttribute = ({
  searchRecordType,
}: UseSearchCountAttributeProps) => {
  const { isMetricOnSelection } = useUnitOfMeasure();
  const { data, isLoading, getExpectedRecordCount } = useRecordCountData({
    searchRecordType,
  });

  const searchStore = useSearchStore({ searchRecordType });
  const currentBounds = searchStore((state) => state.currentBounds);
  const updateSearchAttributeCount = searchStore(
    (state) => state.updateSearchAttributeCount
  );

  const drawnPolygons = useMapDrawStore((state) => state.drawnPolygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);

  useEffect(() => {
    updateSearchAttributeCount(data, isLoading);
  }, [data, isLoading]);

  useEffect(() => {
    getExpectedRecordCount({
      drawnPolygons,
      bounds: currentBounds,
      shapeId,
      identifier: { type: "", identifiers: [], fileId: "" },
      fetchedSavedSearchTrigger: false,
      isMetric: isMetricOnSelection,
    });
  }, [currentBounds, drawnPolygons, shapeId, isMetricOnSelection]);

  return { data, isLoading };
};

export default useSearchCountAttribute;
