import { RecordType } from "../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useDataGridPermitsStore from "../../store/grid/permits/dataGridPermitsStore";
import useDataGridWellsStore from "../../store/grid/wells/dataGridWellsStore";

interface UseDataGridStoreProps {
  searchRecordType: RecordType;
}

const useDataGridRecordStore = ({
  searchRecordType,
}: UseDataGridStoreProps) => {
  const dataGridWellsStore = useDataGridWellsStore;
  const dataGridPermitsStore = useDataGridPermitsStore;

  const getDataGridStore = () => {
    if (searchRecordType === RECORD_TYPES.PERMIT) {
      return dataGridPermitsStore;
    }

    return dataGridWellsStore;
  };

  return getDataGridStore();
};

export default useDataGridRecordStore;
