import { useCallback } from "react";

import { ChartType } from "../../types/charts/chartType/chartType";

import {
  CHART_TYPES,
  MAX_WELLS_COUNT_ASSET_ECONOMICS,
} from "../../constants/charts/charts";

import usePermitSelectionStore from "../../store/selection/permits/permitSelectionStore";
import useWellSelectionStore from "../../store/selection/wells/wellSelectionStore";

const useChartReference = () => {
  const selectedWellIdsKeys = useWellSelectionStore(
    (state) => state.selectedIdsKeys
  );
  const selectedWellUwis = useWellSelectionStore(
    (state) => state.selectedRecordUwis
  );

  const selectedPermitIdsKeys = usePermitSelectionStore(
    (state) => state.selectedIdsKeys
  );

  const checkIDsReference = useCallback(
    (chartType: ChartType) => {
      switch (chartType) {
        case CHART_TYPES.PRODUCTION_PLOT || CHART_TYPES.TYPE_CURVE:
          return Boolean(selectedWellIdsKeys.length);
        case CHART_TYPES.ASSET_ECONOMICS:
          return Boolean(
            selectedWellUwis.length &&
              selectedWellUwis.length < MAX_WELLS_COUNT_ASSET_ECONOMICS
          );
        default:
          return Boolean(
            [...selectedPermitIdsKeys, ...selectedWellIdsKeys].length
          );
      }
    },
    [selectedWellIdsKeys, selectedWellUwis, selectedPermitIdsKeys]
  );

  return { checkIDsReference };
};

export default useChartReference;
