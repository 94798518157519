import { useState } from "react";

import axios, { AxiosError } from "axios";

import { APIErrorResponse } from "../../../types/common/api";
import { GetSavedSearch } from "../../../types/panels/savedSearchPanel/hooks";
import { SavedSearchDataResponse } from "../../../types/panels/savedSearchPanel/savedSearchData";
import { RecordType } from "../../../types/panels/searchPanel/search";

import config from "../../../configs/appSettings";

import { SIDEBAR_MENU_KEYS } from "../../../constants/panels/menu";
import { SAVED_SEARCH_VISIBILITY_PRIVATE } from "../../../constants/panels/savedSearchPanel/hooks";
import {
  ATTRIBUTES_TAB,
  IDENTIFIER_TAB,
} from "../../../constants/panels/searchPanel/common/accordion";
import {
  RECORD_TYPES,
  SEARCH_TYPES,
} from "../../../constants/panels/searchPanel/search";

import useDataGridStore from "../../../store/grid/dataGridStore";
import usePanelsStore from "../../../store/panels/panelsStore";
import useSearchWellsStore from "../../../store/search/wells/searchWellsStore";
import useStore from "../../../store/useStore";

import { formatToNonAutoSearchType } from "../../../utils/savedSearch/savedData";

import useDataGridRecordStore from "../../grid/useDataGridRecordStore";
import useSearchStore from "../../search/useSearchStore";
import useSavedSearchHelper from "../useSavedSearchHelper";
import useLoadConfigs from "./useLoadConfigs";
import useLoadSearches from "./useLoadSearches";

const useGetSavedSearch = () => {
  const authUser = useStore((state) => state.authUser);
  const updateSelectedSavedSearchData = useStore(
    (state) => state.updateSelectedSavedSearchData
  );

  // TEMP: Temporarily using useSearchWellsStore
  const searchStore = useSearchStore({ searchRecordType: RECORD_TYPES.WELL });
  const updateNewSearchCriteria = searchStore(
    (state) => state.updateNewSearchCriteria
  );

  // TEMP: Temporarily using useSearchWellsStore to update tab
  const updateSearchTypeTab = useSearchWellsStore(
    (state) => state.updateSearchTypeTab
  );
  // const updateActiveSearchPanelTab = usePanelsStore(
  //   (state) => state.updateActiveSearchPanelTab
  // );

  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);

  const toggleGrid = useDataGridStore((state) => state.toggleGrid);
  const toggleGridHeader = useDataGridStore((state) => state.toggleGridHeader);
  const updateInitialGridSearchMade = useDataGridStore(
    (state) => state.updateInitialGridSearchMade
  );
  // TEMP: temporarily set to well
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const updateInitialWellDataFetched = dataGridRecordStore(
    (state) => state.updateInitialWellDataFetched
  );
  const updateSortPayload = dataGridRecordStore(
    (state) => state.updateSortPayload
  );

  const {
    loadGridConfigs,
    loadModulesConfigs,
    loadMapConfigs,
    loadDashboardChartConfigs,
    loadUnitOfMeasure,
  } = useLoadConfigs();
  const { loadAttributeSearch, loadUWISearch, loadUWIFileUpload } =
    useLoadSearches();
  const { error, setError, catchError } = useSavedSearchHelper();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SavedSearchDataResponse | null>(null);

  const updateLastRunDate: GetSavedSearch = (id, searchName, searchData) => {
    const body = {
      userId: `${authUser.uid}`,
      searchName,
      searchData,
      lastRunTime: "true",
    };

    axios
      .post(`${config.endpoints.wellService}api/searches/update/${id}`, body, {
        params: {
          applicationName: "Saga-Analytics",
        },
      })
      .catch((error) => catchError(error))
      .finally(() => setIsLoading(false));
  };

  const getSavedSearch = async (id: string) => {
    try {
      setIsLoading(true);
      setData(null);
      setError(null);

      const { data } = await axios.get<SavedSearchDataResponse>(
        `${config.endpoints.wellService}api/searches/${id}`
      );
      const { id: searchId, searchName, searchData, searchType } = data;
      const nonAutoSearchType = formatToNonAutoSearchType(searchType);
      if (data.visibility === SAVED_SEARCH_VISIBILITY_PRIVATE)
        updateLastRunDate(searchId, searchName, searchData);

      // TODO: Update searchTypeTab for Permits
      updateSearchTypeTab(
        nonAutoSearchType === SEARCH_TYPES.ATTRIBUTE_SEARCH
          ? ATTRIBUTES_TAB
          : IDENTIFIER_TAB
      );
      updateInitialWellDataFetched(true);

      loadUnitOfMeasure(searchData.unitOfMeasureConfiguration);
      const gridConfigs = loadGridConfigs(searchData.gridConfiguration);

      let searchState = {};
      if (nonAutoSearchType === SEARCH_TYPES.ATTRIBUTE_SEARCH) {
        searchState = loadAttributeSearch(
          searchData.attributeSearch,
          searchData.sortPerAttribute
        );
      } else {
        searchState = { ...searchState };
        if (nonAutoSearchType === SEARCH_TYPES.IDENTIFIER_PASTED_SEARCH) {
          const { loadedUWIs } = loadUWISearch(searchData.uwiSearch);
          searchState = { ...searchState, ...loadedUWIs };
        } else if (nonAutoSearchType === SEARCH_TYPES.IDENTIFIER_FILE_SEARCH) {
          const uwiUploadState = await loadUWIFileUpload(searchData.fileUpload);
          searchState = { ...searchState, ...uwiUploadState };
        } else {
          const uwiConvertedFileState = await loadUWIFileUpload(
            searchData.fileUpload,
            searchData.uwiSearch
          );
          searchState = { ...searchState, ...uwiConvertedFileState };
        }
      }

      updateInitialGridSearchMade(true);
      toggleGrid(true);
      toggleGridHeader(true);
      updateNewSearchCriteria({
        drawnPolygons: [],
        polygonType: "",
        currentBounds: [],
        identifier: { type: "uwi", identifiers: [], fileId: "" }, //TODO: Decoupling | update when doing the saved search functionality
        shapeId: "",
        ...gridConfigs,
        ...searchState,
      });

      if (gridConfigs.sort?.length) {
        updateSortPayload(gridConfigs.sort);
      } else {
        updateSortPayload([]);
      }

      loadMapConfigs(searchData.mapConfiguration);
      loadModulesConfigs(searchData.modulesConfiguration);
      loadDashboardChartConfigs(searchData.dashboardChartConfiguration);

      if (searchState && "currentBounds" in searchState) {
        // TEMP: Temporarily set to wells
        const recordType: RecordType = RECORD_TYPES.WELL;
        // const recordType = getRecordTypeOnBound(
        //   searchState.currentBounds as Bounds
        // );
        switch (recordType) {
          case RECORD_TYPES.WELL:
            // case RECORD_TYPES.WELLS_AND_PERMIT:
            updateActivePanel(SIDEBAR_MENU_KEYS.DEFAULT_CHARTS);
            break;
          // case RECORD_TYPES.PERMIT:
          //   updateActivePanel(SIDEBAR_MENU_KEYS.SEARCH);
          //   break;
          default:
            updateActivePanel(SIDEBAR_MENU_KEYS.SEARCH);
        }
      }

      setData(data);
      updateSelectedSavedSearchData(data);
    } catch (error) {
      catchError(error as AxiosError<APIErrorResponse>);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    updateLastRunDate,
    getSavedSearch,
  };
};

export default useGetSavedSearch;
