import { useCallback } from "react";

import { FetchExtentProps } from "../../../types/grid/useAllRecordData";
import { Extent, ExtentResponse } from "../../../types/map/gis/extent";
import { RecordType } from "../../../types/panels/searchPanel/search";

import config from "../../../configs/appSettings";

import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useStore from "../../../store/useStore";

import { cleanExtentData } from "../../../utils/map/extent";

import { callServiceAPI } from "../../../action/callServiceAPI";
import useExtentRecordStore from "./useExtentRecordStore";

const useFetchExtentData = (
  searchRecordType: RecordType = RECORD_TYPES.WELL
) => {
  const extentStore = useExtentRecordStore({ searchRecordType });

  const updateExtentInfo = extentStore((state) => state.updateExtentInfo);

  const toggleEnableZoomFit = useStore((state) => state.toggleEnableZoomFit);

  const getExtentData = useCallback(
    async ({
      body,
      getIdentifierFileIdWithRetry,
      identifierSearchUploadedFile,
      isFromIdentifierSearchUploadedFile,
    }: FetchExtentProps) => {
      try {
        // NOTE:
        // Resetting of the states for extent is
        // handled in Search Triggers UWI Tab Panel and Search Panel

        const getWellsExtentUrl = `${config.endpoints.wellService}api/records/coordinates/extent`;

        const extentRes: any = await callServiceAPI(
          getWellsExtentUrl,
          body,
          getIdentifierFileIdWithRetry,
          identifierSearchUploadedFile,
          isFromIdentifierSearchUploadedFile
        );
        const exData: ExtentResponse = extentRes?.data;
        const extent: Extent | undefined = cleanExtentData(exData);

        if (extent) {
          updateExtentInfo({
            isLoading: false,
            data: extent,
            error: "",
          });
          toggleEnableZoomFit(true);
        }
      } catch (error: any) {
        console.debug("getExtentData error", error);
        updateExtentInfo({
          isLoading: false,
          data: [],
          error,
        });
      }
    },
    [updateExtentInfo, toggleEnableZoomFit]
  );

  return {
    getExtentData,
  };
};

export default useFetchExtentData;
