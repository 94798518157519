import { useMemo } from "react";

import useDataGridStore from "../../store/grid/dataGridStore";

const usePanelsLayout = (isFooterIncluded = false) => {
  const showGrid = useDataGridStore((state) => state.showGrid);
  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const gridHeight = useDataGridStore((state) => state.gridHeight);
  const isExpandGridWidth = useDataGridStore(
    (state) => state.isExpandGridWidth
  );

  const getPanelHeight: string = useMemo(() => {
    const footerOffset = isFooterIncluded ? "23px" : "0px";
    let height = `calc(100vh - 56px - ${footerOffset})`;

    if (isExpandGridWidth) {
      if (showGrid) {
        height = `calc(100vh - (${gridHeight} + 56px))`;
      } else if (!showGrid && showGridHeader) {
        height = `calc(100vh - 138px)`;
      }
    }

    return height;
  }, [
    gridHeight,
    showGrid,
    showGridHeader,
    isExpandGridWidth,
    isFooterIncluded,
  ]);

  return {
    getPanelHeight,
  };
};

export default usePanelsLayout;
