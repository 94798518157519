import {
  GridFilterModel,
  GridLogicOperator,
  GridSortModel,
} from "@mui/x-data-grid-premium";

import { map } from "lodash";

import { FILTER_TYPE_CHECKLIST } from "../../constants/grid";

import { getColumnKeyNames } from "./dynamicColumns";
import { allPermitColumns } from "./permits/permitColumns";
import { permitsColumnVisibilityList } from "./permits/permitsColumnVisibilityList";
import { columnVisibilityList } from "./wells/columnVisbilityList";
import { allColumns } from "./wells/columns";

export const defaultColumnsOrder = map(allColumns, "field");
export const defaultPermitColumnsOrder = map(allPermitColumns, "field");

export const defaultSortModel: GridSortModel = [
  {
    field: "UWI",
    sort: "asc",
  },
];

export const defaultSortPayload = [
  {
    columnName: "UWI",
    order: "ASC",
  },
];

export const defaultFilterModel: GridFilterModel = {
  items: [],
  logicOperator: GridLogicOperator.And,
};

export const defaultBetweenValues = {
  value1: null,
  value2: null,
};

export const defaultColumnsModel = getColumnKeyNames(columnVisibilityList);
export const defaultPermitColumnsModel = getColumnKeyNames(
  permitsColumnVisibilityList
);

export const defaultFilterAttributesOptions = Object.keys(
  columnVisibilityList
).reduce((a, v) => ({ ...a, [v]: [] }), {});

export const defaultColumnsFilterType = Object.keys(
  columnVisibilityList
).reduce((a, v) => ({ ...a, [v]: FILTER_TYPE_CHECKLIST }), {});
