import { useEffect } from "react";

import { RecordType } from "../../types/panels/searchPanel/search";

import useMapDrawStore from "../../store/map/draw/mapDrawStore";

import { containsAtLeastOne } from "../../utils/helper2";

import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useRecordCountData from "../useRecordCountData";
import useSearchStore from "./useSearchStore";

interface UseSearchCountIdentifierProps {
  searchRecordType: RecordType;
}

const useSearchCountIdentifier = ({
  searchRecordType,
}: UseSearchCountIdentifierProps) => {
  const { isMetricOnSelection } = useUnitOfMeasure();

  const { data, isLoading, getExpectedRecordCount } = useRecordCountData({
    searchRecordType,
  });

  const searchStore = useSearchStore({ searchRecordType });
  const updateSearchIdentifierCount = searchStore(
    (state) => state.updateSearchIdentifierCount
  );
  const selectedIdentifier = searchStore((state) => state.selectedIdentifier);
  const identifierSearchPastedValueList = searchStore(
    (state) => state.identifierSearchPastedValueList
  );
  const identifierSearchUploadedFileId = searchStore(
    (state) => state.identifierSearchUploadedFileId
  );
  const isLoadingIdentifierFileUpload = searchStore(
    (state) => state.isLoadingIdentifierFileUpload
  );
  const drawnPolygons = useMapDrawStore((state) => state.drawnPolygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);

  useEffect(() => {
    updateSearchIdentifierCount(data, isLoading);
  }, [data, isLoading]);

  useEffect(() => {
    if (
      !identifierSearchPastedValueList?.length &&
      !identifierSearchUploadedFileId &&
      !containsAtLeastOne(drawnPolygons) &&
      !shapeId
    ) {
      updateSearchIdentifierCount(0, false);
      return;
    }

    getExpectedRecordCount({
      bounds: [],
      identifier: {
        type: selectedIdentifier,
        identifiers: identifierSearchPastedValueList,
        fileId: identifierSearchUploadedFileId ?? "",
      },
      drawnPolygons,
      shapeId,
      fetchedSavedSearchTrigger: false,
      isMetric: isMetricOnSelection,
    });
  }, [
    identifierSearchPastedValueList,
    identifierSearchUploadedFileId,
    selectedIdentifier,
    drawnPolygons,
    shapeId,
    isMetricOnSelection,
  ]);

  return { data, isLoading: isLoading || isLoadingIdentifierFileUpload };
};

export default useSearchCountIdentifier;
