import { StateCreator } from "zustand";

import { DataGridStates } from "../../types/grid/store/dataGridStore";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

const dataGridSlice: StateCreator<DataGridStates> = (set, get) => ({
  showGrid: false,
  toggleGrid: (status) => set((state) => ({ showGrid: status })),

  showGridHeader: false,
  toggleGridHeader: (status) => set((state) => ({ showGridHeader: status })),

  gridHeight: "440px",
  setGridHeight: (height) => set((state) => ({ gridHeight: height })),

  isExpandGridWidth: false,
  toggleGridWidth: (status) =>
    set(() => ({ isExpandGridWidth: status ?? !get().isExpandGridWidth })),

  initialGridSearchMade: false,
  updateInitialGridSearchMade: (initialGridSearchMade) =>
    set(() => ({ initialGridSearchMade })),

  activeRecordTypeTab: RECORD_TYPES.WELL,
  updateActiveRecordTypeTab: (activeRecordTypeTab) =>
    set(() => ({ activeRecordTypeTab })),
});

export default dataGridSlice;
