import { useMemo } from "react";

import { Card, CardContent, CardHeader, Slide } from "@mui/material";

import classNames from "classnames";
import { Accordion as CLAccordion } from "component-library";

import { BUBBLE_MAP } from "../../constants/constants";
import { LEGEND, MAP_SETTINGS } from "../../constants/map/mapSettings";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapStore from "../../store/map/mapStore";
import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";
import usePanelsStore from "../../store/panels/panelsStore";

import CustomCloseButton from "../common/CustomCloseButton";
import BubbleSizeList from "./legendsOverlay/BubbleSizeList";
import WellColorList from "./legendsOverlay/WellColorList";

import "../../assets/scss/mapOverlays/legendsOverlay.scss";

const LegendsOverlay = () => {
  const layers = useMapStore((state) => state.layers);
  const currentZoom = useMapStore((state) => state.currentZoom);
  const mapOverlayOpened = useMapSettingsStore(
    (state) => state.mapOverlayOpened
  );
  const removeMapOverlayOpened = useMapSettingsStore(
    (state) => state.removeMapOverlayOpened
  );

  const isOpenAnalysisPanel = usePanelsStore(
    (state) => state.isOpenAnalysisPanel
  );

  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const showGrid = useDataGridStore((state) => state.showGrid);
  const gridHeight = useDataGridStore((state) => state.gridHeight);

  const isBubbleMapEnabled = useMemo(
    () =>
      !!layers.find((layer) => layer.name === BUBBLE_MAP)?.isSelected &&
      currentZoom >= 12,
    [layers, currentZoom]
  );

  const open = useMemo(
    () => mapOverlayOpened.includes(LEGEND.key),
    [mapOverlayOpened]
  );

  const isMapSettingsOpen = useMemo(
    () => mapOverlayOpened.includes(MAP_SETTINGS.key),
    [mapOverlayOpened]
  );

  const getBottomPosition = useMemo(() => {
    let bottom = `calc(${gridHeight} + 84px)`;

    if (!showGrid && !showGridHeader) {
      bottom = "84px";
    } else if (!showGrid && showGridHeader) {
      bottom = "186px";
    }

    return bottom;
  }, [gridHeight, showGrid, showGridHeader]);

  const getHeight = useMemo(() => {
    let height = "";

    height = `calc(100vh - (${gridHeight} + 180px))`;
    if (!showGrid && !showGridHeader) {
      height = `calc(100vh - 175px)`;
    } else if (!showGrid && showGridHeader) {
      height = `calc(100vh - 282px)`;
    }

    return height;
  }, [gridHeight, showGrid, showGridHeader, isOpenAnalysisPanel]);

  return (
    <Slide direction="left" in={open}>
      <Card
        className={classNames("legends-card", {
          "analysis-open-mapsettings-closed":
            isOpenAnalysisPanel && !isMapSettingsOpen,
          "analysis-closed-mapsettings-open":
            !isOpenAnalysisPanel && isMapSettingsOpen,
          "analysis-open-mapsettings-open":
            isOpenAnalysisPanel && isMapSettingsOpen,
          open,
        })}
        style={{
          bottom: getBottomPosition,
          maxHeight: getHeight,
        }}
      >
        <CardHeader
          action={
            <CustomCloseButton
              aria-label="close"
              onClick={() => removeMapOverlayOpened(LEGEND.key)}
            />
          }
          title={LEGEND.label}
        />
        <CardContent>
          <CLAccordion title="Wells & Permits" isDefaultOpen>
            <div className="legends-content">
              <WellColorList />
            </div>
          </CLAccordion>
          {isBubbleMapEnabled && (
            <CLAccordion title="Bubble Map" isDefaultOpen>
              <div className="legends-content">
                <BubbleSizeList />
              </div>
            </CLAccordion>
          )}
        </CardContent>
      </Card>
    </Slide>
  );
};

export default LegendsOverlay;
