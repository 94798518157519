import { useMemo } from "react";

import { isEqual } from "lodash";

import { RecordData, RecordDataObjectKeys } from "../../types/common/records";
import { RecordType } from "../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useMapHoverStore from "../../store/map/hover/mapHoverStore";

import { changeAttributeKey } from "../../utils/datagrid";
import {
  getColumnsModelByRecordType,
  getColumnsVisibilityListByRecordType,
  getRequiredColumnsByRecordType,
  getRequiredColumnsForSpotByRecordType,
} from "../../utils/datagrid/getRecordSpecificConstants";

import useDataGridRecordStore from "./useDataGridRecordStore";

export const useGridColumn = (
  searchRecordType: RecordType = RECORD_TYPES.WELL
) => {
  const dataGridRecordStore = useDataGridRecordStore({ searchRecordType });
  const columnsPayload = dataGridRecordStore((state) => state.columnsPayload);
  const columnVisibilityModel = dataGridRecordStore(
    (state) => state.columnVisibilityModel
  );
  const mapHoverEnabled = useMapHoverStore((state) => state.mapHoverEnabled);
  const dataGridSelector = dataGridRecordStore(
    (state) => state.allWellSelectors
  );

  const reqCols = useMemo(() => {
    const allReqCols = getRequiredColumnsByRecordType(searchRecordType);
    const spotReqCols = getRequiredColumnsForSpotByRecordType(searchRecordType);
    return mapHoverEnabled ? allReqCols : spotReqCols;
  }, [mapHoverEnabled, searchRecordType]);

  const recordColumnVisibility = useMemo(
    () => getColumnsVisibilityListByRecordType(searchRecordType),
    [searchRecordType]
  );

  const getRequiredColumns = (columns: string[], optimizeColumns = false) => {
    if (columns.length) {
      const columnsFiltered = getColumnsFiltered(columns);

      if (optimizeColumns) {
        return columnsFiltered;
      }

      return [...reqCols, ...columnsFiltered];
    }

    if (optimizeColumns) {
      const columnsModel = getColumnsModelByRecordType(searchRecordType);
      const columnsFiltered = getColumnsFiltered(columnsModel);
      return columnsFiltered;
    }

    return reqCols;
  };

  const getColumnsFiltered = (columns: string[] = []) => {
    return columns.filter((col) => !reqCols.includes(col));
  };

  const getColumnsPayloadData = () => {
    return isEqual(columnVisibilityModel, recordColumnVisibility)
      ? []
      : columnsPayload;
  };

  //find missing column data from allWellDataObj and merge to existing grid row data
  const setGridRowData = (rowData: RecordData[] = []) => {
    const allWellGridDatabyWellHeaderPermitKey =
      dataGridSelector.byWellHeaderPermitKey;

    const newRowData = rowData.map((row) => {
      const mapObj =
        allWellGridDatabyWellHeaderPermitKey[row.WellHeaderPermitKey];

      return {
        ...getColumnsFromAllWellData(mapObj, reqCols),
        ...row,
      };
    });

    return newRowData;
  };

  const getColumnsFromAllWellData = (
    wellData: RecordData,
    gridColumns: string[] = []
  ) => {
    const requiredMiniCardColumns = getRequiredColumnsByRecordType(
      searchRecordType
    ) as RecordDataObjectKeys[];
    const rowDataFromAllWellDataObj:
      | RecordData
      | Record<string, string | number | number[] | undefined> = {};

    if (gridColumns.length) {
      requiredMiniCardColumns.forEach((col) => {
        const wellDataCol = changeAttributeKey(col) as keyof RecordData;
        if (gridColumns.includes(col) && wellData[wellDataCol]) {
          rowDataFromAllWellDataObj[wellDataCol] = wellData[wellDataCol];
        }
      });
    } else {
      requiredMiniCardColumns.forEach((col) => {
        const wellDataCol = changeAttributeKey(col) as keyof RecordData;
        if (wellData[wellDataCol])
          rowDataFromAllWellDataObj[wellDataCol] = wellData[wellDataCol];
      });
    }

    return rowDataFromAllWellDataObj;
  };

  return {
    getRequiredColumns,
    getColumnsPayloadData,
    setGridRowData,
  };
};
