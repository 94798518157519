import { MapData, RecordData } from "../../types/common/records";
import { RecordType } from "../../types/panels/searchPanel/search";

import {
  REQUIRED_PERMIT_COLUMNS,
  REQUIRED_PERMIT_SPOT_COLUMNS,
  REQUIRED_WELL_COLUMNS,
  REQUIRED_WELL_SPOT_COLUMNS,
} from "../../constants/grid";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import { defaultColumnsModel, defaultPermitColumnsModel } from "./defaults";
import { allPermitColumns } from "./permits/permitColumns";
import { permitsColumnVisibilityList } from "./permits/permitsColumnVisibilityList";
import { columnVisibilityList } from "./wells/columnVisbilityList";
import { allColumns } from "./wells/columns";

export const getColumnsByRecordType = (recordType: RecordType) => {
  if (recordType === RECORD_TYPES.PERMIT) return allPermitColumns;
  return allColumns;
};

export const getRowIdByRecordType = (
  recordType: RecordType,
  row: { WellID: number; PermitID: number }
) => {
  if (recordType === RECORD_TYPES.PERMIT) return row.PermitID;
  return row.WellID;
};

export const getColumnsModelByRecordType = (recordType: RecordType) => {
  if (recordType === RECORD_TYPES.PERMIT) return defaultPermitColumnsModel;
  return defaultColumnsModel;
};

export const getColumnsVisibilityListByRecordType = (
  recordType: RecordType
) => {
  if (recordType === RECORD_TYPES.PERMIT) return permitsColumnVisibilityList;
  return columnVisibilityList;
};

export const getRequiredColumnsByRecordType = (recordType: RecordType) => {
  if (recordType === RECORD_TYPES.PERMIT) return REQUIRED_PERMIT_COLUMNS;
  return REQUIRED_WELL_COLUMNS;
};

export const getRequiredColumnsForSpotByRecordType = (
  recordType: RecordType
) => {
  if (recordType === RECORD_TYPES.PERMIT) return REQUIRED_PERMIT_SPOT_COLUMNS;
  return REQUIRED_WELL_SPOT_COLUMNS;
};

export const getRecordIdByRecordType = (
  recordData: RecordData | MapData,
  recordType: RecordType
) => {
  if (recordType === RECORD_TYPES.PERMIT) return recordData.PermitID;
  return recordData.WellID;
};
