import { useState } from "react";

import { SearchRequestPayload } from "../../types/common/api";
import { RecordData } from "../../types/common/records";
import {
  BaseSearchCriteria,
  SearchWellDataPayload,
  SortBySelected,
} from "../../types/common/search";
import { GetGridDataResponse } from "../../types/grid/useGridData";
import { RecordType } from "../../types/panels/searchPanel/search";

import config from "../../configs/appSettings";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import { defaultSortPayload } from "../../utils/datagrid";

import { callServiceAPI } from "../../action/callServiceAPI";
import useSearchRequest from "../common/useSearchRequest";
import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useIdentifierFileUpload from "../search/identifier/useIdentifierFileUpload";
import useSearchStore from "../search/useSearchStore";
import useDataGridRecordStore from "./useDataGridRecordStore";
import { useGridColumn } from "./useGridColumn";
import useRecordSelectionStore from "./useRecordSelectionStore";
import { useSelectedRecordByRecordType } from "./useSelectedRecordByRecordType";

const searchURL = `${config.endpoints.wellService}api/records/search/`;

export const useGridData = (
  searchRecordType: RecordType = RECORD_TYPES.WELL
) => {
  const configPageLimit = config.searchPageLimit;
  const { getRequiredColumns, setGridRowData } =
    useGridColumn(searchRecordType);
  const { buildSearchRequestByParam } = useSearchRequest(searchRecordType);
  const { isMetricOnSearch } = useUnitOfMeasure();

  const dataGridRecordStore = useDataGridRecordStore({ searchRecordType });
  const sortByAnalysis = dataGridRecordStore((state) => state.sortByAnalysis);

  const updateGridFilteredLoading = dataGridRecordStore(
    (state) => state.updateGridFilteredLoading
  );
  const updateGridTotalCount = dataGridRecordStore(
    (state) => state.updateGridTotalCount
  );
  const updateGridFilteredCount = dataGridRecordStore(
    (state) => state.updateGridFilteredCount
  );
  const updateBatchWellGridData = dataGridRecordStore(
    (state) => state.updateBatchWellGridData
  );
  const updateBatchWellGridDataSuccess = dataGridRecordStore(
    (state) => state.updateBatchWellGridDataSuccess
  );
  const updateBatchWellGridDataLoading = dataGridRecordStore(
    (state) => state.updateBatchWellGridDataLoading
  );
  const updateColumnsFetched = dataGridRecordStore(
    (state) => state.updateColumnsFetched
  );

  const { isDeselectedIds } = useSelectedRecordByRecordType({
    searchRecordType,
  });

  const recordSelectionStore = useRecordSelectionStore({
    searchRecordType,
  });
  const selectedIdsKeys = recordSelectionStore(
    (state) => state.selectedIdsKeys
  );
  const deselectedIdsKeys = recordSelectionStore(
    (state) => state.deselectedIdsKeys
  );

  const searchStore = useSearchStore({ searchRecordType });
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const { getIdentifierFileIdWithRetry } =
    useIdentifierFileUpload(searchRecordType);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<RecordData[] | null>(null);
  const [error, setError] = useState<unknown>(null);
  const [success, setSuccess] = useState(false);

  const searchGridData = async ({
    drawnPolygons = [],
    currentBounds = [],
    identifier,
    shapeId = "",
    offset = 0,
    pageLimit = configPageLimit,
    sort = [],
    filters = [],
    columns = [],
    optimizeColumns = false,
  }: SearchWellDataPayload) => {
    setIsLoading(true);
    setData(null);
    setError(null);
    setSuccess(false);
    updateBatchWellGridDataSuccess(false);
    updateBatchWellGridDataLoading(true);

    const hasFilters = filters?.length;
    const hasOffset = offset === 0;

    if (hasOffset) {
      if (hasFilters) {
        updateGridFilteredLoading(true);
      } else {
        updateBatchWellGridDataLoading(true);
      }
    }

    const requestBodySearchCriteria: BaseSearchCriteria = {
      drawnPolygons,
      shapeId,
      currentBounds,
      identifier,
      filters,
    };

    const searchRequestBody = buildSearchRequestByParam({
      pSearchCriteria: requestBodySearchCriteria,
    });

    const body: SearchRequestPayload = {
      ...searchRequestBody,
      offset: offset,
      pageLimit: pageLimit,
      recordType: searchRecordType,
      ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
    };

    if (sort.length) {
      body.sort = sort;
    }

    const finalColumnsPayload = getRequiredColumns(columns, optimizeColumns);
    body.columns = finalColumnsPayload;

    if (sortByAnalysis) {
      const payloadKey =
        searchRecordType === RECORD_TYPES.WELL ? "wellIds" : "permitIds";
      const sortBySelectedRequest: SortBySelected = {
        prioritizeSelected: !isDeselectedIds,
        [payloadKey]: isDeselectedIds ? deselectedIdsKeys : selectedIdsKeys,
      };
      body.sortBySelected = sortBySelectedRequest;

      if (!sort.length) {
        body.sort = defaultSortPayload;
      }
    }

    try {
      const initialResponse = await callServiceAPI<GetGridDataResponse>(
        searchURL,
        body,
        getIdentifierFileIdWithRetry,
        identifierSearchUploadedFile,
        Boolean(identifier.fileId)
      );

      if (!initialResponse || !("data" in initialResponse)) return;

      const newGridData = setGridRowData(initialResponse.data.wells);

      updateBatchWellGridData(newGridData);
      setData(initialResponse.data.wells);
      setIsLoading(false);
      setSuccess(true);
      updateBatchWellGridDataSuccess(true);
      updateBatchWellGridDataLoading(false);

      if (columns.length) updateColumnsFetched(columns);

      if (hasOffset && hasFilters) {
        updateGridFilteredLoading(false);
      }
    } catch (err) {
      console.debug("getWellsData error", err);
      setError(err);
      setIsLoading(false);
      updateBatchWellGridDataLoading(false);
      if (hasOffset) {
        if (hasFilters) {
          updateGridFilteredCount(0);
          updateGridFilteredLoading(false);
        } else {
          updateGridTotalCount(0);
          updateGridFilteredCount(0);
          updateBatchWellGridDataLoading(false);
        }
      }
    }
  };

  return {
    isLoading,
    data,
    error,
    success,
    searchGridData,
  };
};
