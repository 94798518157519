import { StateCreator } from "zustand";

import { RecordSelectSlice } from "../../../types/grid";

const recordSelectSlice: StateCreator<RecordSelectSlice> = (set, get) => ({
  recordSelectTrigger: "",
  updateRecordSelectTrigger: (recordSelectTrigger) =>
    set((state) => ({ recordSelectTrigger })),
});

export { recordSelectSlice };
