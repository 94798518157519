import { StateCreator } from "zustand";

import { DataGridColumnStore } from "../../../types/grid";

import {
  columnVisibilityList,
  defaultColumnsModel,
  defaultColumnsOrder,
} from "../../../utils/datagrid";
import { clone } from "../../../utils/helper";

const dataGridColumnSlice: StateCreator<DataGridColumnStore> = (set, get) => ({
  columnsOrder: clone(defaultColumnsOrder),
  updateColumnsOrder: (columnsOrder) => set((state) => ({ columnsOrder })),
  columnVisibilityAction: "",
  updateColumnVisibilityAction: (action) =>
    set(() => ({
      columnVisibilityAction: action,
    })),
  columnVisibilityModel: clone(columnVisibilityList),
  updateColumnVisibilityModel: (columnVisibilityModel) =>
    set((state) => ({ columnVisibilityModel })),
  columnsFetched: clone(defaultColumnsModel),
  updateColumnsFetched: (columnsFetched) =>
    set((state) => ({ columnsFetched })),
  columnsPayload: clone(defaultColumnsModel),
  updateColumnsPayload: (columnsPayload) =>
    set((state) => ({ columnsPayload })),
});

export { dataGridColumnSlice };
