import { useState } from "react";

import axios from "axios";

import { SearchRequestPayload } from "../../types/common/api";
import { BaseSearchCriteria } from "../../types/common/search";
import {
  GetAttribWellCountProps,
  GetAttribWellCountResponse,
} from "../../types/common/useSearchData";
import { AttributeSortKeys } from "../../types/panels/searchPanel/queryBuilder/sortPerAttribute";
import { RecordType } from "../../types/panels/searchPanel/search";

import config from "../../configs/appSettings";

import { settledPromise } from "../../utils/api/request";

import { callServiceAPI } from "../../action/callServiceAPI";
import useDataGridRecordStore from "../grid/useDataGridRecordStore";
import useIdentifierFileUpload from "../search/identifier/useIdentifierFileUpload";
import useSearchStore from "../search/useSearchStore";
import useAttributeOptionsPostProcess from "./useAttributeOptionsPostProcess";
import useSearchRequest from "./useSearchRequest";

interface UseAttributeCountDataProps {
  searchRecordType: RecordType;
}

const useAttributeCountData = ({
  searchRecordType,
}: UseAttributeCountDataProps) => {
  const { getIdentifierFileIdWithRetry } =
    useIdentifierFileUpload(searchRecordType);
  const { formatOptions } = useAttributeOptionsPostProcess();
  const { buildSearchRequestByParam } = useSearchRequest(searchRecordType);

  const searchStore = useSearchStore({ searchRecordType });
  const updateCurrentQBInfo = searchStore((state) => state.updateCurrentQBInfo);
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType,
  });
  const updateColumnFilterAttributes = dataGridRecordStore(
    (state) => state.updateColumnFilterAttributes
  );

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const getAttributeCount = async ({
    bounds = [],
    searchType, // attribute key
    drawnPolygons = [],
    forGridColumnFilter = false,
    isBasedOnIdentifierFileUpload = true,
    filters = [],
    identifier = { type: "", identifiers: [], fileId: "" },
    shapeId = "",
    sort,
    isMetric,
  }: GetAttribWellCountProps) => {
    setIsLoading(true);
    setError("");
    setSuccess(false);

    const countUrl = `${config.endpoints.wellService}api/records/count/${searchType}`;

    const requestBodySearchCriteria: BaseSearchCriteria = {
      drawnPolygons,
      shapeId,
      currentBounds: bounds,
      identifier,
      filters,
    };

    const searchRequestBody = buildSearchRequestByParam({
      pSearchCriteria: requestBodySearchCriteria,
    });

    const body: SearchRequestPayload = {
      ...searchRequestBody,
      offset: 0,
      pageLimit: 1,
      recordType: searchRecordType,
      ...(config.isUoMEnabled && { isMetric }),
    };

    if (sort) {
      body.sort = sort;
    }

    try {
      const initialResponse = await callServiceAPI<GetAttribWellCountResponse>(
        countUrl,
        body,
        getIdentifierFileIdWithRetry,
        identifierSearchUploadedFile,
        Boolean(identifier.fileId),
        isBasedOnIdentifierFileUpload
      );

      if (!initialResponse || !("data" in initialResponse)) return;

      const totalCount = initialResponse?.data?.totalCount;
      const pageLimit = config.countPageLimit;
      const totalIterations = Math.ceil(totalCount / pageLimit);

      if (totalIterations > 0) {
        const responses = [...Array(totalIterations).keys()].map((_, i) => {
          return axios.post<GetAttribWellCountResponse>(
            countUrl,
            {
              ...body,
              offset: i * pageLimit,
              pageLimit,
            },
            {
              ignoreResponseInterceptor: true,
            }
          );
        });

        settledPromise(responses, (values) => {
          const allData = values.flatMap((response) => response.data.countData);

          if (forGridColumnFilter) {
            const blankIndex = allData.findIndex(
              (el) => el.attributeValue === "Blank"
            );

            //check if index exists and if it is not at the bottom of list
            if (blankIndex >= 0 && blankIndex + 1 < allData.length) {
              //get blank item and push it at the bottom of list
              allData.push(allData.splice(blankIndex, 1)[0]);
            }

            updateColumnFilterAttributes(searchType, allData);
          } else {
            const attributeOptions = allData.filter(
              (countDataElement) =>
                countDataElement.attributeValue !== "Blank" &&
                countDataElement.attributeValue !== ""
            );

            //format options based on attribute data type
            const formattedAttrOptions = formatOptions(
              searchType,
              attributeOptions
            );

            updateCurrentQBInfo(searchType, "options", formattedAttrOptions);

            if (sort) {
              const currentSortKey = sort.type
                .replace(/-/g, "")
                .concat(sort.order) as AttributeSortKeys;
              updateCurrentQBInfo(searchType, "sortKey", currentSortKey);
            }
          }

          setIsLoading(false);
          setSuccess(true);
        });
      } else {
        setIsLoading(false);
        setSuccess(true);
      }
    } catch (err) {
      const error = err as Error;
      console.debug("getAttributeCount error", error);
      setError(error?.message);
      setIsLoading(false);
      setSuccess(false);
    }
  };

  return {
    isLoading,
    error,
    success,
    getAttributeCount,
  };
};

export default useAttributeCountData;
