import { Typography } from "@mui/material";
import {
  gridColumnMenuSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";

import { allColumns } from "../../../../utils/datagrid/wells/columns";

//Note: always import the component within grid customized components (inside)
//otherwise useGridApiContext will fail
const MenuHeader = () => {
  const apiRef = useGridApiContext();
  const openColumnMenuInfo = useGridSelector(apiRef, gridColumnMenuSelector);
  const headerName =
    allColumns.filter(
      (column) => column.field === openColumnMenuInfo.field
    )?.[0]?.headerName ?? "";

  return (
    <div className="grid-menu-header">
      <Typography variant={"body1"}>{headerName}</Typography>
    </div>
  );
};

export default MenuHeader;
