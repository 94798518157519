import { useState } from "react";

import { BaseSearchCriteria } from "../../../types/common/search";
import {
  GetTopAttributesProps,
  GetTopAttributesResponse,
} from "../../../types/map/mapSettings/useTopAttributeValues";

import config from "../../../configs/appSettings";

import { CUM_BOE } from "../../../constants/attributes";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import { callServiceAPI } from "../../../action/callServiceAPI";
import useSearchRequest from "../../common/useSearchRequest";
import useUnitOfMeasure from "../../common/useUnitOfMeasure";
import useIdentifierFileUpload from "../../search/identifier/useIdentifierFileUpload";
import useSearchStore from "../../search/useSearchStore";

const useTopAttributeValues = () => {
  const { buildSearchRequestByParam } = useSearchRequest();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<GetTopAttributesResponse>([]);
  const [error, setError] = useState<unknown>(null);

  // TEMP: temporarily set to well
  const searchStore = useSearchStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const { getIdentifierFileIdWithRetry } = useIdentifierFileUpload(
    RECORD_TYPES.WELL
  );
  // END TEMP: temporarily set to well

  const { isMetricOnSearch } = useUnitOfMeasure();

  const getTopAttributeValues = async ({
    attribute,
    sortBy = CUM_BOE.key,
    searchCriteria,
  }: GetTopAttributesProps) => {
    setIsLoading(true);
    setData([]);
    setError(null);

    try {
      const topAttributesURL = `${config.endpoints.wellService}api/wells/top/${attribute}/by/${sortBy}`;

      const { currentBounds, drawnPolygons, identifier, filters, shapeId } =
        searchCriteria;

      const requestBodySearchCriteria: BaseSearchCriteria = {
        drawnPolygons,
        shapeId,
        currentBounds,
        identifier,
        filters,
      };

      const searchRequestBody = buildSearchRequestByParam({
        pSearchCriteria: requestBodySearchCriteria,
      });

      const body = {
        ...searchRequestBody,
        ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
      };

      const response = await callServiceAPI<GetTopAttributesResponse>(
        topAttributesURL,
        body,
        getIdentifierFileIdWithRetry,
        identifierSearchUploadedFile,
        Boolean(identifier.fileId)
      );

      if (!response || !("data" in response)) return;
      setData(response.data);
    } catch (error) {
      console.error("getTopAttributeValues error", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    getTopAttributeValues,
  };
};

export default useTopAttributeValues;
